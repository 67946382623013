import { Component, OnInit } from '@angular/core';
import { UserConstants } from 'src/app/utils/userConstant';
import { Router } from '@angular/router';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { ConfirmModalService } from 'src/app/pages/modal/confirm-modal/confirm-modal.service';


@Component({
  selector: 'app-redeem-to-account',
  templateUrl: './redeem-to-account.component.html',
  styleUrls: ['./redeem-to-account.component.scss']
})
export class RedeemToAccountComponent extends Basecomponent implements OnInit{
  bankAccountAdded: boolean = false;
  redeem: boolean = true;
  userHashId:string = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID) : localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID);
  totalWalletAmount:number=0;
  zoneHashId = (this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_ZONE_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_ZONE_HASH_ID) : localStorage.getItem(UserConstants.KEY_SELECTED_ZONE_HASH_ID);
  constructor(baseService : BaseService,
    private userStorage : UserStorageService,
    private confirmModal: ConfirmModalService,
    private router : Router) {
      super(baseService)
     }

  ngOnInit(): void {
    this.apiService.getUserBankDetails(this.userHashId,this.zoneHashId),
    this.apiService.getWalltedInfoByAccountId(this.userHashId);

  }
  openBankAccount() {
    this.redirect(UserConstants.RL_BANK_DETAILS,{modalSize : 'md'});
  }
   apiResultSubscribe(data: any) {
    if(data.resulttype == UserConstants.RESULT_GET_USER_BANK_DETAILS){
      if(data.result.statusCode == 200){
        this.bankAccountAdded = data.result.data.length>0
        // console.log("fdngf",this.bankAccountAdded);
      }

    }
    if(data.resulttype == UserConstants.RESULT_GET_WALLET_INFO_BY_ACCOUNT_ID){
      if(data.result.statusCode == 200){
        this.totalWalletAmount = data.result.data[0].totalBalance
        // console.log("fdngf",this.totalWalletAmount);
      }
    }
  }

  redeemToWallet() {
    if(this.redeem) {
      let data ={
        "userHashId":this.userHashId,
        "encash_amount":this.totalWalletAmount,
        "zoneHashId":this.zoneHashId,
        "transfer_status":"new"
      }
      this.apiService.redeemWalletCash(data)
      // console.log('request send')
      this.redirect(UserConstants.RL_REQUEST_TO_REDEEM_CASH, {modalSize : 'md'});
    } else if(this.redeem) {
      console.log('failed')
    }
  }

  close() {
    console.log('close')
    this.compManager.closePopup();
  }

}
