/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:a966f857-bc91-4886-accd-6fbe2b311a82",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_IdfOijgKi",
    "aws_user_pools_web_client_id": "722h2009ojahnb7ufs9egcj824",
    "oauth": {}
};


export default awsmobile;
