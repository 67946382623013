<div class="p-1">
        <div class="add_profile_drat">
            <div class="frm_fl">
                <div class="row">
                    <div class="col-md-12" *ngIf="bankAccountAdded">
                        Did you really want to redeem wallet ballance.
                    </div>
                    <div class="col-md-12" *ngIf="!bankAccountAdded">
                        You are eligible to redeem, but bank account not added.
                    </div>
                </div>
            </div>
        </div>


        <div class="add_footer vbweyg" >
            <div class="md-footer">
                <div class="row">
                    <div class="col-sm-3">
                        <button type="submit" (click)="close()">Cancel</button>
                    </div>
                    <div class="col-sm-6" *ngIf="bankAccountAdded">
                        <button type="submit" (click)="redeemToWallet()">Yes, redeem to account.</button>
                    </div>
                    <div class="col-sm-6" *ngIf="!bankAccountAdded">
                        <button type="submit" (click)="openBankAccount()">Add Bank Account</button>
                    </div>
                </div>
            </div>
        </div>
 </div>
