<div class="customize_food_section">
<div class="hefb_klad">
<h3 class="ml-3" *ngIf="userCoustemize?.editCustomization == false ||userCoustemize?.editCustomization==undefined">{{ userCoustemize.item_name | titlecase }} -
   Rs.{{ userCoustemize.item_price}}</h3>
<h3 class="ml-3" *ngIf="userCoustemize?.editCustomization">{{ userCoustemize.item_name | titlecase }} - Rs.{{ userCoustemize.totalPrice}}</h3>
</div>

<div class="check-btn"  *ngIf="Step==1">
    <p class="size_wb">Size</p>
    <div class="form-check d-flex justify-content-between" *ngFor="let item of userCoustemize.itemPortionList;let i = index">
      <input class="form-check-input" type="radio" name="selectedPortion"
        id="rp{{ i }}" (change)="onSelectPortion(item)" [checked]="item.isChecked">
      <label class="form-check-label" for="rp{{ i }}">
        {{ item.portion_name | titlecase }}
      </label>
      <label class="pr-3 extercv">Rs.{{ item.extra_cost }}</label>
    </div>
     <div class="d-flex justify-content-between mt-5">
      <!-- <p>Steps 1/3</p> -->
      <button class="btn btn-success" (click)="coustimezedata()" [disabled]="currPortionEmpty">Continue</button>
  </div>
</div>
<div  class="check-btn" *ngIf="Step==2">
<div *ngFor="let item of userCoustemize.itemAddOnsList;let j=index">
    <p class="way_opks"  >{{ item.add_on_type | titlecase}}</p>
  <div class="cumnwd">
    <div class="form-check d-flex justify-content-between align-items-center " *ngFor="let op of item.addonsOptions; let i=index">
      <input class="form-check-input" type="checkbox"  (change)="onSelectAddOns($event,op)" id="addOns{{op.hash_id}}" [checked]="op.isChecked">
      <label class="form-check-label" for="addOns{{op.hash_id}}">
        {{op.add_on_name | titlecase }}
      </label>
      <label class="pr-3 extercv">Rs.{{ op.extra_cost }} </label>
    </div>
  </div>
  </div>
  <div class="d-flex justify-content-between mt-5">
    <button class="btn btn-success" (click)="createCart()">Continue</button>
   </div>
</div>
</div>
