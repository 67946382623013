import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UserConstants } from 'src/app/utils/userConstant';

@Component({
  selector: 'app-view-rate-review',
  templateUrl: './view-rate-review.component.html',
  styleUrls: ['./view-rate-review.component.scss']
})
export class ViewRateReviewComponent extends Basecomponent implements OnInit {

  constructor(baseService : BaseService) {
    super(baseService);
   }
   servicePersonnelId:string;
  ngOnInit(): void {
    super.ngOnInit();
    this.servicePersonnelId = this.getPassingData().stateData.servicePersonnelId;
    this.apiService.getServicePersonnelRateAndReview(this.servicePersonnelId,'1','50','modal');
  }
  reviewDataList:any[]=[];
  apiResultSubscribe(data: any) {
      if(data.resulttype == UserConstants.RESULT_GET_SERVICE_PERSONNEL_RATING_REVIEW){
        if(data.result.statusCode == 200){
          if(data.inputParams == 'modal'){
            this.reviewDataList = [];
            data.result.data.forEach(element => {
              element.created_by_image_url = (element.created_by_image_url && element.created_by_image_url != 'null') ? UserConstants.AWS_S3_OBJECT_URL + element.created_by_image_url : '/assets/images/default.jpeg';
              this.reviewDataList.push(element);
            });
          }
        }else{
          this.setError(true,data.result);
        }
      }
  }


}
