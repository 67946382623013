export class UserConstants {
  //------------- Component Open/Close Flags ------------//
  public static readonly POPUP: string = "popup";
  public static readonly CONTAINER: string = "container";
  public static readonly PARENT_CONTAINER: string = "parentContainer";
  public static readonly CLOSE_POPUP: string = "closePopup";
  public static readonly RIGHT_CONTAINER: string = "rightContainer";
  public static readonly POPUP_CLOSED: string = "popupClosed";
  public static readonly INFO_PANEL: string = "infoPanel";

  //------------- State Related Flags ---------------//

  //----Order status---//
public static readonly ORDER_STATUS_ORDER_PLACED_BY_USER : string= "order_placed_by_user";
public static readonly ORDER_STATUS_PAYMENT_DONE_FOR_THE_ORDER : string = "payment_done_for_the_order";
public static readonly ORDER_STATUS_ORDER_PLACED_BY_CALL_CENTER_OR_ADMIN : string = "order_placed_by_call_center_or_admin";
public static readonly ORDER_STATUS_ORDER_CANCELLED_BY_USER : string = "order_cancelled_by_user";
public static readonly ORDER_STATUS_ORDER_CANCELLED_BY_CALL_CENTER_OR_ADMIN : string = "order_cancelled_by_call_center_or_admin";
public static readonly ORDER_STATUS_ORDER_CANCELLED_BY_MERCHANT : string = "order_cancelled_by_merchant";
public static readonly ORDER_STATUS_ORDER_ACCEPTED_BY_MERCHANT : string = "order_accepted_by_merchant";
public static readonly ORDER_STATUS_ORDER_CANCELLED_BY_MERCHANT_AFTER_ACCEPTING : string= "order_cancelled_by_merchant_after_accepting";
public static readonly ORDER_STATUS_ORDER_READY_BY_MERCHANT : string = "order_ready_by_merchant";
public static readonly ORDER_STATUS_ORDER_WILL_BE_DELAYED_BY_MERCHANT : string = "order_will_be_delayed_by_merchant";
public static readonly ORDER_STATUS_DELIVERY_ASSIGNED_TO_DELIVERY_BOY : string = "delivery_assigned_to_delivery_boy";
public static readonly ORDER_STATUS_ORDER_PICKED_UP_BY_DELIVERY_BOY : string = "order_picked_up_by_delivery_boy";
public static readonly ORDER_STATUS_DELIVERY_WILL_BE_DELAYED_BY_DELAYED_BY_DELIVERY_BOY : string = "delivery_will_be_delayed_by_delivery_boy";
public static readonly ORDER_STATUS_DELIVERY_BOY_REACHED_AT_DROP_LOCATION : string = "delivery_boy_reached_at_drop_location";
public static readonly ORDER_STATUS_DELIVERY_COMPLETE  :string = "delivery_complete";
public static readonly ORDER_STATUS_USER_RATING_DONE_FOR_THE_ORDER : string = "user_rating_done_for_the_order";
public static readonly ORDER_STATUS_USER_RATING_DONE_FOR_THE_DELIVERY : string = "user_rating_done_for_the_delivery";
public static readonly ORDER_STATUS_ORDER_ACCEPTED_BY_DELIVERY_BOY : string = "order_accepted_by_delivery_boy";
public static readonly ORDER_STATUS_DELIVERY_CANCEL_BY_USER : string = "delivery_cancel_by_user";
public static readonly ORDER_STATUS_ORDER_ACCEPTED_BY_SERVICE_PERSONNEL : string = "order_accepted_by_service_personnel";
public static readonly ORDER_STATUS_ORDER_CANCELLED_BY_SERVICE_PERSONNEL : string = "order_cancelled_by_service_personnel";
public static readonly ORDER_STATUS_ORDER_CANCELLED_BY_SERVICE_PERSONNEL_AFTER_ACCEPTING : string = "order_cancelled_by_service_personnel_after_accepting";
public static readonly ORDER_STATUS_JOB_CARD_PREPARED_BY_SERVICE_PERSONNEL : string = "job_card_prepared_by_service_personnel";
public static readonly ORDER_STATUS_JOB_CARD_DECLINED_BY_USER : string = "job_card_declined_by_user";
public static readonly ORDER_STATUS_JOB_CARD_ACCEPTED_BY_USER : string = "job_card_accepted_by_user";
public static readonly ORDER_STATUS_SERVICE_ORDER_STARTED_BY_SERVICE_PERSONNEL : string = "service_order_started_by_service_personnel";
public static readonly ORDER_STATUS_SERVICE_ORDER_CANCEL_BY_SERVICE_PERSONNEL_AFTER_ACCEPTING_JOB_CARD_BY_USER : string = "order_cancel_by_service_personnel_after_accepting_job_card_by_user";
public static readonly ORDER_STATUS_SERVICE_ORDER_COMPLETE : string = "service_order_complete";
public static readonly ORDER_STATUS_SERVICE_ORDER_BILL_GENERATED_BY_SERVICE_PERSONNEL : string = "service_order_bill_generated_by_service_personnel";
public static readonly ORDER_STATUS_SERVICE_ORDER_BILL_AMOUNT_PAID_BY_CUSTOMER : string = "service_order_bill_amount_paid_by_customer";
public static readonly ORDER_STATUS_ORDER_ACCEPTED_BY_LAUNDRY : string = "order_accepted_by_laundry";
public static readonly ORDER_STATUS_ORDER_CANCELLED_BY_LAUNDRY : string = "order_cancelled_by_laundry";
public static readonly ORDER_STATUS_ORDER_CANCELLED_BY_DRIVER : string = "order_cancelled_by_driver";
public static readonly ORDER_STATUS_ORDER_ACCEPTED_BY_DRIVER : string = "order_accepted_by_driver";
public static readonly ORDER_STATUS_PASSENGER_LOCATED : string = "passenger_located";
public static readonly ORDER_STATUS_PASSENGER_PICKED_UP_BY_DRIVER : string = "passenger_picked_up_by_driver";
public static readonly ORDER_STATUS_REACHED_DROP_LOCATION : string = "reached_drop_location";
public static readonly ORDER_STATUS_PAYMENT_DONE : string = "payment_done";
public static readonly ORDER_STATUS_DELIVERY_BOY_REACHED_AT_PICK_UP_LOCATION : string = "delivery_boy_reached_at_pick_up_location";

public static readonly ORDER_MAXIMUM_DISTANCE_KM : number = 20;
//------------------Sub service Type-------------------//

public static readonly SUB_SERVICE_TYPE_GROCERY : string = "1";
public static readonly SUB_SERVICE_TYPE_RESTAURANT : string = "2";
public static readonly SUB_SERVICE_TYPE_COSMETIC : string = "3";
public static readonly SUB_SERVICE_TYPE_COURIER : string = "4";
public static readonly SUB_SERVICE_TYPE_LAUNDARY : string = "5";
public static readonly SUB_SERVICE_TYPE_PAN : string = "6";
public static readonly SUB_SERVICE_TYPE_VEGETABLE : string = "7";
public static readonly SUB_SERVICE_TYPE_STREET_ORDER : string = "8";
public static readonly SUB_SERVICE_TYPE_CAB_ORDER : string = "9";
public static readonly SUB_SERVICE_TYPE_STATIONARY_ORDER : string = "10";
public static readonly SUB_SERVICE_TYPE_SERVICE_ORDER : string = "11";
public static readonly SUB_SERVICE_TYPE_PERISHABLE_ORDER : string = "12";
public static readonly SUB_SERVICE_TYPE_MEDICINE : string = "13";
public static readonly SUB_SERVICE_TYPE_MEAT_AND_FISH : string = "14";
public static readonly SUB_SERVICE_TYPE_BIKE_ORDER : string = "16";
public static readonly SUB_SERVICE_TYPE_AUTO_ORDER : string = "15";
public static readonly SUB_SERVICE_TYPE_TOTO_ORDER : string = "17";
public static readonly SUB_SERVICE_TYPE_UTILITY_SERVICE : string = "18";
public static readonly SUB_SERVICE_TYPE_DHOBI : string = "19";
public static readonly SUB_SERVICE_TYPE_UNLISTED_SHOP : string = "21";

  //----------------- Link Related -------------------//
  public static readonly RL_LOGIN: string = "login";
  public static readonly RL_SIGN_UP : string = "sign-up";
  public static readonly RL_HOME : string = "home"
  public static readonly RL_SELECT_LOCATION : string = "selectLocation";
  public static readonly RL_SUB_SERVICE_PAGE : string = "subService";
  public static readonly RL_SUB_SERVICE_DETAIL : string = "serviceDetail";
  public static readonly RL_GLOBAL_SEARCH : string = "globalSearch";
  public static readonly RL_CART_CHECKOUT : string = "cartCheckout";
  public static readonly RL_MY_ACCOUNT : string ="myAccount";
  public static readonly RL_EDIT_PROFILE : string = "editProfile";
  public static readonly RL_APPLY_COUPON : string = "applyCoupon";
  public static readonly RL_ADD_PROFILE_PICTURE : string ="addProfilePicture";
  public static readonly RL_ADD_BACKGROUND_PICTURE : string = "addBackgroundPicture";
  public static readonly RL_ADD_DELIVERY_ADDRESS : string = "addDeliveryAddress";
  public static readonly RL_PAYTM_PAYMENT : string = "paytmPayment";
  public static readonly RL_BOOK_TABLE : string = "bookTable";
  public static readonly RL_SELECT_DELIVERY_LOCATION : string = "Select delivery location";
  public static readonly RL_TRACK_ORDER : string = "Track order";
  public static readonly RL_CUSTOMIZE : string = "Customize";
  public static readonly RL_ORDER_DETAILS : string = "orderDetails";
  public static readonly RL_ADD_PHARMACY_DOCUMENT : string = "addPharmacyDocument";
  public static readonly RL_CONTACT_US : string = "contactUs";
  public static readonly RL_ABOUT_US : string = "aboutUs";
  public static readonly RL_PRIVACY_POLICY : string = "privacyPolicy";
  public static readonly RL_SCRATCH_CARD : string = "ScratchCard";
  public static readonly RL_VIEW_WALLET_HISTORY : string = "ViewWalletHistory";
  public static readonly RL_BANK_DETAILS : string = "BankDetails";
  public static readonly RL_TRANSACTIONS : string = "Transaction";
  public static readonly RL_REDEEM_TO_ACCOUNT : string = "RedeemToAccount";
  public static readonly RL_NOT_ELIGIBLE_TO_CASH : string = "NotEligibleToRedeemCash";
  public static readonly RL_REQUEST_TO_REDEEM_CASH : string = "RequestToRedeemCash";
  public static readonly RL_USER_BANK_ACCOUNT_DETAILS : string = "UserBankAccountDetails";
  public static readonly RL_TRACK_ORDER_MAP : string = "TrackOrderMap";
  public static readonly RL_NOTIFICATION : string = "notifications";
  public static readonly RL_SETTING : string = "settings";
  public static readonly RL_ORDERS_HISTORY : string = "ordersHistory";
  public static readonly RL_TABLE_BOOKINGS : string = "tableBookings";
  public static readonly RL_LAUNDRY_DETAILS : string = "laundryDetails";
  public static readonly RL_CAB_BOOKING : string = "cabBooking";
  public static readonly RL_BIKE_BOOKING : string = "bikeBooking";
  public static readonly RL_AUTO_BOOKING : string = "autoBooking";
  public static readonly RL_TOTO_BOOKING : string = "totoBooking";
  public static readonly RL_CAB_CHECKOUT : string = "cabCheckout";
  public static readonly RL_BIKE_CHECKOUT : string = "bikeCheckout";
  public static readonly RL_AUTO_CHECKOUT : string = "autoCheckout";
  public static readonly RL_TOTO_CHECKOUT : string = "totoCheckout";
  public static readonly RL_CAB_BOOKING_DETAIL : string = "cabBookingDetail";
  public static readonly RL_AUTO_BOOKING_DETAIL : string = "autoBookingDetail";
  public static readonly RL_BIKE_BOOKING_DETAIL : string = "bikeBookingDetail";
  public static readonly RL_TOTO_BOOKING_DETAIL : string = "totoBookingDetail";
  public static readonly RL_RIDE_HISTORY : string = "rideHistory";
  public static readonly RL_RIDE_TRACK_MAP : string = "trackRideMap";
  public static readonly RL_COURIER_BOOKING : string = "courierBooking";
  public static readonly RL_COURIER_BOOKING_DETAIL : string = "courierBookingDetail";
  public static readonly RL_COURIER_BOOKING_CHECKOUT : string = "courierBookingCheckout";
  public static readonly RL_COURIER_TRACK_ORDER : string = "courierTrackOrder";
  public static readonly RL_BOOKING_HISTORY : string = "bookingHistory";
  public static readonly RL_VIEW_JOB_CARD : string = "viewJobCard";
  public static readonly RL_VIEW_RATE_REVIEW : string = "viewRateReview";
  public static readonly RL_UTILITY : string = "utility";
  public static readonly RL_UTILITY_DETAIL : string = "utilityDetail";
  public static readonly RL_UNLISTED_SHOP : string = "unlistedShop";
  public static readonly RL_UNLISTED_SHOP_CHECKOUT : string = "unlistedShopCheckout";
  public static readonly RL_UNLISTED_SHOP_BOOKING_DETAIL : string="unlistedShopBookingDetail";
  public static readonly RL_UTILITY_CHECKOUT : string = "utilityCheckout";
  public static readonly RL_UTILITY_BOOKING_DETAIL : string = "utilityBookingDetail";
  public static readonly RL_UTILITY_JOB_CARD_LIST : string = "utilityJobCardList";
  public static readonly RL_LAUNDRY_BOOKING_DETAIL : string = "laundryBookingDetail";
  public static readonly RL_SERVICE_ORDER_MAP : string = "serviceOrderMap";
  public static readonly RL_SURPRISE_ME : string = "surpriseMe";
  public static readonly RL_SURPRISE : string = "surprise";
  public static readonly RL_CAB_BIDS : string = "cabBids";
  public static readonly RL_RIDE_BID : string = "rideBid";
  public static readonly RL_MERCHANT_ORDER : string = "merchantOrder";
  public static readonly RL_BIKE_BIDS : string = "bikebids";
  public static readonly RL_BIKE_RIDE_BIDS : string = "bikeridebids";
  public static readonly RL_DOWNLOAD_APP : string = "downloadapp";
  public static readonly RL_BIKE_DOWNLOAD_APP : string = "bikedownloadapp";
  public static readonly RL_AUTO_DOWNLOAD_APP : string = "autodownloadapp";
  public static readonly RL_TOTO_DOWNLOAD_APP : string = "totodownloadapp";
  public static readonly RL_SURPRISE_ME_BIDS : string = "surprisemebids";

  public static readonly RL_CONFRIM_BIDS : string = "confrimbids";
  public static readonly RL_INVITE_OTHERS : string = "inviteothers";
  public static readonly RL_SURPRISE_ME_REQUEST : string = "surprisemerequest";
  public static readonly RL_MERCHANT_ORDER_DETAILS : string = "merchantOrderDetails";
  public static readonly RL_RIDE_BOOKING_DETAILS : string = "ridebookingdetails";
  public static readonly RL_TERMS_OF_SERVICE : string = "termsofservice";
  public static readonly RL_ADD_YOUR_LAUNDRY_SERVICE : string = "laundry";
  public static readonly RL_DRIVE_AND_EARN_LIMITLESS : string = "driver";
  public static readonly RL_JOIN_DELIVERY_PERSONNAL : string = "delivery";
  public static readonly RL_RETURN_POLICY : string = "returnpolicy";
  public static readonly RL_REFUND_POLICY : string = "refundpolicy";
  public static readonly RL_HELP_GUIDES : string = "helpguides";




  


















  //------------------ API URL ----------------------- //

  public static GET_TEST_DATA_URL : string = "";
  public static GOOGLE_PLACES_API_URL : string = "";
  public static AWS_S3_OBJECT_URL: string = "";
  public static USER_LOGIN_IN_DATA : string = "";
  public static GET_ZONE_INFO_BY_LOCATION : string = "";
  public static SET_NORMAL_USER_ZONE_MAP : string = "";
  public static GET_ZONE_SPECIFIC_SUB_SERVICE_LIST : string = "";
  public static GET_BUSINESS_LIST_BY_SUB_SERVICE : string = "";
  public static GET_BUSINESS_DETAIL_BY_ID : string = "";
  public static GET_INVENTORY_ITEM_FOR_NORMAL_USER : string = "";
  public static SEARCH_INVENTORY_ITEM_FOR_NORMAL_USER : string = "";
  public static GET_BUSINESS_LIST_BY_ZONE_ID : string = "";
  public static GET_ITEM_CATEGORY_AS_PER_KEY : string = "";
  public static UPDATE_USER_DATA : string = "";
  public static ADD_NEW_JOINING_INTEREST_REQUEST_DATA : string = "";
  public static CREATE_USER_CART : string = "";
  public static GET_USER_CART : string = "";
  public static GET_NORMAL_USER_DETAIL_BY_ACCOUNT_ID : string = "";
  public static ADD_EDIT_DELIVERY_ADDRESS : string = "";
  public static GET_DELIVERY_ADDRESS : string = "";
  public static DELETE_DELIVERY_ADDRESS : string = "";
  public static SET_DEFAULT_DELIVERY_ADDRESS : string = "";
  public static BOOK_RESTAURANT_TABLE : string = "";
  public static UPDATE_CART_QUANTITY : string = "";
  public static REMOVE_CART_ITEM : string = "";
  public static DELETE_USER_CART : string = "";
  public static VALIDATE_COUPON : string = "";
  public static GET_AVAILABLE_COUPON : string = "";
  public static GET_CHECKOUT_VALUE : string = "";
  public static CREATE_USER_ORDER : string = "";
  public static CREATE_LAUNDRY_USER_ORDER : string = "";
  public static GET_ORDER_BY_USER_ID : string = "";
  public static GET_ORDER_DETAIL_FOR_NORMAL_USER : string = "";
  public static UPDATE_ORDER_STATUS : string = "";
  public static GET_ORDER_TRACK_HISTORY : string = "";
  public static GET_TABLE_BOOKING_INFO : string = "";
  public static ADD_DELIVERY_RATING : string = "";
  public static ADD_MERCHANT_RATING : string = "";
  public static GET_DELIVERY_PERSON_INFO_BY_ORDER_ID : string = "";
  public static ADD_CONTACT_US_INFO : string = "";
  public static GET_WALLET_INFO_BY_ACCOUNT_ID : string = "";
  public static GET_SCRATCH_CARD_PROVIDED_TO_USER : string = "";
  public static REDEEMED_SCRATCH_CARD : string = "";
  public static GET_BUSINESS_PROMOTION_BY_ZONE : string = "";
  public static GET_USER_NOTIFICATION : string = "";
  public static GET_LAUNDRY_LIST : string = "";
  public static GET_LAUNDRY_DETAIL_BY_ID : string = "";
  public static GET_INVENTORY_ITEM_LAUNDRY : string = "";
  public static SEARCH_INVENTORY_ITEM_LAUNDRY : string = "";
  public static GET_ITEM_CATEGORY_LAUNDRY : string = "";
  public static GET_CAB_DATA_BY_ADDRESS_DATE : string = "";
  public static GET_RIDE_COST_SETUP : string = "";
  public static ADD_EDIT_BIKE_ORDER : string = "";

  public static ADD_EDIT_CAB_ORDER : string = "";

  public static GET_CAB_ORDER_DETAIL_FOR_USER : string = "";
  public static GET_AUTO_ORDER_DETAIL_FOR_USER : string = "";
  public static GET_TOTO_ORDER_DETAIL_FOR_USER : string = "";
  public static GET_BIKE_ORDER_DETAIL_FOR_USER : string = "";

  public static ADD_EDIT_AUTO_ORDER : string = "";
  public static ADD_EDIT_TOTO_ORDER : string = "";

  public static GET_BUSINESS_PROMOTION_BY_SUB_SERVICE_ID;
  public static GET_ALL_VEHICLE_RIDE_HISTORY : string = "";
  public static GET_SERVICE_ENTITY_BY_SUB_SERVICE_AND_ZONE_HASH_ID : string = "";
  public static GET_PICK_AND_DROP_DELIVERY_COST_ZONE_SPECIFIC : string ="";
  public static GET_SUB_SERVICE_SERVICE_PERSONEL : string = "";
  public static GET_SERVICE_PERSONAL_RATE_LIST : string = "";
  public static GET_SERVICE_PERSONEL_INFO_BY_USER_HASH_ID : string = "";
  public static GET_SERVICE_PERSONEL_BUSINESS_DETAIL_BY_USER_HASH_ID : string = "";
  public static ADD_EDIT_COURIER_BOOKING : string = "";
  public static GET_COURIER_BOOKING_DETAIL : string = "";
  public static GET_COURIER_BOOKING_HISTORY : string = "";
  public static UPDATE_COURIER_BOOKING_STATUS : string ="";
  public static GET_DISCOUNT_BY_SUB_SERVICE_ID : string = "";

  public static CREATE_UNLISTED_SHOP_ORDER : string = "";
  public static GET_UNLISTED_SHOP_ORDER_DETAIL_BY_ID : string = "";
  public static UPDATE_UNLISTED_SHOP_ORDER_STATUS : string = "";
  public static GET_COURIER_ORDER_TRACKING_HISTORY : string = "";
  public static GET_UNLISTED_SHOP_ORDER_TRACKING_HISTORY : string = "";
  public static GET_BIKE_DATA_BY_ADDRESS_DATE : string = "";
  public static GET_COURIER_DELIVERY_PERSON_INFO_BY_ORDER_ID : string = "";
  public static GET_INDIVIDUAL_SERVICE_PERSONEL_COST_SETUP : string = "";
  public static CREATE_UTILITY_ORDER : string = "";
  public static UPDATE_SERVICE_PERSONNEL_ORDER_STATUS : string = "";
  public static GET_SERVICE_PERSONNEL_ORDER_JOB_CARD : string = "";
  public static ADD_EDIT_SERVICE_PERSONNEL_PAYMENT : string = "";
  public static GET_UTILITY_ORDER_TRACKING_HISTORY : string = "";
  public static GET_ALL_TYPE_SERVICE_ORDER_BOOKING_HISTORY : string = "";
  public static GET_UTILITY_ORDER_DETAIL_BY_ID : string = "";
  public static GET_SERVICE_PERSONNEL_ORDER_BILL_PAYMENT : string = "";
  public static GET_SERVICE_PERSONNEL_ORDER_PAID_DATA_LIST : string ="";
  public static GET_LAUNDRY_ORDER_DETAIL_BY_ORDER_ID : string = "";
  public static UPDATE_LAUNDRY_ORDER_STATUS : string = "";
  public static ADD_SERVICE_PERSONNEL_RATING : string = "";
  public static GET_SERVICE_PERSONNEL_RATING_REVIEW : string = "";
  public static GET_RIDE_TRACK_HISTORY : string = "";
  public static GET_SURPRISE_ME_ITEMS : string = "";
  public static SUBMIT_SURPRISE_ME_ORDER : string = "";
  public static GET_SURPRISE_ME_BY_USER : string = "";
  public static GET_SURPRISE_ME_BIDS : string = "";
  public static GET_REMOVE_SUPRISE_ME_ORDER : string = "";
  public static GET_PAYTM_PAYMENT : string = "";
  public static GET_PAYTM_PAYMENT_RECORD : string = "";
  public static GET_PAYTM_TRANSACTION_STATUS : string = "";
  public static GET_USER_BANK_DETAILS : string = "";
  public static DELETE_USER_BANK_DETAILS : string = "";
  public static GET_USER_WALLET_EARN_CASH_REPORT : string = "";
  public static ADD_REEDEM_WALLET_CASH : string = "";
  public static ADD_USER_BANK_DETAILS : string = "";
  public static GET_RIDE_ORDER_HISTORY : string = "";
  public static GET_RIDE_ORDER_DETAILS : string = "";
  public static GET_ITEM_CUSTOMIZATION : string = "";
  public static EDIT_CUSTOMIZABLE_FOR_MERCHANT : string = "";
  public static GET_DRIVER_DELIVERY_LOCATION : string = "";
  public static ADD_PHONE_CALL_BY_MASKING : string = "";


  // ----------------- Result Type ------------------- //
  public static readonly RESULT_GET_TEST_DATA : string = "resultGetTestData";
  public static readonly RESULT_GET_LOG_IN_DATA : string = "resultGetLogInData";
  public static readonly RESULT_GET_ZONE_INFO_BY_LOCATION : string = "resultGetZoneInfoByLocation";
  public static readonly RESULT_GET_ZONE_INFO : string = "resultGetZoneInfo";
  public static readonly RESULT_SET_NORMAL_USER_ZONE_MAP : string = "resultSetNormalUserZoneMap";
  public static readonly RESULT_GET_ZONE_SPECFIC_SUB_SERVICE_LIST : string = "resultGetZoneSpecificSubServiceList";
  public static readonly RESULT_GET_BUSINESS_LIST_BY_SUB_SERVICE : string = "resultGetBusinessListBySubService";
  public static readonly RESULT_GET_BUSINESS_DETAIL_BY_ID : string = "resultGetBusinessDetailById";
  public static readonly RESULT_GET_INVENTORY_ITEM_FOR_NORMAL_USER : string = "resultGetInventoryItemForNormalUser";
  public static readonly RESULT_SEARCH_INVENTORY_ITEM_FOR_NORMAL_USER : string = "resultSearchInventoryItemForNormalUser";
  public static readonly RESULT_GET_BUSINESS_LIST_BY_ZONE_ID : string = "resultGetBusinessListByZoneId";
  public static readonly RESULT_GET_ITEM_CATEGORY_AS_PER_KEY : string = "resultGetItemCategoryAsPerKey";
  public static readonly RESULT_UPDATE_USER_DATA : string = "resultUpdateUserData";
  public static readonly RESULT_ADD_NEW_JOINING_INTEREST_REQUEST_DATA : string = "resultAddNewJoiningInterestData";
  public static readonly RESULT_CREATE_USER_CART : string = "resultCreateUserCart";
  public static readonly RESULT_GET_USER_CART : string = "resultGetUserCart";
  public static readonly RESULT_GET_NORMAL_USER_DETAIL_BY_ACCOUNT_ID : string = "resultGetNormalUserDetailByAccountId";
  public static readonly RESULT_ADD_EDIT_DELIVERY_ADDRESS : string = "resultAddEditDeliveryAddress";
  public static readonly RESULT_GET_DELIVERY_ADDRESS : string = "resultGetDeliveryAddress";
  public static readonly RESULT_DELETE_DELIVERY_ADDRESS : string = "resultDeleteDeliveryAddress";
  public static readonly RESULT_SET_DEFAULT_DELIVERY_ADDRESS : string = "resultSetDefaultDeliveryAddress";
  public static readonly RESULT_BOOK_RESTAURANT_TABLE : string = "resultBookRestaurantTable";
  public static readonly RESULT_UPDATE_CART_QUANTITY : string = "resultUpdateCartQuantity";
  public static readonly RESULT_REMOVE_CART_ITEM : string = "resultRemoveCartItem";
  public static readonly RESULT_DELETE_USER_CART : string = "resultDeleteUserCart";
  public  static readonly RESULT_VALIDATE_COUPON : string = "resultValidateCoupon";
  public static readonly RESULT_GET_AVAILABLE_COUPON : string = "resultGetAvailableCoupon";
  public static readonly RESULT_GET_CHECKOUT_VALUE : string = "resultGetCheckoutValue";
  public static readonly RESULT_CREATE_USER_ORDER : string = "resultCreateUserOrder";
  public static readonly RESULT_CREATE_LAUNDRY_USER_ORDER : string = "resultCreateLaundryUserOrder";
  public static readonly RESULT_GET_ORDER_BY_USER_ID : string = "resultGetOrderByUserId";
  public static readonly RESULT_GET_ORDER_DETAIL_FOR_NORMAL_USER : string = "resultGetOrderDetailForNormalUser";
  public static readonly RESULT_UPDATE_ORDER_STATUS : string = "resultUpdateOrderStatus";
  public static readonly RESULT_GET_ORDER_TRACK_HISTORY : string = "resultGetOrderTrackHistory";
  public static readonly RESULT_GET_TABLE_BOOKING_INFO : string = "resultGetTableBookingInfo";
  public static readonly RESULT_ADD_DELIVERY_RATING : string = "resultAddDeliveryRating";
  public static readonly RESULT_ADD_MERCHANT_RATING : string = "resultAddMerchantRating";
  public static readonly RESULT_GET_DELIVERY_PERSON_INFO_BY_ORDER_ID : string = "resultGetDeliveryPersonInfoByOrderId";
  public static readonly RESULT_ADD_CONTACT_US_INFO : string = "resultAddContactUsInfo";
  public static readonly RESULT_GET_WALLET_INFO_BY_ACCOUNT_ID : string = "resultGetWalletInfoByAccountId";
  public static readonly RESULT_GET_SCRATCH_CARD_PROVIDED_TO_USER : string = "resultGetScratchCardProvidedToUser";
  public static readonly RESULT_REDEEMED_SCRATCH_CARD : string = "resultRedeemedScratchCard";
  public static readonly RESULT_GET_BUSINESS_PROMOTION_BY_ZONE : string = "resultGetBusinessPromotionByZone";
  public static readonly RESULT_GET_BUSINESS_PROMOTION_BY_ZONE2 : string = "resultGetBusinessPromotionByZone2";
  public static readonly RESULT_GET_USER_NOTIFICATION : string = "resultGetUserNotification";
  public static readonly RESULT_SEARCH_BUSINESS_LIST_BY_SUB_SERVICE : string = "resultSearchBusinessListBySubService";
  public static readonly RESULT_GET_BUSINESS_PROMOTIOM_BY_SUB_SERVICE_ID : string = "resultGetBusinessPromotionBySubServiceId";

  public static readonly RESULT_GET_LAUNDRY_LIST : string = "resultGetLaundryList";
  public static readonly RESULT_SEARCH_LAUNDRY_LIST : string = "resultSearchLaundryList";
  public static readonly RESULT_GET_LAUNDRY_DETAIL_BY_ID : string = "resultGetLaundryDetailById";
  public static readonly RESULT_GET_INVENTORY_ITEM_LAUNDRY : string = "resultGetInventoryItemLaundry";
  public static readonly RESULT_SEARCH_INVENTORY_ITEM_LAUNDRY : string = "resultSearchInventoryItemLaundry";
  public static readonly RESULT_GET_ITEM_CATEGORY_LAUNDRY : string = "resultGetItemCategoryLaundry";
  public static readonly RESULT_GET_CAB_DATA_BY_ADDRESS_DATE : string = "resultGetCabDataByAddressDate";
  public static readonly RESULT_GET_RIDE_COST_SETUP : string = "resultGetRideCostSetup";

  public static readonly RESULT_GET_BIKE_ORDER_DETAIL_FOR_USER : string = "resultGetBikeOrderDetailForUser";
  public static readonly RESULT_GET_CAB_ORDER_DETAIL_FOR_USER : string = "resultGetCabOrderDetailForUser";
  public static readonly RESULT_GET_AUTO_ORDER_DETAIL_FOR_USER : string = "resultGetAutoOrderDetailForUser";
  public static readonly RESULT_GET_TOTO_ORDER_DETAIL_FOR_USER : string = "resultGetTotoOrderDetailForUsers";

  public static readonly RESULT_ADD_EDIT_BIKE_ORDER : string = "resultAddEditBikeOrder";

  public static readonly RESULT_ADD_EDIT_CAB_ORDER : string = "resultAddEditCabOrder";

  public static readonly RESULT_ADD_EDIT_AUTO_ORDER : string = "resultAddEditAutoOrder";

  public static readonly RESULT_ADD_EDIT_TOTO_ORDER : string = "resultAddEditTotoOrder";


  public static readonly RESULT_GET_ALL_VEHICLE_RIDE_HISTORY : string = "resultGetAllVehicleRideHistory";
  public static readonly RESULT_GET_SERVICE_ENTITY_BY_SUB_SERVICE_AND_ZONE_HASH_ID : string = "resultGetServiceEntityBySubServiceAndZoneHashId";
  public static readonly RESULT_SEARCH_SERVICE_ENTITY_BY_SUB_SERVICE_AND_ZONE_HASH_ID : string = "resultSearchServiceEntityBySubServiceAndZoneHashId";
  public static readonly RESULT_GET_PICK_AND_DROP_DELIVERY_COST_ZONE_SPECIFIC : string = "resultGetPickAndDropDeliveryCostZoneSpecific";
  public static readonly RESULT_GET_SUB_SERVICE_SERVICE_PERSONEL : string = "resultGetSubServiceServicePersonel";
  public static readonly RESULT_GET_SERVICE_PERSONAL_RATE_LIST : string = "resultGetServicePersonelRateList";
  public static readonly RESULT_GET_SERVICE_PERSONEL_INFO_BY_USER_HASH_ID : string = "resultGetServicePersonelInfoByUserHashId";
  public static readonly RESULT_GET_SERVICE_PERSONEL_BUSINESS_DETAIL_BY_USER_HASH_ID : string = "resultGetServicePersonelBusinessDetailByUserHashId";
  public static readonly RESULT_ADD_EDIT_COURIER_BOOKING : string = "resultAddEditCourierBooking";
  public static readonly RESULT_GET_COURIER_BOOKING_DETAIL : string = "resultGetCourierBookingDetail";
  public static readonly RESULT_GET_COURIER_BOOKING_HISTORY : string = "resultGetCourierBookingHistory";
  public static readonly RESULT_UPDATE_COURIER_BOOKING_STATUS : string = "resultUpdateCourierBookingStatus";
  public static readonly RESULT_GET_DISCOUNT_BY_SUB_SERVICE_ID : string = "resultGetDiscountBySubServiceId";

  public static readonly RESULT_CREATE_UNLISTED_SHOP_ORDER : string = "resultCreateUnlistedShopOrder";
  public static readonly RESULT_GET_UNLISTED_SHOP_ORDER_DETAIL_BY_ID : string = "resultGetUnlistedShopOrderDetailById";
  public static readonly RESULT_UPDATE_UNLISTED_SHOP_ORDER_STATUS : string = "resultUpdateUnlistedShopOrderStatus";

  public static readonly RESULT_GET_COURIER_ORDER_TRACKING_HISTORY : string = "resultGetCourierOrderTrackingHistory";
  public static readonly RESULT_GET_UNLISTED_SHOP_ORDER_TRACKING_HISTORY : string = "resultGetUnlistedShopOrderTrackingHistory";

  public static readonly RESULT_GET_BIKE_DATA_BY_ADDRESS_DATE : string = "resultGetBikeDataByAddressDate";
  public static readonly RESULT_GET_COURIER_DELIVERY_PERSON_INFO_BY_ORDER_ID : string = "resultGetCourierDeliveryPersonInfoByOrderId";
  public static readonly RESULT_GET_INDIVIDUAL_SERVICE_PERSONEL_COST_SETUP : string = "resultGetIndividualServiceCostSetup";

  public static readonly RESULT_CREATE_UTILITY_ORDER : string = "resultCreateUtilityOrder";

  public static readonly RESULT_UPDATE_SERVICE_PERSONNEL_ORDER_STATUS : string = "resultUpdateServicePersonnelOrderStatus";
  public static readonly RESULT_GET_SERVICE_PERSONNEL_ORDER_JOB_CARD : string = "resultGetServicePersonnelOrderJobCard";
  public static readonly RESULT_ADD_EDIT_SERVICE_PERSONNEL_PAYMENT : string = "resultAddEditServicePersonnelPayment";
  public static readonly RESULT_GET_UTILITY_ORDER_TRACKING_HISTORY : string = "resultGetUtilityOrderTrackingHistory";
  public static readonly RESULT_GET_UTILITY_ORDER_DETAIL_BY_ID : string = "resultGetUtilityOrderDetailById";
  public static readonly RESULT_GET_ALL_TYPE_SERVICE_ORDER_BOOKING_HISTORY : string = "resultGetAllTypeServiceOrderBookingHistory";
  public static readonly RESULT_GET_SERVICE_PERSONNEL_ORDER_BILL_PAYMENT : string = "resultGetServicePersonnelOrderBillPayment";
  public static readonly RESULT_GET_SERVICE_PERSONNEL_ORDER_PAID_DATA_LIST : string = "resultGetServicePersonnelOrderPaidDataList";
  public static readonly RESULT_GET_LAUNDRY_ORDER_DETAIL_BY_ORDER_ID : string = "resultGetLaundryOrderDetailByOrderId";
  public static readonly RESULT_UPDATE_LAUNDRY_ORDER_STATUS : string = "resultUpdateLaundryOrderStatus";
  public static readonly RESULT_ADD_SERVICE_PERSONNEL_RATING : string = "resultAddServicePersonnelRating";
  public static readonly RESULT_GET_SERVICE_PERSONNEL_RATING_REVIEW : string = "resultGetServicePersonnelRatingReview";
  public static readonly RESULT_GET_RIDE_TRACK_HISTORY : string = "resultGetRideTrackHistory";
  public static readonly RESULT_GET_SURPRISE_ME_ITEMS : string = "resultMerchantItemForSearch";
  public static readonly RESULT_SUBMIT_SURPRISE_ME_ORDER : string = "resultSubmitSurpriseMeOrder";
  public static readonly RESULT_GET_SURPRISE_ME_BY_USER : string = "resultSurpriseMeRequestsByUser";
  public static readonly RESULT_GET_SURPRISE_ME_BIDS : string = "resultgetSurpriseMeBids";
  public static readonly RESULT_REMOVE_SUPRISE_ME_ORDER : string = "resultremoveSurpriseMeReq";
  public static readonly RESULT_PAYTM_PAYMNT : string = "resultinitiateTransaction";
  public static readonly RESULT_PAYTM_PAYMENT_RECORD : string = "resulPaytmPaymentRecord";
  public static readonly RESULT_PAYTM_TRANSACTION_STATUS : string = "resulPaytmtransactionStatus";
  public static readonly RESULT_GET_USER_BANK_DETAILS : string = "resultuserbankdetails";
  public static readonly RESULT_DELETE_USER_BANK_DETAILS : string = "resultdeleteuserbankdetails";
  public static readonly RESULT_GET_USER_WALLET_EARN_CASH_REPORT : string = "resultearnwalletcash";
  public static readonly RESULT_ADD_REEDEM_WALLET_CASH : string = "resultreedemwalletcash";
  public static readonly RESULT_ADD_USER_BANK_DETAILS : string = "resultaddusebankdatils";
  public static readonly RESULT_GET_RIDE_ORDER_HISTORY : string = "resultrideorderhistory";
  public static readonly RESULT_GET_RIDE_ORDER_DETAILS : string = "resultrideorderdetails";
  public static readonly RESULT_GET_ITEM_CUSTOMIZATION : string = "resultgetitemcustomization";
  public static readonly RESULT_EDIT_CUSTOMIZABLE_FOR_MERCHANT : string = "resulteditcustomizableformerchant";
  public static readonly RESULT_GET_DRIVER_DELIVERY_LOCATION : string = "resultdrivercurrentlocation";
  public static readonly RESULT_ADD_PHONE_CALL_BY_MASKING : string = "resultphonecallbymasking";


  // ----------------- File Upload Type ------------------- //


  // ------------ Bucket name --------//
  public static readonly BUCKET_TYPE_PROFILE_DOC : string = "profiledoc";
  public static readonly BY_DEFAULT_GST_CHARGE:number=18;
//-------------------- Sub Service Type list ------------------//
public static readonly SERVICE_PERSONEL_TYPE_LIST_CARPENTER : string = '1';
public static readonly SERVICE_PERSONEL_TYPE_LIST_PLUMBER : string = '2';
public static readonly SERVICE_PERSONEL_TYPE_LIST_ELECTRICIAN : string = '3';
public static readonly SERVICE_PERSONEL_TYPE_LIST_SWEEPER : string = '4';
public static readonly SERVICE_PERSONEL_TYPE_LIST_BEAUTICIAN : string = '5';
public static readonly SERVICE_PERSONEL_TYPE_LIST_NURSE : string = '6';
public static readonly SERVICE_PERSONEL_TYPE_LIST_BAI : string = '7';

  // ----- regex -----//
  public static readonly REGEX_TYPE_NAME : string = "([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,30}";
  public static readonly REGEX_TYPE_NUMBER : string = "^[0-9]*$";
  public static readonly REGEX_TYPE_AMOUNT : string = '^[0-9]?((\.[0-9]+)|([0-9]+(\.[0-9]+)?))$';
  public static readonly REGEX_TYPE_DATE : string = '([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))';
  public static readonly REGEX_TYPE_TIME : string = '(00|[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9])';


  // --------------------- Others --------------------//
  public static readonly KEY_ACCOUNT_HASH_ID: string = "dratooUseruserKeyAccountHashId";
  public static readonly KEY_MOBILE : string = "dratooUserphoneNumber";
  public static readonly KEY_MOBILE_NEW : string = "dratooUserphoneNumbernew";
  public static readonly KEY_NAME : string = "dratooUserusername";
  public static readonly KEY_USER_ID : string = "dratooUseruserId";
  public static readonly KEY_PASSWORD : string = "dratooUserpassword";
  public static readonly KEY_AUTH_ID : string = "dratooUserawsHashId";
  public static readonly KEY_USER_TYPE : string = "dratooUseruserType";
  public static readonly KEY_SELECTED_ZONE_HASH_ID : string = "dratooUseruserSelectedZoneHashId";
  public static readonly KEY_SELECTED_FORMATTED_ADDRESS : string = "dratooUserselectedFormattedAddress";
  public static readonly KEY_SELECTED_LOCATION : string = "dratooUserselectedLocation";
  public static readonly KEY_SELECTED_LATITUDE : string = "dratooUserselectedLatitude";
  public static readonly KEY_SELECTED_LONGITUDE : string = "dratooUserselectedLongitude";
  public static readonly KEY_SELECTED_CART_ITEM : string = "dratooUseruserSelectedCartInventoryItem";
  public static readonly KEY_ITEM_CART_BELL_STATUS : string = "dratooUseritemCartBellStatus";
  public static readonly KEY_USER_IMAGE : string = "dratooUseruserImageUrl";
  public static readonly KEY_SEARCH_RIDE_PICKUP_LOCATION : string = "dratooUsersearchRidePickupLocation";
  public static readonly KEY_SEARCH_RIDE_DROP_LOCATION : string = "dratooUsersearchRideDropLocation";
  public static readonly KEY_FCM_TOKEN : string = "dratooUserFCMToken";
  /**User Type */
  public static readonly USER_TYPE_ADMIN : string = "0";
  public static readonly USER_TYPE_SUB_ADMIN : string = "2";
  public static readonly USER_TYPE_NORMAL_USER : string = "1";
  public static readonly USER_TYPE_CALL_CENTER : string= '3';
  public static readonly USER_TYPE_CITY_MANAGER : string= '4';
  public static readonly USER_TYPE_DRIVER : string= '5';
  public static readonly USER_TYPE_DELIVERY_PERSON : string= '6';
  public static readonly USER_TYPE_FLEET_MANAGER : string= '7';
  public static readonly USER_TYPE_MERCHANT : string= '8';
  public static readonly USER_TYPE_LAUNDRY : string= '9';
  public static readonly USER_SERVICE_PERSONNEL : string= '10';


  public static readonly DEFAULT_FILE_INPUT_TEXT: string =
    "Choose File (image or document file)";
  public static readonly DEFAULT_INPUT_VALIDATION_MESSAGE: string =
    "Please enter the required details marked with (*)";

  // ---------------- State ID -----------------------//
  public static readonly STATE_MODE: string = "stateMode";
  public static readonly STATE_PASSING_DATA: string = "statePassingData";
  public static readonly STATE_FROM_WINDOW_TRACK: string =
    "stateFromWindowTrack";
  public static readonly STATE_DRIVER_LOCATIONS: string =
    "stateDriverLocations";

  public static readonly ADMIN_DETAIL_STATE_UPDATE : string = "adminDetailStateUpdate";
}
