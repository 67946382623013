import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { UserConstants } from 'src/app/utils/userConstant';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent extends Basecomponent implements OnInit{
  walletEarnCash:any=[];
  userHashId: any = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID) : localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID);
  zoneHashId = (this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_ZONE_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_ZONE_HASH_ID) : localStorage.getItem(UserConstants.KEY_SELECTED_ZONE_HASH_ID);
  constructor(baseService: BaseService,
    private userStorage: UserStorageService,) {
    super(baseService);
  }

  ngOnInit(): void {
    this.apiService.getWalletEncashReport(this.userHashId,this.zoneHashId)
  }
   apiResultSubscribe(data: any) {
    if(data.resulttype == UserConstants.RESULT_GET_USER_WALLET_EARN_CASH_REPORT){
      if (data.result.statusCode == 200) {
        this.walletEarnCash=[];
        data.result.data.forEach((ele)=>{
          this.walletEarnCash.push(ele);
        })
      }else{
        this.setError(true,data.result);
      }
    }
  }

}
