<div>
    <error-exception-comp></error-exception-comp>
    <form [formGroup]="personalInfoForm" (ngSubmit)="saveDetail()">
        <div class="add_profile_drat">
            <div class="frm_fl">
                <div class="add_scroll">
                    <div class="ad_ox">
                        <div class="row">
                            <div class="col-md-12">
                                <label>Name
                </label>
                                <input type="text" class="input-field border" class="form-control" name="name" placeholder="Enter Your name" formControlName="name" required autocomplete="no">
                                <strong *ngIf="personalInfoForm.get('name').invalid && personalInfoForm.get('name').touched" style="color: 'red'; font-size: small">Enter valid name</strong>
                            </div>

                            <!-- <div class="col-md-6">
                <label>Phone Number
                </label>
                <input type="text" class="input-field border" class="form-control" name="number" placeholder="Enter Your phone number" formControlName="phone" required autocomplete="off">
                <strong *ngIf="personalInfoForm.get('phone').invalid && personalInfoForm.get('phone').touched" style="color: 'red'; font-size: small">Enter valid alternate number</strong>
                 </div> -->
                            <div class="col-md-12">
                                <label>Address
              </label>
                                <input type="text" id="search-bar" (input)="updateSearchResults()" name="location" autocomplete="off" formControlName="address" placeholder="Enter your address" (mousedown)="onAddressMouseDown()">
                                <div class="user_loc" [hidden]="autocompleteItems.length == 0">
                                    <div class="add_new_location" *ngFor="let item of autocompleteItems" (click)="selectSearchResult(item)">
                                        <div class="cd_try">
                                            <p>{{ item.description }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="ad_mer">
                                    <strong *ngIf="isAddressMouseDownWarning" style="color: 'red'; font-size: small">Address field is mandatory. Select address from the list.</strong>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="def_grn">
                                    <label>Gender</label><br>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="lkj_gt">
                                            <input type="radio" id="radio03-01" formControlName="gender" value="male" /><label for="radio03-01">Male</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="lkj_gt">
                                            <input type="radio" id="radio03-02" formControlName="gender" value="female" /><label for="radio03-02">Female</label>
                                        </div>
                                    </div>
                                    <strong *ngIf="personalInfoForm.get('gender').invalid && personalInfoForm.get('gender').touched" style="color: 'red'; font-size: small">Field is manadatory</strong>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <label>Email
            </label>
                                <input type="email" id="email" class="form-control" formControlName="email" placeholder="Enter your email" autocomplete="off">
                                <strong *ngIf="personalInfoForm.get('email').invalid && personalInfoForm.get('email').touched" style="color: 'red'; font-size: small">Field is mandatory</strong>
                            </div>
                            <div class="col-md-12">
                                <label>Date of birth
               </label>
                                <input type="date" id="dob" class="form-control" formControlName="dob" autocomplete="off">
                                <strong *ngIf="personalInfoForm.get('dob').invalid && personalInfoForm.get('dob').touched" style="color: 'red'; font-size: small">Field is mandatory</strong>
                            </div>
                            <div class="form-group">
                                <label for="Description">Description*</label>
                                <textarea rows="4" cols="50" id="Description" autocomplete="off" formControlName="description" placeholder="Enter description"></textarea>
                            </div>
                            <strong *ngIf="personalInfoForm.get('description').invalid && personalInfoForm.get('description').touched" style="color: 'red'; font-size: small">Field is manadatory</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="add_footer">
            <div class="md-footer">
                <div class="row">
                    <div class="col-sm-6">
                    </div>
                    <div class="col-sm-3">
                        <button type="button" class="cenl_btn" (click)="closePopup()">Cancel</button>
                    </div>
                    <div class="col-sm-3">
                        <button type="submit" [disabled]="personalInfoForm.invalid">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
