import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, interval }   from 'rxjs';
import { ExceptionManagerService } from '../services/exception-manager.service';
import { trigger, transition, state, animate, style, AnimationEvent,group } from '@angular/animations';
import { DataEventService } from '../services/data-event.service';
import { UserConstants } from './userConstant';
import { AlertCode } from '../models/common.model';

@Component({
  selector: 'error-exception-comp',
  animations: [
    trigger('myanimation', [
      state('open', style({
        opacity: 1
      })),
      state('closed', style({
        opacity: 0,
      })),

      transition('void => *', [
        style({ height: 0, opacity: 0 }),
        group([
          animate('0.3s ease', style({
            height: '*'
          })),
          animate('1s ease-out', style({
            opacity: 1
          }))
        ])
      ]),
      transition('* => void', [
        group([
          animate('0.1s ease', style({
            height: 0
          })),
          animate('0.5s ease-out', style({
            opacity: 0
          }))
        ])
      ]),

    ])
 ],
  template: `
  <style>
        .alert{
          padding: 0.28rem 0.60rem;
        }
        .cursorstyle{
          cursor:pointer;
        }

        .alert-warning a i{
          color:#e7b63a;
        }

        .alert-warning a i:hover{
          color: #364054;
          opacity:1;
          text-decoration: none;
        }

        .alert-danger a i{
          color:#e63327;
        }

        .alert-danger a i:hover{
          color: #364054;
          opacity:1;
          text-decoration: none;
        }

        .alert-success a i{
          color:#508a95;
        }

        .alert-success a i:hover{
          color: #364054;
          opacity:1;
          text-decoration: none;
        }


  </style>
  <div role="alert" *ngIf="showError" [@myanimation] = "showError ? 'open' : 'closed'"
  [ngClass]="{
    'alert': true,
    'alert-warning': (colorobj.acode === 2 ? true : false ),
    'alert-danger': (colorobj.acode === 1 ? true : false ),
    'alert-success': (colorobj.acode === 0 ? true : false )}">
    {{errorMsg}}
    <a *ngIf="hideCloseBtn"
       class="float-right pl-2 cursorstyle"
       (click)="closeHandler()">
      <i class="fa fa-times-circle"></i>
    </a>
  </div>
  `
})
export class ErrorNExceptionComponent implements OnInit,OnDestroy {
  @Input() hideCloseBtn:boolean = true;
  @Input() level:number = 1;

  private _errorsubscription: Subscription;
  private _intervalSub:Subscription;
  private _showError:Boolean;
  private _errorMsg:string;
  private _colorobj:AlertCode = new AlertCode();
  private _alertCode:number = -1;


  constructor(private _errorManager:ExceptionManagerService,
      private _dEvent:DataEventService) {
    this._errorsubscription = this._errorManager.showError.subscribe(data=>{
      console.log("dataxxxx",data)
      if(data !== null){
        if((data.level && this.level === data.level) || (!data.level)){
          if(data.intervalMs && data.intervalMs > 0){
            let secondCounter:any = interval(data.intervalMs);
            this._intervalSub = secondCounter.subscribe(n =>{
              this.displayMsgWithCondition(data);
              this._intervalSub.unsubscribe();
            });
          }else{
            this.displayMsgWithCondition(data);
          }
        }
      }
    });




  }

  ngOnInit() {
    if(!this._colorobj)
      this._colorobj = new AlertCode();
    this.colorobj.acode = 1;
  }

  private displayMsgWithCondition(data:any):void{
    this._showError = data.show;
    this._errorMsg = data.msg;
    if(data.alertCode !== undefined){
      this._alertCode = data.alertCode;
    }else{
      this._alertCode = 1;
    }
    this.colorobj.acode = this._alertCode;
  }

  closeHandler(){
    this._errorMsg = "";
    this._showError = false;
  }


  get showError(){
    return this._showError;
  }

  get errorMsg(){
    return this._errorMsg;
  }

  get colorobj(){
    return this._colorobj;
  }
  set colorobj(value:AlertCode){
    this._colorobj = value;
  }

  ngOnDestroy(){
    this._errorsubscription.unsubscribe();

  }



}
