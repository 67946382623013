import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UserConstants } from 'src/app/utils/userConstant';

@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.scss']
})
export class TrackOrderComponent extends Basecomponent implements OnInit {

  constructor(baseService : BaseService) {
    super(baseService);
   }
   orderType:string;
   orderTrackStatus : any=[];
   orderHashId : string;
  ngOnInit(): void {
    super.ngOnInit();
    let data = this.getPassingData().stateData;
    console.log(data);
    this.orderHashId = data.orderHashId;
    this.orderType = data.orderType;
    if(this.orderType == 'laundry'){
      this.apiService.getCourierOrderTrackingOrder(this.orderHashId);
    }else if(this.orderType == 'ride'){
      this.apiService.getRideOrderDetails(this.orderHashId);
    }else{
      this.apiService.getOrderTrackHistory(this.orderHashId);

    }
  }

  apiResultSubscribe(data : any){
    console.log(data);
    if(data.resulttype == UserConstants.RESULT_GET_ORDER_TRACK_HISTORY){
      if(data.result.statusCode == 200){
        this.orderTrackStatus=[];
        data.result.data.forEach(element => {
          if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_ASSIGNED_TO_DELIVERY_BOY){
            element.value = "Delivery Assigned";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_BOY_REACHED_AT_DROP_LOCATION){
            element.value = "Reach at drop location";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_WILL_BE_DELAYED_BY_DELAYED_BY_DELIVERY_BOY){
            element.value = "Delivery may be delayed";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_DELIVERY_BOY){
            element.value = "Delivery Accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PICKED_UP_BY_DELIVERY_BOY){
            element.value = "Order Picked";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PLACED_BY_USER){
            element.value = "Order Place";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_READY_BY_MERCHANT){
            element.value = "Order ready";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_PAYMENT_DONE_FOR_THE_ORDER){
            element.value = "Payment done";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PLACED_BY_CALL_CENTER_OR_ADMIN){
            element.value = "Order place by call center/admin";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_USER){
            element.value = "Order cancel by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_MERCHANT_AFTER_ACCEPTING){
            element.value = "Order cancel by merchant after accepting";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_MERCHANT){
            element.value = "Order cancel by merchant";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_MERCHANT){
            element.value = "Order accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_COMPLETE){
            element.value = "Order Delivered";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_CANCEL_BY_USER){
            element.value = "Delivery cancel by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_CALL_CENTER_OR_ADMIN){
            element.value = "Order cancel by call center/admin";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_USER_RATING_DONE_FOR_THE_DELIVERY){
            element.value = "Rating done for delivery by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_USER_RATING_DONE_FOR_THE_ORDER){
            element.value = "Rating done for order by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_WILL_BE_DELAYED_BY_MERCHANT){
            element.value = "Order may be delayed from the merchant";
          }
          console.log(element);
          this.orderTrackStatus.push(element);
        });
        console.log("table",this.orderTrackStatus);

      }else{
        this.setError(true,data.result);
      }
    }else if(data.resulttype == UserConstants.RESULT_GET_COURIER_ORDER_TRACKING_HISTORY){
      if(data.result.statusCode == 200){
        this.orderTrackStatus=[];
        data.result.data.forEach(element => {
          if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_ASSIGNED_TO_DELIVERY_BOY){
            element.value = "Delivery Assigned";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_BOY_REACHED_AT_DROP_LOCATION){
            element.value = "Reach at drop location";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_WILL_BE_DELAYED_BY_DELAYED_BY_DELIVERY_BOY){
            element.value = "Delivery may be delayed";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_DELIVERY_BOY){
            element.value = "Delivery Accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PICKED_UP_BY_DELIVERY_BOY){
            element.value = "Order Picked";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PLACED_BY_USER){
            element.value = "Order Place";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_READY_BY_MERCHANT){
            element.value = "Order ready";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_PAYMENT_DONE_FOR_THE_ORDER){
            element.value = "Payment done";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PLACED_BY_CALL_CENTER_OR_ADMIN){
            element.value = "Order place by call center/admin";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_USER){
            element.value = "Order cancel by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_MERCHANT_AFTER_ACCEPTING){
            element.value = "Order cancel by merchant after accepting";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_MERCHANT){
            element.value = "Order cancel by merchant";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_MERCHANT){
            element.value = "Order accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_COMPLETE){
            element.value = "Order Delivered";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_CANCEL_BY_USER){
            element.value = "Delivery cancel by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_CALL_CENTER_OR_ADMIN){
            element.value = "Order cancel by call center/admin";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_USER_RATING_DONE_FOR_THE_DELIVERY){
            element.value = "Rating done for delivery by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_USER_RATING_DONE_FOR_THE_ORDER){
            element.value = "Rating done for order by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_WILL_BE_DELAYED_BY_MERCHANT){
            element.value = "Order may be delayed from the merchant";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_LAUNDRY){
            element.value = "Order accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_LAUNDRY){
            element.value = "Order cancel by laundry";
          }
          console.log(element);
          this.orderTrackStatus.push(element);
        });
      }else{
        this.setError(true,data.result);
      }
    }else if(data.resulttype == UserConstants.RESULT_GET_RIDE_ORDER_DETAILS){
      if(data.result.statusCode == 200){
        this.orderTrackStatus=[];
        data.result.data.forEach(element => {
          if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_PASSENGER_PICKED_UP_BY_DRIVER){
            element.value = "Passenger picked up by driver";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_DRIVER){
            element.value = "order accepted by driver";

          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_DRIVER){
            element.value = "order cancelled by driver";

          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_REACHED_DROP_LOCATION){
            element.value = " Order reached drop location ";

          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_PAYMENT_DONE){
            element.value = "Payment done";

          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PLACED_BY_USER){
            element.value = "Order Place";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_PASSENGER_LOCATED){
            element.value = "Passenger located";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_USER){
            element.value = "Order cancelled by user";
          }
          console.log(element);
          this.orderTrackStatus.push(element);
        });
      }else{
        this.setError(true,data.result);
      }
    }
  }

}

