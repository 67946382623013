import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ComponentInfo } from "../models/compinfo";
import { UserConstants } from "../utils/userConstant";

@Injectable({
  providedIn: "root",
})
export class CompLoadManagerService {
  private dObs = new Subject<any>();
  private _compList: Array<ComponentInfo>;
  private cOType: string;

  private _observer: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {}

  private loadComponent(
    cinfo: ComponentInfo,
    supportingParams?: any,
    complevel: number = 1
  ): void {
    this.cOType = cinfo.compOpenType;
    if (!this.cOType) {
      this.cOType = UserConstants.CONTAINER;
    }
    this.publishEvt({
      type: this.cOType,
      value: cinfo,
      supportingParams: supportingParams,
      level: complevel,
    });
  }

  public closePopup(complevel: number = 1, inventoryIndex:any=null, action:any=null): void {
    this.publishEvt({ type: UserConstants.CLOSE_POPUP, level: complevel,inventoryIndex:inventoryIndex,action:action});
  }

  public publishEvt(data: any): void {
    this.dObs.next(data);
  }

  public setHeaderTitle(titleStr: string): void {
    this._observer.next(titleStr);
  }

  public notifyPopupClosed(params?: any): void {
    this.publishEvt({ type: UserConstants.POPUP_CLOSED, sParams: params });
  }

  public redirect(
    routestring: string,
    supportingParams?: any,
    complevel: number = 1
  ): void {
    let { isExists, menuitem } = this.isItemExists(routestring);
    console.log("isExist :",isExists);
    if (isExists) {
      this.loadComponent(menuitem, supportingParams, complevel);
    }
  }

  private isItemExists(routerlink: string): any {
    // console.log("Component list", this._compList);
    for (const v of this._compList) {
      if (v.routeLink === routerlink) {
        return { isExists: true, menuitem: v };
      }
    }
    return { isExists: false, menuitem: undefined };
  }

  get dataObserver(): Observable<any> {
    return this.dObs.asObservable();
  }

  get managerObs(): Observable<any> {
    return this._observer.asObservable();
  }

  set compList(value: Array<ComponentInfo>) {
    this._compList = value;
  }
}
