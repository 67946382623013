import { Component, OnInit, NgZone } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { MouseEvent } from '@agm/core';
import { UserConstants } from 'src/app/utils/userConstant';
import { AlertModalService } from 'src/app/pages/modal/alert-modal/alert-modal.service';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { Router } from '@angular/router';
declare var google;
@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss']
})
export class SelectLocationComponent extends Basecomponent implements OnInit {
  zoom: number = 4;
  geocoder = new google.maps.Geocoder();
  // initial center position for the map
  lat: any = 37.090240;
  lng: any = -95.712891;
  GoogleAutocomplete = new google.maps.places.AutocompleteService();;
  autocompleteItems:any=[];
  autocomplete: any = {};
  isLocationError:any;
  locationError:any;
  selectedLocation : any = [];
  formattedAddress : any;
  constructor(baseService : BaseService,
    private ngZone : NgZone,
    private alertModal : AlertModalService,
    private userStorage : UserStorageService,
    private router : Router) {
    super(baseService);
   }
   isUserLoggenIn:Boolean = false;
  ngOnInit(): void {
    super.ngOnInit();
    let accountHashId = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID) : localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID);
    let awsHashId = (this.userStorage.getValueFromStorage(UserConstants.KEY_AUTH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_AUTH_ID) : localStorage.getItem(UserConstants.KEY_AUTH_ID);
    if(accountHashId && awsHashId && accountHashId != 'null' && awsHashId != 'null'){
      this.isUserLoggenIn = true;
    }
    let lat = (this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_LATITUDE)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_LATITUDE) : localStorage.getItem(UserConstants.KEY_SELECTED_LATITUDE);
    let lng = (this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_LONGITUDE)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_LONGITUDE) : localStorage.getItem(UserConstants.KEY_SELECTED_LONGITUDE);
    console.log(lat,lng);
    if(lat && lng && lat != 'null' && lng != 'null') {
      this.lat = parseFloat(lat);
      this.lng = parseFloat(lng);
      this.zoom = 15;
    }
    this.autocomplete = {
      input: ''
  };
  }

apiResultSubscribe(data:any){
  console.log(data);
  if(data.resulttype == UserConstants.RESULT_GET_ZONE_INFO_BY_LOCATION){
    if(data.result.statusCode == 200){
      if(data.result.data.length != 0){
        console.log(data.result.data[0]);
       
        this.userStorage.setValueInStorage(UserConstants.KEY_SELECTED_ZONE_HASH_ID,data.result.data[0].hash_id);
        localStorage.setItem(UserConstants.KEY_SELECTED_ZONE_HASH_ID,data.result.data[0].hash_id);

        if(this.isUserLoggenIn){
          this.apiService.setNormalUserZoneMap(data.result.data[0].hash_id);
        }else{
          this.apiService.getSubServiceByZoneId(data.result.data[0].hash_id);
          this.closePopupWithData(true);
          this.router.navigate([`${UserConstants.RL_HOME}`]);
        }
      }else{
        localStorage.removeItem(UserConstants.KEY_SELECTED_FORMATTED_ADDRESS);
        localStorage.removeItem(UserConstants.KEY_SELECTED_LOCATION);
        localStorage.removeItem(UserConstants.KEY_SELECTED_LATITUDE);
        localStorage.removeItem(UserConstants.KEY_SELECTED_LONGITUDE);
        localStorage.removeItem(UserConstants.KEY_SELECTED_ZONE_HASH_ID);
        this.userStorage.removeKey(UserConstants.KEY_SELECTED_FORMATTED_ADDRESS);
        this.userStorage.removeKey(UserConstants.KEY_SELECTED_LATITUDE);
        this.userStorage.removeKey(UserConstants.KEY_SELECTED_LOCATION);
        this.userStorage.removeKey(UserConstants.KEY_SELECTED_LONGITUDE);
        this.userStorage.removeKey(UserConstants.KEY_SELECTED_ZONE_HASH_ID);
        this.closePopupWithData(true);
        this.router.navigate([`${UserConstants.RL_HOME}`]);
        this.alertModal.confirm("Service unavailable","dripple yet not started here. Select a different location and get our services their.");
      }
    }else{
      this.setError(true,data.result);
    }
  }else if(data.resulttype == UserConstants.RESULT_SET_NORMAL_USER_ZONE_MAP){
    if(data.result.statusCode == 200){
      // this.alertModal.confirm("Alert!","Location updated Successfully.");
      this.router.navigate([`${UserConstants.RL_HOME}`]);
      let zoneHashId = (this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_ZONE_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_ZONE_HASH_ID) : localStorage.getItem(UserConstants.KEY_SELECTED_ZONE_HASH_ID);
      this.apiService.getSubServiceByZoneId(zoneHashId);
      this.closePopupWithData(true);
    }else{
      this.setError(true,data.result);
    }
  }else if(data.resulttype == UserConstants.RESULT_GET_ZONE_SPECFIC_SUB_SERVICE_LIST){

  }
}

  getCurrentLocation(){
    console.log("current Location");
    this.isLocationError=false;
  if(window.navigator && window.navigator.geolocation){
    window.navigator.geolocation.getCurrentPosition((position)=>{
      console.log(position)
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
      this.zoom=15;
      this.getAddress(this.lat,this.lng);
    },error => {
      this.isLocationError=true;
      switch (error.code) {
          case 1:
              console.log('Permission Denied');
              this.locationError = 'Permission Denied';
              break;
          case 2:
              console.log('Position Unavailable');
              this.locationError = 'Position Unavailable';
              break;
          case 3:
              console.log('Timeout');
              this.locationError = 'Timeout. Please try again.';
              break;
      }
  });
  }
  }
  updateSearchResults() {
    console.log("Text enter")
    if ( this.autocomplete.input == '' )
    {
        this.autocompleteItems = [];
        return;
    }
    this.GoogleAutocomplete.getPlacePredictions( {
        input: this.autocomplete.input
    },
        ( predictions, status ) => {

          console.log(predictions,status);
            this.autocompleteItems = [];
            predictions.forEach( ( prediction ) => {
                this.autocompleteItems.push( prediction );
            } );
        } );
  }
  selectSearchResult( item ) {
    console.log(item);
    this.autocompleteItems = [];
    this.geocoder.geocode( {
        'placeId': item.place_id
    }, ( results, status ) => {
        if ( status === 'OK' && results[ 0 ] )
        {
            this.ngZone.run( () => {
                this.autocomplete.input = results[ 0 ].formatted_address;
                // this.locationSelected = true;
                let location_set:any = JSON.stringify(results[0].geometry);
                location_set=JSON.parse(location_set)
                let position = {
                    lat: results[ 0 ].geometry.location.lat,
                    lng: results[ 0 ].geometry.location.lng
                };
                this.lat=location_set.location.lat;
                this.lng=location_set.location.lng;
                this.zoom=15;
                console.log(this.lat,this.lng);
                console.log('Latitude & Langitude' , position)
                this.storeLocation( results[ 0 ].address_components, results[ 0 ].formatted_address );
            } );


        }
    } )
  }
  storeLocation( location, formatted_address ) {
    const selectedLocation: any = {};
    location.forEach( element => {
        console.log( element, element.types.includes( "postal_code" ) )
        if ( element.types.includes( "postal_code" ) )
        {
            selectedLocation.zip = element.long_name
        } else if ( element.types.includes( "country" ) )
        {
            selectedLocation.country = element.long_name
        } else if ( element.types.includes( "administrative_area_level_1" ) )
        {
            selectedLocation.state = element.long_name
        } else if ( element.types.includes( "locality" ) )
        {
            selectedLocation.city = element.long_name
        } else if ( element.types.includes( "route" ) )
        {
            selectedLocation.address = element.long_name
        }
    } );
      this.formattedAddress = formatted_address;

      this.selectedLocation = selectedLocation;

  }
  getAddress(latitude, longitude) {
    this.geocoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if ( status === 'OK' && results[ 0 ] )
      {
          this.ngZone.run( () => {
              this.autocomplete.input = results[ 0 ].formatted_address;
              // this.locationSelected = true;
              let location_set:any = JSON.stringify(results[0].geometry);
              location_set=JSON.parse(location_set)
              let position = {
                  lat: results[ 0 ].geometry.location.lat,
                  lng: results[ 0 ].geometry.location.lng
              };
              this.lat=location_set.location.lat;
              this.lng=location_set.location.lng;
              this.zoom=15;
              console.log(this.lat,this.lng);
              console.log('Latitude & Langitude' , position)
              this.storeLocation( results[ 0 ].address_components, results[ 0 ].formatted_address );
          } );


      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.lat, this.lng);
      });
    }
  }
  saveCurrentAddress(){
    console.log(this.formattedAddress,this.selectedLocation)
    if(this.autocomplete.input && this.formattedAddress){
      this.userStorage.setValueInStorage(UserConstants.KEY_SELECTED_FORMATTED_ADDRESS,this.formattedAddress);
      this.userStorage.setValueInStorage(UserConstants.KEY_SELECTED_LOCATION,JSON.stringify(this.selectedLocation));
      this.userStorage.setValueInStorage(UserConstants.KEY_SELECTED_LATITUDE,this.lat);
      this.userStorage.setValueInStorage(UserConstants.KEY_SELECTED_LONGITUDE,this.lng);
      localStorage.setItem( UserConstants.KEY_SELECTED_FORMATTED_ADDRESS, this.formattedAddress );
      localStorage.setItem( UserConstants.KEY_SELECTED_LOCATION, JSON.stringify( this.selectedLocation ) );
      localStorage.setItem( UserConstants.KEY_SELECTED_LATITUDE,this.lat);
      localStorage.setItem( UserConstants.KEY_SELECTED_LONGITUDE,this.lng);
      let zip = (this.selectedLocation.zip && this.selectedLocation.zip != 'null') ? this.selectedLocation.zip : "null";
      // this.apiService.getZoneByLocationInfo(zip,this.formattedAddress);

      /**----------------- */
      this.closePopupWithData(true);
      this.router.navigate([`${UserConstants.RL_HOME}`]);
      this.apiService.getZoneByLocation();
      /**----------------- */
    }
  }
  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  mapClicked($event: MouseEvent) {
      this.lat =  $event.coords.lat;
      this.lng = $event.coords.lng;
      this.zoom=15;
    this.getAddress(this.lat,this.lng);
  }
}
