<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
</div>
<div class="modal-body" style="padding: 15px !important;">
    {{ message }}
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()" style="    background: #fff;
    padding: 8px 18px 8px 18px;
    border: 1px solid #bebebe;
    color: #515151;
    width: 48.60%;">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-primary" (click)="accept()" style="background: #1abd03;
    padding: 8px 18px 8px 18px;
    border: 1px solid #1abd03;
    color: #ffffff; width: 47.19%;">{{ btnOkText }}</button>
</div>
