import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  private _loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  public setLogout(value:boolean = false){
    this._loggedIn.next(value);
  }
  get isLoggedIn() {
    return this._loggedIn.asObservable();
  }
}
