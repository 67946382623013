import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GenericModalContent, GenericPopup } from './utils/generic-popup';
import { LoaderComponent } from './component/loader/loader.component';
import { CompMapDirective } from './directives/comp-map.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { GoogleMapsModule } from "@angular/google-maps";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from  'ng-otp-input';
import { AgmCoreModule } from '@agm/core';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AgmDirectionModule } from 'agm-direction';
import { environment } from 'src/environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    GenericModalContent,
    GenericPopup,
    LoaderComponent,
    CompMapDirective,
  ],
  imports: [
    // RouterModule,
    BrowserModule,
    AppRoutingModule,
    // GoogleMapsModule,
    NgxSkeletonLoaderModule,
    NgbModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    AgmCoreModule.forRoot({
      apiKey : 'AIzaSyD8h8T7x18lXx1Hyqi9anIcnX-8J0vNM9k',
      libraries: ['geometry']
    }),
    NgImageFullscreenViewModule,
    ImageCropperModule,
    AgmDirectionModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
