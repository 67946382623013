<div class="add_new_md">
    <div class="add_scroll">
        <div class="add_deliver_head">
            <div class="input_filed_pla">
                <label for="name">Document name</label>
                <input type="text" name="name" placeholder="Enter document name" autocomplete="off" [(ngModel)]="documentNamess">
            </div>
            <div class="form-group">
                <label for="Description">Document Description*</label>
                <textarea rows="4" cols="50" maxlength="200" id="Description" autocomplete="off" placeholder="Enter description" [(ngModel)]="documentDescription"></textarea>
            </div>
        </div>
        <div class="upl_body">
            <div class="row">
                <div class="image-upload">
                    <input type="file" multiple="false" id="merchantExcel" (change)="detectDocument($event)">
                    <label for="logo" class="upload-field" id="file-label">
                  <div class="file-thumbnail">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="377.5" y2="45.5"><stop offset="0" stop-color="#fd5900"/><stop offset="1" stop-color="#ffde00"/></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="241" x2="241" y1="466.5" y2="196.5"><stop offset="0" stop-color="#ffe59a"/><stop offset="1" stop-color="#ffffd5"/></linearGradient><g><g><path d="m406 377.5h-120c-8.284 0-15-6.716-15-15v-61c0-7.633 5.7-13.934 13.076-14.878l-43.076-51.691-43.076 51.692c7.376.943 13.076 7.244 13.076 14.877v61c0 8.284-6.716 15-15 15h-90c-58.449 0-106-47.552-106-106 0-40.678 23.742-77.63 60.096-95.146 2.712-72.619 62.634-130.854 135.904-130.854 44.991 0 87.168 22.882 112.37 60.339 2.63-.23 5.131-.339 7.63-.339 40.696 0 77.659 23.75 95.165 60.124 56.061 2.701 100.835 49.157 100.835 105.876 0 58.448-47.551 106-106 106z" fill="url(#SVGID_1_)"/></g><path d="m286 466.5h-90c-8.284 0-15-6.716-15-15v-135h-15c-5.82 0-11.115-3.367-13.584-8.638s-1.666-11.494 2.061-15.965l75-90c2.85-3.42 7.072-5.397 11.523-5.397s8.673 1.977 11.523 5.397l75 90c3.726 4.471 4.529 10.694 2.061 15.965s-7.764 8.638-13.584 8.638h-15v135c0 8.284-6.716 15-15 15z" fill="url(#SVGID_2_)"/></g></svg>
                      <h3 id="filename">
                        <label for="merchantExcel">{{documentName}}</label>
                    </h3>
                </div>
                </label>
            </div>

        </div>
    </div>
</div>
<div class="add_footer">
    <div class="md-footer">
        <div class="row">
            <div class="col-sm-6">
            </div>
            <div class="col-sm-3">
                <button type="button" class="cenl_btn" (click)="closePopup()">Cancel</button>
            </div>
            <div class="col-sm-3">
                <button class="btn btn-default btn-block" id="upload-save" (click)="saveDocument()">
                    Save
                  </button>
            </div>
        </div>
    </div>
</div>
</div>
