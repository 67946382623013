<div class="dratoo_account">
    <div class="container">
                <div class="profile_added">
                    <div class="acc_edit_btn">
                        <i class='fa fa-trash' style="color: red;" (click)="deletUserBankDetail(bankDetail)"></i>
                        <button  (click)="openBankAccount()"><i class="fa fa-pencil" aria-hidden="true"></i> Edit</button>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="prof_gtt">
                                <h5>Bank Name</h5>
                                <p>{{ bankDetail?.bank_name  }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="prof_gtt">
                                <h5>Account Holder Name</h5>
                                <p>{{ bankDetail?.holder_name }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="prof_gtt">
                                <h5>Branch Name</h5>
                                <p>{{ bankDetail?.branch_name }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="prof_gtt">
                                <h5>Account Type</h5>
                                <p *ngIf="bankDetail!=undefined">{{ bankDetail.acc_type==0 ? "Saving" : "Current" }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="prof_gtt">
                                <h5>Account Number</h5>
                                <p>{{ bankDetail?.acc_number}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="prof_gtt">
                                <h5>IFSC Code</h5>
                                <p>{{ bankDetail?.ifsc_code}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="prof_gtt">
                                <h5>Pan Card Number</h5>
                                <p>{{ bankDetail?.pan_number}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="order_added" *ngIf="selectedvalue == 2"> -->
                    <!-- <app-order-history></app-order-history> -->
                <!-- </div> -->
                <!-- <div class="adr_added" *ngIf="selectedvalue == 3"> -->
                    <!-- <app-delivery-address></app-delivery-address> -->
                <!-- </div> -->
                <!-- <div class="paym_added" *ngIf="selectedvalue == 4"> -->
                    <!-- <app-manage-payment></app-manage-payment> -->
                <!-- </div> -->
                <!-- <div class="paym_added" *ngIf="selectedvalue == 5"> -->
                    <!-- <app-book-table-history></app-book-table-history> -->
                <!-- </div> -->
    </div>
</div>
