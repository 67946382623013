import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UploadService } from 'src/app/services/uploadService/upload.service';
import { UserConstants } from 'src/app/utils/userConstant';

@Component({
  selector: 'app-add-pharmacy-document',
  templateUrl: './add-pharmacy-document.component.html',
  styleUrls: ['./add-pharmacy-document.component.scss']
})
export class AddPharmacyDocumentComponent extends Basecomponent implements OnInit {

  constructor(baseService : BaseService,
    private uploadService : UploadService) {
    super(baseService);
   }

  ngOnInit(): void {
    super.ngOnInit();
    this.imageType = this.getPassingData().stateData.imageType;
    console.log(this.imageType);
  }

  apiResultSubscribe(data:any){
    console.log(data);
  }
  urls:any=[];
  imageType:string;
  documentName:string='Upload document';
detectDocument(event){
  console.log(event);
  this.urls = [];
  let file = event.target.files;
  console.log(file)
  if(file[0]){
      this.convertArrayBuffer(file[0]).then(res=>{
        this.documentName = file[0].name;
        // this.isFile=true;
        // this.isSelectImage=true;
        console.log(res);
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push({
            data : e.target.result,
            name : file[0].name,
            fileType : file[0].type,
            byteArray : res
          });
        }
        reader.readAsDataURL(file[0]);
      })  
  }
}
convertArrayBuffer(data):Promise<any>{
  return new Promise((resolve,reject)=>{
    let varImage;
    let reader = new FileReader();
    reader.readAsArrayBuffer(data);
    reader.onload=(e:any)=>{
       varImage = e.target.result;
      resolve(varImage);
    }
  })
}
private storageKey:any;
documentNamess:string;
documentDescription:string;
saveDocument(){
  try {
    if(this.urls.length != 0){
      if(this.documentNamess && this.documentNamess != 'null' && this.documentDescription && this.documentDescription != 'null'){
        document.getElementById( 'upload-save' ).innerHTML = 'Uploading <i class="fa fa-spinner fa-spin"></i>';
        document.getElementById( 'upload-save' ).setAttribute( "disabled", "diasbled" );
        this.uploadService.uploadToS3BucketTypeWise(UserConstants.BUCKET_TYPE_PROFILE_DOC,'document',this.imageType,this.urls[0].byteArray,this.urls[0].name,this.urls[0].fileType).then(res=>{
          console.log(res);
          document.getElementById( 'upload-save' ).innerHTML = 'Send';
          document.getElementById( 'upload-save' ).removeAttribute( "disabled" );
          this.storageKey=res['key'];
            let imageData = {
              type : this.imageType,
              key : this.storageKey,
              name : this.documentNamess,
              description : this.documentDescription
            }
            this.closePopupWithData(imageData);
        }).catch(err=>{
          document.getElementById( 'upload-save' ).innerHTML = 'Send';
          document.getElementById( 'upload-save' ).removeAttribute( "disabled" );
          console.log(err);
        })
      }else{
        alert("Document name and description is manadatory.");
      }
    }else{
      alert("Please select any document.");
    }
    
    
  } catch (error) {
    console.log(error);
  }
}

}
