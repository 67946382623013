<div class="add_profile_drat">
    <div class="frm_fl">
        <div class="add_scroll">
            <div class="ad_ox">
                <error-exception-comp></error-exception-comp>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="book_sec">
                            <h3>Let us serve you better</h3>
                            <p>Don’t wait in a line to enjoy your meal. Reserve a table in advance with us.</p>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Pick Date</label>
                            <div class="input-group">
                                <div class="input-group-append">
                                    <div class="book_bnm">
                                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i class="fa fa-calendar-o" aria-hidden="true"></i>
                                    </button>
                                    </div>
                                </div>
                                <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker">

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="time_trc">
                            <label>Time</label>
                            <!-- <input type="time" class="input-field border" class="form-control" name="name" required autocomplete="no"> -->
                            <div class="time_pic_org">
                                <ngb-timepicker class="changeSize" [(ngModel)]="time" [meridian]="meridian"></ngb-timepicker>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="brt_olp">
                            <label>Guest</label>
                            <div class="stper">
                                <div class="ser_inpt">
                                    <div class="form-check-inline rgmk">
                                        <button class="btn" (click)="minus()"><i class="fa fa-minus"></i></button>
                                    </div>
                                    <div class="form-check-inline rgmk">
                                        <input type="text" class="form-control" min="0" max="25" (keypress)="onKeyPress($event)" [(ngModel)]="noOfPerson">
                                    </div>
                                    <div class="form-check-inline rgmk">
                                        <button class="btn" (click)="plus()"><i class="fa fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="nts_ord">
                                <label>Note</label>
                                <textarea name="note" [(ngModel)]="note"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="add_footer">
        <div class="md-footer">
            <div class="row">
                <div class="col-sm-12">
                    <button type="button" (click)="saveData()">Book now</button>
                </div>
            </div>
        </div>
    </div>
</div>
