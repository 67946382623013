import { Component, OnInit, NgZone } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UserConstants } from 'src/app/utils/userConstant';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { AlertModalService } from 'src/app/pages/modal/alert-modal/alert-modal.service';
import { Router } from '@angular/router';
import { MouseEvent } from '@agm/core';
declare var google;
@Component({
  selector: 'app-add-delivery-address',
  templateUrl: './add-delivery-address.component.html',
  styleUrls: ['./add-delivery-address.component.scss']
})
export class AddDeliveryAddressComponent extends Basecomponent implements OnInit {
  zoom: number = 4;
  geocoder = new google.maps.Geocoder();
  // initial center position for the map
  lat: any = 37.090240;
  lng: any = -95.712891;
  GoogleAutocomplete = new google.maps.places.AutocompleteService();;
  autocompleteItems:any=[];
  autocomplete: any = {};
  isLocationError:any;
  locationError:any;
  selectedLocation : any = [];
  formattedAddress : any;
  constructor(baseService : BaseService,
    private ngZone : NgZone,
    private alertModal : AlertModalService,
    private userStorage : UserStorageService,
    private router : Router) {
    super(baseService);
   }
   isUserLoggenIn:Boolean = false;

  private _isLandMarkMouseDownWarning: boolean = false;
  private _isLandMarkMouseChangeWarning: boolean = false;

  get isLandMarkMouseChangeWarning() {
    return this._isLandMarkMouseChangeWarning;
  }

  get isLandMarkMouseDownWarning() {
    return this._isLandMarkMouseDownWarning;
  }

  onLandMarkMouseDown(){
    if (!this.landmark) {
      this._isLandMarkMouseDownWarning = true;
    }
  }

  onLandMarkInput(event){
    if (!event.target.value) {
      this._isLandMarkMouseChangeWarning = true;
      this._isLandMarkMouseDownWarning = true;
    } else {
      this._isLandMarkMouseChangeWarning = false;
      this._isLandMarkMouseDownWarning = false;
    }
  }
  phoneNumber:string='';
  private _isPhoneMouseDownWarning: boolean = false;
  private _isPhoneMouseChangeWarning: boolean = false;

  get isPhoneMouseChangeWarning() {
    return this._isPhoneMouseChangeWarning;
  }

  get isPhoneMouseDownWarning() {
    return this._isPhoneMouseDownWarning;
  }

  onPhoneMouseDown(){
    if (!this.phoneNumber) {
      this._isPhoneMouseDownWarning = true;
    }
  }

  onPhoneInput(event){
    if (!event.target.value) {
      this._isPhoneMouseChangeWarning = true;
      this._isPhoneMouseDownWarning = true;
    } else {
      this._isPhoneMouseChangeWarning = false;
      this._isPhoneMouseDownWarning = false;
    }
  }




  ngOnInit(): void {
    super.ngOnInit();
    let accountHashId = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID) : localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID);
    let awsHashId = (this.userStorage.getValueFromStorage(UserConstants.KEY_AUTH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_AUTH_ID) : localStorage.getItem(UserConstants.KEY_AUTH_ID);
    if(accountHashId && awsHashId && accountHashId != 'null' && awsHashId != 'null'){
      this.isUserLoggenIn = true;
    }
    let lat = (this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_LATITUDE)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_LATITUDE) : localStorage.getItem(UserConstants.KEY_SELECTED_LATITUDE);
    let lng = (this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_LONGITUDE)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_LONGITUDE) : localStorage.getItem(UserConstants.KEY_SELECTED_LONGITUDE);
    console.log(lat,lng);
    if(lat && lng && lat != 'null' && lng != 'null') {
      this.lat = parseFloat(lat);
      this.lng = parseFloat(lng);
      this.zoom = 15;
    }
    this.autocomplete = {
      input: ''
  };
  }
  userHashId : string;
updateDataInAddMode(){
let data = this.getPassingData().stateData;
this.userHashId = data.userHashId;
}
apiResultSubscribe(data:any){
  console.log(data);
  if (data.resulttype == UserConstants.RESULT_ADD_EDIT_DELIVERY_ADDRESS){
    if(data.result.statusCode == 200){
      this.closePopupWithData(true);
    }else{
      this.setError(true,data.result);
    }
  }
}

  getCurrentLocation(){
    console.log("current Location");
    this.isLocationError=false;
  if(window.navigator && window.navigator.geolocation){
    window.navigator.geolocation.getCurrentPosition((position)=>{
      console.log(position)
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
      this.zoom=15;
      this.getAddress(this.lat,this.lng);
    },error => {
      this.isLocationError=true;
      switch (error.code) {
          case 1:
              console.log('Permission Denied');
              this.locationError = 'Permission Denied';
              break;
          case 2:
              console.log('Position Unavailable');
              this.locationError = 'Position Unavailable';
              break;
          case 3:
              console.log('Timeout');
              this.locationError = 'Timeout. Please try again.';
              break;
      }
  });
  }
  }
  updateSearchResults() {
    console.log("Text enter")
    if ( this.autocomplete.input == '' )
    {
        this.autocompleteItems = [];
        return;
    }
    this.GoogleAutocomplete.getPlacePredictions( {
        input: this.autocomplete.input
    },
        ( predictions, status ) => {

          console.log(predictions,status);
            this.autocompleteItems = [];
            predictions.forEach( ( prediction ) => {
                this.autocompleteItems.push( prediction );
            } );
        } );
  }
  selectSearchResult( item ) {
    console.log(item);
    this.autocompleteItems = [];
    this.geocoder.geocode( {
        'placeId': item.place_id
    }, ( results, status ) => {
        if ( status === 'OK' && results[ 0 ] )
        {
            this.ngZone.run( () => {
                this.autocomplete.input = results[ 0 ].formatted_address;
                // this.locationSelected = true;
                let location_set:any = JSON.stringify(results[0].geometry);
                location_set=JSON.parse(location_set)
                let position = {
                    lat: results[ 0 ].geometry.location.lat,
                    lng: results[ 0 ].geometry.location.lng
                };
                this.lat=location_set.location.lat;
                this.lng=location_set.location.lng;
                this.zoom=15;
                console.log(this.lat,this.lng);
                console.log('Latitude & Langitude' , position)
                this.storeLocation( results[ 0 ].address_components, results[ 0 ].formatted_address );
            } );


        }
    } )
  }
  storeLocation( location, formatted_address ) {
    const selectedLocation: any = {};
    location.forEach( element => {
        console.log( element, element.types.includes( "postal_code" ) )
        if ( element.types.includes( "postal_code" ) )
        {
            selectedLocation.zip = element.long_name
        } else if ( element.types.includes( "country" ) )
        {
            selectedLocation.country = element.long_name
        } else if ( element.types.includes( "administrative_area_level_1" ) )
        {
            selectedLocation.state = element.long_name
        } else if ( element.types.includes( "locality" ) )
        {
            selectedLocation.city = element.long_name
        } else if ( element.types.includes( "route" ) )
        {
            selectedLocation.address = element.long_name
        }
    } );
      this.formattedAddress = formatted_address;

      this.selectedLocation = selectedLocation;

  }
  getAddress(latitude, longitude) {
    this.geocoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if ( status === 'OK' && results[ 0 ] )
      {
          this.ngZone.run( () => {
              this.autocomplete.input = results[ 0 ].formatted_address;
              // this.locationSelected = true;
              let location_set:any = JSON.stringify(results[0].geometry);
              location_set=JSON.parse(location_set)
              let position = {
                  lat: results[ 0 ].geometry.location.lat,
                  lng: results[ 0 ].geometry.location.lng
              };
              this.lat=location_set.location.lat;
              this.lng=location_set.location.lng;
              this.zoom=15;
              console.log(this.lat,this.lng);
              console.log('Latitude & Langitude' , position)
              this.storeLocation( results[ 0 ].address_components, results[ 0 ].formatted_address );
          } );


      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.lat, this.lng);
      });
    }
  }
  addressType:string;
  landmark:string;
  invokeApiHandler(){
    let data = {
      userHashId : this.userHashId,
      city : this.selectedLocation.city,
      state : this.selectedLocation.state,
      country : this.selectedLocation.country,
      zip : this.selectedLocation.zip,
      lat : this.lat,
      lng : this.lng,
      address_type : this.addressType,
      is_default : 0,
      address_line : this.formattedAddress,
      landmark : this.landmark,
      phone_1 : this.phoneNumber
    }
    console.log(data);
    this.apiService.addEditDeliveryAddress(data);
  }
  validateAllFields(): boolean {
    if (
      this.landmark && this.autocomplete.input && this.formattedAddress && this.phoneNumber
    ) {
      return true;
    }
    return false;
  }
  
  validationErrorHandler(): void {
    this.setError(true, UserConstants.DEFAULT_INPUT_VALIDATION_MESSAGE);
  }
  saveCurrentAddress(){
    console.log(this.formattedAddress,this.selectedLocation,this.addressType,this.landmark);
    if(!this.formattedAddress){
      alert("Please enter valid address.");
      return;
    }
    if(!this.landmark){
      alert("Please enter landmark.");
      return;
    }
    if (this.phoneNumber.toString().length !== 10) {
      alert("Phone number not in correct format");
      return;
    }
    if (!this.phoneNumber.toString().match(/^[0-9]+$/)) {
      alert("Phone number only contain numbers");
      return;
    }
    if(!this.addressType){
      alert("Please choose address type");
      return;
    }
    this.saveHandler(true);
  }
  
  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  mapClicked($event: MouseEvent) {
      this.lat =  $event.coords.lat;
      this.lng = $event.coords.lng;
      this.zoom=15;
    this.getAddress(this.lat,this.lng);
  }

}
