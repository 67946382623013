import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UserConstants } from 'src/app/utils/userConstant';
import { UserStorageService } from 'src/app/services/user-storage.service';

@Component({
  selector: 'app-select-delivery-location',
  templateUrl: './select-delivery-location.component.html',
  styleUrls: ['./select-delivery-location.component.scss']
})
export class SelectDeliveryLocationComponent extends Basecomponent implements OnInit {

  constructor(baseService : BaseService,
    private userStorage : UserStorageService) {
    super(baseService);
   }

  userHashId : any = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID) : localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID);

  ngOnInit(): void {
    super.ngOnInit();
    this.apiService.getDeliveryAddress(this.userHashId);
  }
  addressData:any=[];
  apiResultSubscribe(data :any){
    console.log(data);
    if(data.resulttype == UserConstants.RESULT_GET_DELIVERY_ADDRESS){
      if(data.result.statusCode == 200){
        data.result.data.forEach(element => {
          this.addressData.push(element);
        });
      }else{
        this.setError(true,data.result);
      }
    }
  }

}
