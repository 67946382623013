export class HelperFunc {
  /**
   * static isEmpty
obj:any   */
  public static isEmpty(obj: any) {
    if (obj === "") {
      return true;
    }

    return false;
  }

  public static isExitsInArray(tarr: Array<any>, checkobj: any): boolean {
    for (let iterator of tarr) {
      if (checkobj === iterator) {
        return true;
      }
    }
    return false;
  }

  public static getFormattedWeeks(weekNo: number) {
    let year = new Date().getFullYear();
    //define a date object variable that will take the current system date
    let todaydate: any = new Date();

    //find the year of the current date
    let oneJan: any = new Date(todaydate.getFullYear(), 0, 1);

    // calculating number of days in given year before a given date
    let numberOfDays = Math.floor((todaydate - oneJan) / (24 * 60 * 60 * 1000));

    //  adding 1 since to current date and returns value starting from 0
    let resultWeek = Math.ceil((todaydate.getDay() + numberOfDays) / 7);
    // console.log(resultWeek);
    if (resultWeek < weekNo) {
      year--;
    }
    let simpleDate: Date = HelperFunc.getDateOfISOWeek(weekNo, year);
    let lastDate: Date = new Date(
      HelperFunc.getFormattedForwardDate(
        HelperFunc.convertTimestampToDate(simpleDate.getTime()),
        6
      )
    );

    // console.log(simpleDate, lastDate);
    let months: string[] = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // console.log("Month", simpleDate.getMonth(), lastDate.getMonth());
    // console.log("Day", simpleDate.getDate(), lastDate.getDate());
    const result: string = `${
      months[simpleDate.getMonth()]
    } ${simpleDate.getDate()}-${months[lastDate.getMonth()]} ${
      lastDate.getDate() + 1
    },  ${year}`;
    return result;
  }

  public static isExitsInObject(
    tarr: any,
    checkobj: any,
    propname: string,
    isParseInt: boolean = false
  ): any {
    let count: number = 0;
    let propvalue: any;
    for (let iterator of tarr) {
      if (isParseInt) {
        propvalue = parseInt(iterator[propname]);
      } else {
        propvalue = iterator[propname];
      }

      if (checkobj === propvalue) {
        return { isExists: true, element: iterator, indexno: count };
      }
      count++;
    }
    return { isExists: false, element: undefined, indexno: undefined };
  }

  public static getLocalDate(): string {
    return `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 <= 9 ? "0" : ""
    }${new Date().getMonth() + 1}-${
      new Date().getDate() <= 9 ? "0" : ""
    }${new Date().getDate()}`;
  }

  public static isExitsInObjectI2(
    tarr: any,
    checkobj: any,
    propname: string,
    withoutQuotes: boolean = false
  ): any {
    let ts: string;
    let t: any;
    for (let [k, iterator] of Object.entries(tarr)) {
      if (withoutQuotes) {
        ts = this.stringToJson(iterator);
        t = JSON.parse(ts);
      } else {
        t = iterator;
      }

      if (checkobj === t[propname]) {
        if (withoutQuotes) {
          return { isExists: true, element: t, indexno: k };
        } else {
          return { isExists: true, element: iterator, indexno: k };
        }
      }
    }
    return { isExists: false, element: undefined, indexno: undefined };
  }

  public static isExitsInObjectI3(
    tarr: any,
    checkobj1: any,
    checkobj2: any,
    propname1: string,
    propname2: string
  ): any {
    let count: number = 0;
    for (let iterator of tarr) {
      if (
        checkobj1 === iterator[propname1] &&
        checkobj2 === parseInt(iterator[propname2])
      ) {
        return { isExists: true, element: iterator, indexno: count };
      }
      count++;
    }
    return { isExists: false, element: undefined, indexno: undefined };
  }

  public static stringToJson(valueString: any): any {
    let ts1: string = valueString.toString();
    // remove brackets.
    let ts2: string = ts1.substring(1, ts1.length - 1);
    let ta: Array<any> = ts2.split(",");
    let ts4: string = "";
    let c: number = 0;
    for (let v of ta) {
      let ta1: Array<any> = v.split(":");
      let ts3: string = `"${ta1[0]}":"${ta1[1]}"`;

      if (c === 0) ts4 = `${ts4}${ts3}`;
      else ts4 = `${ts4},${ts3}`;
      c++;
    }
    ts4 = `{${ts4}}`;
    return ts4;
  }

  public static convertToJson(obj: any): string {
    return JSON.stringify(obj);
  }

  public static compareTwoObjsProp(fo, so): boolean {
    let foArray = Object.entries(fo);
    let soArray = Object.entries(so);

    if (foArray.length !== soArray.length) {
      return false;
    }

    let objectMatched: boolean = true;
    for (let [k, v] of foArray) {
      if (!this.checkArray(soArray, k, v)) {
        objectMatched = false;
        break;
      }
    }

    return objectMatched;
  }

  private static checkArray(array, key, value): boolean {
    let isExists = false;
    for (let [k, v] of array) {
      if (k === key && value === v) {
        isExists = true;
      }
    }

    return isExists;
  }

  public static isValueDefinedByProp(objref: any, prop: string) {
    try {
      if (objref[prop]) {
        return true;
      } else if (objref[prop] === "") {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  public static isValueDefined(value) {
    try {
      if (!this.isUndefined(value)) {
        return true;
      }
    } catch (error) {
      return false;
    }
  }

  public static isUndefined(value): boolean {
    if (value === undefined) {
      return true;
    }
    if (typeof value === "string" && value.toLowerCase() === "undefined") {
      return true;
    }
    return false;
  }

  public static isNull(value: any): boolean {
    if (value === "null" || value === null) {
      return true;
    }
    return false;
  }

  public static fetchNoFromString(
    value,
    extractval,
    exactindex = false
  ): number {
    try {
      let i = value.slice(extractval.length);
      if (!exactindex) i--;
      return Number(i);
    } catch (error) {
      return -1;
    }
  }

  public static sortArrayAsPer(value, array) {
    return array.sort(this.compare(value));
  }

  private static compare(propname) {
    return (fo, so) => {
      let fv =
        propname === ""
          ? Number(fo.substring(1))
          : Number(fo[propname].substring(1));
      let sv =
        propname === ""
          ? Number(so.substring(1))
          : Number(so[propname].substring(1));

      let comparison = 0;
      if (fv > sv) {
        comparison = 1;
      } else if (fv < sv) {
        comparison = -1;
      }
      return comparison;
    };
  }

  public static deepCopy<T>(srcObj: T, targetObj: { new (): T }): T {
    let tempObj: T = new targetObj();
    for (let [key, value] of Object.entries(srcObj)) {
      tempObj[key] = value;
    }
    return tempObj;
  }

  public static deepCopyNObj<T>(srcObj: any): any {
    let tempObj: any = {};
    for (let [key, value] of Object.entries(srcObj)) {
      tempObj[key] = value;
    }
    return tempObj;
  }

  public static unit8ArrayToString(u8ArObj: Uint8Array): string {
    let str: string = "";
    for (var i: number = 0; i < u8ArObj.length; i++) {
      str += String.fromCharCode(u8ArObj[i]);
    }
    return JSON.parse(str);
  }

  public static deleteUnusedTag(
    mappngobj: any,
    specifictag: string | Array<string> = undefined
  ): void {
    if (specifictag) {
      if (typeof specifictag === "string") {
        delete mappngobj[specifictag];
      } else {
        for (let v of specifictag) {
          delete mappngobj[v];
        }
      }

      return;
    }

    for (let [k, v] of Object.entries(mappngobj)) {
      if (
        mappngobj[k] === null ||
        mappngobj[k] === "" ||
        mappngobj[k] === undefined
      ) {
        delete mappngobj[k];
      }
    }
  }

  public static getDateOfISOWeek(w: number, year: number): Date {
    let simple = new Date(year, 0, 1 + (w - 1) * 7);
    let dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }

  public static calculateDistance(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ): number {
    let radius = 6371; // Radius of Earth in km
    let dLat = this.degreeToRadian(lat2 - lat1);
    let dLon = this.degreeToRadian(lon2 - lon1);
    lat1 = this.degreeToRadian(lat1);
    lat2 = this.degreeToRadian(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = radius * c;
    return distance;
  }

  public static getISOFromDate(date: Date): string {
    const backDate = date.setDate(date.getDate() - 1);
    const stringBackDate = new Date(backDate);
    const milliseconds = stringBackDate.setHours(18, 30, 0, 0);
    return new Date(milliseconds).toISOString();
  }

  public static degreeToRadian(value: number): number {
    return value * (Math.PI / 180);
  }

  public static convertTimestampToDate(
    milliseconds: number = new Date().getTime()
  ): string {
    return new Date(milliseconds).toISOString().split("T")[0];
  }

  public static getFormattedForwardDate(
    currentFormattedDate: string,
    numberToForward: number
  ): string {
    const tDate = new Date(currentFormattedDate);
    const cDate = tDate.getDate();
    const bDate = tDate.setDate(cDate + numberToForward);
    return HelperFunc.convertTimestampToDate(bDate);
  }

  public static getFormattedBackDate(
    currentFormattedDate: string,
    numberToBack: number
  ): string {
    const tDate = new Date(currentFormattedDate);
    const cDate = tDate.getDate();
    const bDate = tDate.setDate(cDate - numberToBack);
    return HelperFunc.convertTimestampToDate(bDate);
  }

  public static addHoursTo24HourTimeString(
    timeString: string,
    hours: number
  ): string {
    let timeArray: string[] = timeString.split(":");
    let hour = +timeArray[0];
    if (hour === 0) {
      hour = hour + 24;
      return `${hour}:${timeArray[1]}`;
    }
    let addedHour: number = hour + hours;
    return `${addedHour}:${timeArray[1]}`;
  }

  public static getHoursFromTimeString(timeString: string): number {
    let hour: number = +timeString?.split(":")[0];
    let minute: number = +timeString?.split(":")[1];
    return hour + minute / 60;
  }

  public static getHoursBetweenTimeInterval(
    startTime: string,
    endTime: string
  ): number {
    return (
      this.getHoursFromTimeString(endTime) -
      this.getHoursFromTimeString(startTime)
    );
  }

  public static getMeridiemOfTimeString(timeString: string): string {
    let hour: number = +timeString.split(":")[0];
    let meridiem: string = "";
    if (hour > 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    return meridiem;
  }
}
