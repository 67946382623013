import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { UserConstants } from 'src/app/utils/userConstant';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/services/base.service';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { ConfirmModalService } from 'src/app/pages/modal/confirm-modal/confirm-modal.service';

@Component({
  selector: 'app-confirm-bids',
  templateUrl: './confirm-bids.component.html',
  styleUrls: ['./confirm-bids.component.scss']
})
export class ConfirmBidsComponent extends Basecomponent implements OnInit {
  constructor(baseService: BaseService,
    private userStorage: UserStorageService,
    private router: Router,
    private confirmModal: ConfirmModalService) {
    super(baseService);
  }

  confirmOrder: any = [];
  userCartHaveItems: boolean = false;
  data: any = {};
  aws3: string = UserConstants.AWS_S3_OBJECT_URL;
  userHashId: any = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ?? localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID)
  ngOnInit(): void {
    this.confirmOrder = this.getPassingData().stateData;
    console.log("confirmitem", this.confirmOrder, "jfj", this.userHashId);
    this.apiService.getUserCart(this.userHashId);

    this.data = {
      "businessId": this.confirmOrder.business_hash_id,
      "businessName": this.confirmOrder.businessName,
      "description": this.confirmOrder.item_description,
      "discount_title": "",
      "image_url": this.confirmOrder.item_image,
      "isPrescriptionRequired": 0,
      "isSelected": 1,
      "is_order_on": 1,
      "itemHashId": this.confirmOrder.item_hash_id,
      "item_name": this.confirmOrder.item_name,
      "item_price": this.confirmOrder.price,
      "item_quantity": this.confirmOrder.quantity,
      "selectedQuantity": this.confirmOrder.quantity,
      "subServiceType": this.confirmOrder.sub_service_type,
      "sub_service_hash": this.confirmOrder.subserviceHashId,
      "totalCost": this.confirmOrder.price,
      "userHashId": this.userHashId,
      "zoneId": this.confirmOrder.zone_hash_id,
      "orderType": 1
    }
  }
  apiResultSubscribe(data: any) {
    console.log("data", data);
    if (data.resulttype == UserConstants.RESULT_GET_USER_CART) {
      if (data.result.statusCode == 200) {
        this.userCartHaveItems = data.result.data.length > 0;
      }
    }
    if (data.resulttype == UserConstants.RESULT_DELETE_USER_CART) {
      if (data.result.statusCode == 200) {
        this.userCartHaveItems=false
        // this.apiService.createUserCart(this.data, this.userHashId);
      }
    }
    if (data.resulttype == UserConstants.RESULT_CREATE_USER_CART) {
      if (data.result.statusCode == 200) {
        this.closePopup();
        this.router.navigate([`${UserConstants.RL_CART_CHECKOUT}`]);
        this.apiService.getremoveSupriseMeOrder({"orderHash":this.confirmOrder.suprise_me_req_hash,"userHashId":this.userHashId});
      }
    }
  }
  goToCheckout() {
    if(this.userCartHaveItems==true){
      this.confirmModal.confirm("Items already in cart", "Your cart contains items from other services. Would you like to reset your cart for adding items from this services?", "YES, START FRESH", "NO").then(res => {
        if (res == true) {
          this.apiService.deleteUserCart(this.userHashId);
        }
      });
    }else{
      this.apiService.createUserCart(this.data, this.userHashId);
    }
  }
  closeModel() {
    this.compManager.closePopup();
  }
}
