import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Input,
  ViewContainerRef,
} from "@angular/core";
import { ComponentInfo } from "../models/compinfo";

@Directive({
  selector: "[zapCompMap]",
})
export class CompMapDirective {
  private _componentFactory: any;

  constructor(
    private vcRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private el: ElementRef
  ) {}

  @Input() set zapCompMap(value: ComponentInfo) {
    if (value) {
      if (!value.comp) {
        this.vcRef.clear();
        return;
      }
      this._componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        value.comp.component
      );
      this.vcRef.clear();
      this.vcRef.createComponent(this._componentFactory);
    }
  }
}
