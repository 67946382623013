<!-- Most popular reviews -->
<div class="helpful_review_part">
    <!-- <div class="popular_reviews">
        <h3>Most popular reviews</h3>
    </div> -->
    <div class="ult_dt_paer">
        <div class="list_of_rvs" *ngFor="let item of reviewDataList;let i=index;">
            <div class="row">
                <div class="col-sm-1">
                    <div class="utity_img">
                        <img [src]="item.created_by_image_url">
                    </div>
                </div>
                <div class="col-sm-11">
                    <div class=""></div>
                    <div class="uti_pt_heading">
                        <div class="rating-product">
                            <i class="fa fa-star" aria-hidden="true"></i> {{item.rating.toFixed(1)}}
                        </div>
                        <h3>{{item.created_by_name}}</h3>
                        <span>{{item.creation_time | date: "MMM d, y, h:mm a"}}</span>
                        <p>{{item.message && item.message != 'null' ? item.message : 'No review given' }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="reviewDataList.length == 0">
            <div class="col-sm-12">
                <div class="pce_hml_text">
                    <div class="gtr_plc">
                        <div class="plt_hlr">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve"><g>
                            <g xmlns="http://www.w3.org/2000/svg">
                              <g>
                                <path d="M401,401c-20.671,0-38.255,13.42-44.531,32H79c-27.019,0-49-21.981-49-49c0-27.019,21.981-49,49-49h96    c43.561,0,79-35.439,79-79c0-43.561-35.439-79-79-79H91.531C86.848,163.134,75.866,152.152,62,147.469V111h81    c8.284,0,15-6.716,15-15V32c0-8.284-6.716-15-15-15H47c-8.284,0-15,6.716-15,15v64v51.469C13.42,153.745,0,171.329,0,192    c0,25.916,21.084,47,47,47c20.671,0,38.255-13.42,44.531-32H175c27.019,0,49,21.981,49,49s-21.981,49-49,49H79    c-43.561,0-79,35.439-79,79c0,43.561,35.439,79,79,79h277.469c6.276,18.58,23.86,32,44.531,32c25.916,0,47-21.084,47-47    S426.916,401,401,401z M62,47h66v34H62V47z M47,209c-9.374,0-17-7.626-17-17c0-9.374,7.626-17,17-17s17,7.626,17,17    C64,201.374,56.374,209,47,209z M401,465c-9.374,0-17-7.626-17-17c0-9.374,7.626-17,17-17c9.374,0,17,7.626,17,17    C418,457.374,410.374,465,401,465z" fill="#000000" data-original="#000000"  ></path>
                              </g>
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                              <g>
                                <path d="M401,113c-25.916,0-47,21.084-47,47s21.084,47,47,47s47-21.084,47-47S426.916,113,401,113z M401,177    c-9.374,0-17-7.626-17-17c0-9.374,7.626-17,17-17c9.374,0,17,7.626,17,17C418,169.374,410.374,177,401,177z" fill="#000000" data-original="#000000"  ></path>
                              </g>
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                              <g>
                                <path d="M401,49c-61.206,0-111,49.794-111,111c0,33.051,14.399,63.844,39.626,85.015l58.01,113.797    C390.197,363.837,395.36,367,401,367s10.803-3.163,13.364-8.188l58.01-113.797C497.601,223.844,512,193.051,512,160    C512,98.794,462.206,49,401,49z M451.15,223.613c-1.698,1.341-3.085,3.032-4.068,4.96L401,318.972l-46.082-90.399    c-0.982-1.927-2.37-3.619-4.068-4.96C331.244,208.132,320,184.946,320,160c0-44.664,36.336-81,81-81s81,36.336,81,81    C482,184.946,470.756,208.132,451.15,223.613z" fill="#000000" data-original="#000000"  ></path>
                              </g>
                            </g>

                            </g></svg>
                            <div class="rgt_ted">
                                <h3>No rate and review found
                                </h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="list_of_rvs">
            <div class="row">
                <div class="col-sm-1">
                    <div class="utity_img">
                        <img src="https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot2003/vadymvdrobot200300051/142744663-image-of-a-young-strong-happy-cheery-sports-man-posing-outdoors-in-nature-green-park-looking-camera-.jpg?ver=6">
                    </div>
                </div>
                <div class="col-sm-11">
                    <div class="uti_pt_heading">
                        <div class="rating-product">
                            <i class="fa fa-star" aria-hidden="true"></i> 5.0
                        </div>
                        <h3>Sumanta Mandal</h3>
                        <span>Kolkata, 7th Jan, 2022</span>
                        <p>Thank you susmita and uc . Wish services again</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="list_of_rvs">
            <div class="row">
                <div class="col-sm-1">
                    <div class="utity_img">
                        <img src="https://us.123rf.com/450wm/luismolinero/luismolinero1909/luismolinero190917934/130592146-handsome-young-man-in-pink-shirt-over-isolated-blue-background-keeping-the-arms-crossed-in-frontal-p.jpg?ver=6">
                    </div>
                </div>
                <div class="col-sm-11">
                    <div class="uti_pt_heading">
                        <div class="rating-product">
                            <i class="fa fa-star" aria-hidden="true"></i> 5.0
                        </div>
                        <h3>Rubai Mandal</h3>
                        <span>Kolkata, 7th Jan, 2022</span>
                        <p>Thank you susmita and uc . Wish services again</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="list_of_rvs">
            <div class="row">
                <div class="col-sm-1">
                    <div class="utity_img">
                        <img src="https://thumbs.dreamstime.com/b/handsome-man-hair-style-beard-beauty-face-portrait-fashion-male-model-black-hair-high-resolution-handsome-man-125031765.jpg">
                    </div>
                </div>
                <div class="col-sm-11">
                    <div class="uti_pt_heading">
                        <div class="rating-product">
                            <i class="fa fa-star" aria-hidden="true"></i> 5.0
                        </div>
                        <h3>Ayan pal</h3>
                        <span>Kolkata, 7th Jan, 2022</span>
                        <p>Thank you susmita and uc . Wish services again</p>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</div>
<!-- most popular reviews end -->