import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { UserConstants } from 'src/app/utils/userConstant';

@Component({
  selector: 'app-view-job-card',
  templateUrl: './view-job-card.component.html',
  styleUrls: ['./view-job-card.component.scss']
})
export class ViewJobCardComponent extends Basecomponent implements OnInit {

  constructor(baseService : BaseService,
    private userStorage : UserStorageService,
    ){
    super(baseService);
  }
  orderHashId:string;
  orderStatus:string;
  ngOnInit(): void {
    super.ngOnInit();
    let data = this.getPassingData().stateData;
    console.log(data);
    this.orderHashId = data.orderHashId;
    this.apiService.getServicePersonnelOrderJobCard(this.orderHashId);
    this.apiService.getUtilityOrderDetailByOrderHashId(this.orderHashId);
  }
  jobCardList:any[]=[];

  apiResultSubscribe(data: any) {
      console.log(data);
      if(data.resulttype == UserConstants.RESULT_GET_SERVICE_PERSONNEL_ORDER_JOB_CARD){
        if(data.result.statusCode == 200){
          this.jobCardList=[];
          data.result.data.forEach(element => {
            this.jobCardList.push(element);
          });
        }else{
          this.setError(true,data.result);
        }
      }else if(data.resulttype == UserConstants.RESULT_GET_UTILITY_ORDER_DETAIL_BY_ID){
        if(data.result.statusCode == 200){
          if(data.result.data.length != 0){
            let order = data.result.data[0];
            this.orderStatus = order.vendor_delivery_status_latest;
          }else{
           
          }
        }else{
          this.setError(true,data.result);
        }
      }else if(data.resulttype == UserConstants.RESULT_UPDATE_SERVICE_PERSONNEL_ORDER_STATUS){
        if(data.result.statusCode == 200){

        }else{
          this.setError(true,data.result);
        }
      }
  }
}
