import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private _showLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  get showLoader(){
    return this._showLoader.asObservable();
  }

  set showLoader(value:any){
    this._showLoader.next(value);
  }
}
