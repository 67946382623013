import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { UserConstants } from 'src/app/utils/userConstant';
import { BaseService } from 'src/app/services/base.service';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { MouseEvent } from '@agm/core';

declare var google;

@Component({
  selector: 'app-track-ride-map',
  templateUrl: './track-ride-map.component.html',
  styleUrls: ['./track-ride-map.component.scss']
})
export class TrackRideMapComponent extends Basecomponent implements OnInit {
  zoom: number = 15;
  geocoder = new google.maps.Geocoder();
  // initial center position for the map
  lat: any = (this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_LATITUDE)) ? parseFloat(this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_LATITUDE)) : parseFloat(localStorage.getItem(UserConstants.KEY_SELECTED_LATITUDE));
  lng: any = (this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_LONGITUDE)) ? parseFloat(this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_LONGITUDE)) : parseFloat(localStorage.getItem(UserConstants.KEY_SELECTED_LONGITUDE));
  constructor(baseService : BaseService,
    private userStorage : UserStorageService) {
    super(baseService);
   }
   businessName : string;
   businessAddress:any={};
   businessImage : any=[];
   deliveryAddress : any={};
   deliveryBoyInfo : any={};
     ngOnInit(): void {
    super.ngOnInit();
    let data = this.getPassingData().stateData;
    console.log(data);
    // this.businessName = data.businessName;
    // this.businessAddress = data.businessAddress;
    // this.businessImage = data.businessImage;
    // this.deliveryAddress = data.deliveryAddress;
    // this.deliveryBoyInfo = data.deliveryBoyInfo;
    // this.start_end_mark.push([parseFloat(this.businessAddress.lat),parseFloat(this.businessAddress.lng)]);
    // this.start_end_mark.push([parseFloat(this.deliveryAddress.lat),parseFloat(this.deliveryAddress.lng)]);
    // this.latlng.push([parseFloat(this.businessAddress.lat),parseFloat(this.businessAddress.lng)]);
    // this.latlng.push([parseFloat(this.deliveryAddress.lat),parseFloat(this.deliveryAddress.lng)]);
  }
  start_end_mark :any= [];

  latlng :any= [];
  createAvaterName( userName ) {
    const name = userName.trim().split( ' ' );
    let avater: string;
    if ( name.length > 1 ) {
      avater = name[ 0 ].substring( 0, 1 ) + '' + name[ 1 ].substring( 0, 1 );
    } else {
      avater = userName.substring( 0, 2 );
    }
    return avater;
  }

  callMe(value){
    let str = 'tel:'+'+91'+value;
    console.log(str);
    window.location.href=str;
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  mapClicked($event: MouseEvent) {
      this.lat =  $event.coords.lat;
      this.lng = $event.coords.lng;
      this.zoom=15;
    // this.getAddress(this.lat,this.lng);
  }
/**
 * End
 */

}
