import { Injectable } from '@angular/core';
import { UserConstants } from '../utils/userConstant';
// import * as dev_url from '../config-data/dev-url.json';
//const prod_urls:any  = require('../data/test_data1.json');
const BASE_URL: string = "http://localhost:4200";
const dev_urls:any = require("../config-data/dev-url.json");
// const dev_urls :string='';
@Injectable({
  providedIn: 'root'
})
export class UrlConverterService {

  private _isLocalEnv:boolean = true;
  private _stage:string = "dev";
  private _accessLevel: string = "public";

  constructor() { }

  public initialize(initGoogleMap: boolean = true):void{
    if(this._isLocalEnv){
      UserConstants.AWS_S3_OBJECT_URL = `${dev_urls.S3ObjectURL}/${this._accessLevel}/`;
      UserConstants.GET_TEST_DATA_URL = `assets/jsons/test_data1.json`;
      UserConstants.GOOGLE_PLACES_API_URL = `${dev_urls.googlePlacesURL}`;
      UserConstants.USER_LOGIN_IN_DATA = `${dev_urls.userSignIn}/${this._stage}/api/userservice/getuserdata`;
      UserConstants.GET_ZONE_INFO_BY_LOCATION = `${dev_urls.setLocation}/${this._stage}/api/userservice/getzonebylocationinfo`;
      UserConstants.SET_NORMAL_USER_ZONE_MAP = `${dev_urls.setLocation}/${this._stage}/api/userservice/setNormalUserZoneMap`;
      UserConstants.GET_ZONE_SPECIFIC_SUB_SERVICE_LIST = `${dev_urls.setLocation}/${this._stage}/api/app/merchentservice/subService`;
      UserConstants.GET_BUSINESS_LIST_BY_SUB_SERVICE = `${dev_urls.setLocation}/${this._stage}/api/merchant/getEntityBySubServiceAndZoneId`;
      UserConstants.GET_BUSINESS_DETAIL_BY_ID = `${dev_urls.setLocation}/${this._stage}/api/userservice/getBusinessDetail`;
      UserConstants.GET_INVENTORY_ITEM_FOR_NORMAL_USER = `${dev_urls.setLocation}/${this._stage}/api/merchant/getInventoryItemForNormalUser`;
      UserConstants.SEARCH_INVENTORY_ITEM_FOR_NORMAL_USER = `${dev_urls.setLocation}/${this._stage}/api/merchant/getInventoryItemForNormalUser`;
      UserConstants.GET_BUSINESS_LIST_BY_ZONE_ID = `${dev_urls.setLocation}/${this._stage}/api/merchant/getEntityListByZoneId`;
      UserConstants.GET_ITEM_CATEGORY_AS_PER_KEY = `${dev_urls.setLocation}/${this._stage}/api/merchant/getItemCategoryAsPerKey`;
      UserConstants.UPDATE_USER_DATA = `${dev_urls.setLocation}/${this._stage}/api/userservice/addnormaluserdata`;
      UserConstants.ADD_NEW_JOINING_INTEREST_REQUEST_DATA = `${dev_urls.setLocation}/${this._stage}/api/service/addInterestRequest`;
      UserConstants.GET_USER_CART = `${dev_urls.setLocation}/${this._stage}/api/userservice/getUserCart`;
      UserConstants.CREATE_USER_CART = `${dev_urls.setLocation}/${this._stage}/api/userservice/createUserCart`;
      UserConstants.UPDATE_CART_QUANTITY = `${dev_urls.setLocation}/${this._stage}/api/userservice/updateCartQuantity`;
      UserConstants.GET_NORMAL_USER_DETAIL_BY_ACCOUNT_ID = `${dev_urls.setLocation}/${this._stage}/api/userservice/getNormalUserDataByAccountId`;
      UserConstants.GET_DELIVERY_ADDRESS = `${dev_urls.setLocation}/${this._stage}/api/userservice/getUserDeliveryAddress`;
      UserConstants.ADD_EDIT_DELIVERY_ADDRESS = `${dev_urls.setLocation}/${this._stage}/api/userservice/addEditDeliveryAddress`;
      UserConstants.DELETE_DELIVERY_ADDRESS = `${dev_urls.setLocation}/${this._stage}/api/userservice/deleteDeliveryAddress`;
      UserConstants.SET_DEFAULT_DELIVERY_ADDRESS = `${dev_urls.setLocation}/${this._stage}/api/userservice/setdefaultDeliveryAddress`;
      UserConstants.BOOK_RESTAURANT_TABLE = `${dev_urls.setLocation}/${this._stage}/api/userservice/bookRestaurantTable`;
      UserConstants.REMOVE_CART_ITEM = `${dev_urls.setLocation}/${this._stage}/api/userservice/removeCartItem`;
      UserConstants.DELETE_USER_CART = `${dev_urls.setLocation}/${this._stage}/api/userservice/deleteUserCart`;
      UserConstants.VALIDATE_COUPON = `${dev_urls.setLocation}/${this._stage}/api/userservice/validateCoupon`;
      UserConstants.GET_AVAILABLE_COUPON = `${dev_urls.setLocation}/${this._stage}/api/operation/getAvailableCoupon`;
      UserConstants.GET_CHECKOUT_VALUE = `${dev_urls.setLocation}/${this._stage}/api/userservice/getCheckOutValue`;
      UserConstants.CREATE_USER_ORDER = `${dev_urls.setLocation}/${this._stage}/api/userservice/createUserOrder`;
      UserConstants.CREATE_LAUNDRY_USER_ORDER = `${dev_urls.setLocation}/${this._stage}/api/service/createServiceUserOrder`;
      UserConstants.GET_ORDER_BY_USER_ID = `${dev_urls.setLocation}/${this._stage}/api/operation/getOrdersByOrderById`;
      UserConstants.GET_ORDER_DETAIL_FOR_NORMAL_USER = `${dev_urls.setLocation}/${this._stage}/api/operation/getOrderDetailForNormalUser`;
      UserConstants.UPDATE_ORDER_STATUS = `${dev_urls.setLocation}/${this._stage}/api/operation/updateOrderStatus`;
      UserConstants.GET_ORDER_TRACK_HISTORY = `${dev_urls.setLocation}/${this._stage}/api/operation/getOrderTrackHistory`;
      UserConstants.GET_TABLE_BOOKING_INFO = `${dev_urls.setLocation}/${this._stage}/api/userservice/getRestaturantTableBookingInfo`;
      UserConstants.ADD_MERCHANT_RATING = `${dev_urls.setLocation}/${this._stage}/api/userservice/addMerchentRating`;
      UserConstants.ADD_DELIVERY_RATING = `${dev_urls.setLocation}/${this._stage}/api/userservice/addDeliveryRatingActivity`;
      UserConstants.GET_DELIVERY_PERSON_INFO_BY_ORDER_ID = `${dev_urls.setLocation}/${this._stage}/api/transport/getDeliveryPersonInfoByOrderId`;
      UserConstants.ADD_CONTACT_US_INFO = `${dev_urls.setLocation}/${this._stage}/api/operation/addContactUsInfo`;
      UserConstants.GET_WALLET_INFO_BY_ACCOUNT_ID = `${dev_urls.setLocation}/${this._stage}/api/userservice/getWalletInfoByUserId`;
      UserConstants.GET_SCRATCH_CARD_PROVIDED_TO_USER = `${dev_urls.setLocation}/${this._stage}/api/operation/getScratchCardProvideToUser`;
      UserConstants.REDEEMED_SCRATCH_CARD = `${dev_urls.setLocation}/${this._stage}/api/operation/redeemedScratchCard`;
      UserConstants.GET_BUSINESS_PROMOTION_BY_ZONE = `${dev_urls.setLocation}/${this._stage}/api/operation/getPromotionByZoneId`;
      UserConstants.GET_USER_NOTIFICATION = `${dev_urls.setLocation}/${this._stage}/api/userservice/getUserNotification`;
      UserConstants.GET_BUSINESS_PROMOTION_BY_SUB_SERVICE_ID = `${dev_urls.setLocation}/${this._stage}/api/operation/getPromoAdsBasedOnSeubserviceByZone`;

      UserConstants.GET_LAUNDRY_LIST = `${dev_urls.setLocation}/${this._stage}/api/service/getServicePersonalBusinessList`;
      UserConstants.GET_LAUNDRY_DETAIL_BY_ID = `${dev_urls.setLocation}/${this._stage}/api/service/getBusinessDetailById`;
      UserConstants.GET_INVENTORY_ITEM_LAUNDRY = `${dev_urls.setLocation}/${this._stage}/api/service/getInventoryDetailAsPerID`;
      UserConstants.GET_ITEM_CATEGORY_LAUNDRY = `${dev_urls.setLocation}/${this._stage}/api/service/getItemCategoryAsPerKey`;
      UserConstants.SEARCH_INVENTORY_ITEM_LAUNDRY = `${dev_urls.setLocation}/${this._stage}/api/service/getInventoryDetailAsPerID`;
      UserConstants.GET_CAB_DATA_BY_ADDRESS_DATE = `${dev_urls.setLocation}/${this._stage}/api/transport/getCab`;

      UserConstants.GET_RIDE_COST_SETUP = `${dev_urls.setLocation}/${this._stage}/api/transport/getRideCostSetUp`;

      UserConstants.ADD_EDIT_AUTO_ORDER = `${dev_urls.setLocation}/${this._stage}/api/transport/createRideUserOrder`;

      UserConstants.GET_BIKE_ORDER_DETAIL_FOR_USER = `${dev_urls.setLocation}/${this._stage}/api/transport/getBikeOrderDetailForUser`;
      UserConstants.GET_AUTO_ORDER_DETAIL_FOR_USER =`${dev_urls.setLocation}/${this._stage}/api/transport/getAutoOrderDetailForUser`;
      UserConstants.GET_CAB_ORDER_DETAIL_FOR_USER = `${dev_urls.setLocation}/${this._stage}/api/transport/getCabOrderDetailForUser`;
      UserConstants.GET_TOTO_ORDER_DETAIL_FOR_USER = `${dev_urls.setLocation}/${this._stage}/api/transport/getTotoOrderDetailForUser`;

      UserConstants.ADD_EDIT_CAB_ORDER = `${dev_urls.setLocation}/${this._stage}/api/transport/createRideUserOrder`;
      UserConstants.ADD_EDIT_BIKE_ORDER = `${dev_urls.setLocation}/${this._stage}/api/transport/createRideUserOrder`;

      UserConstants.ADD_EDIT_TOTO_ORDER = `${dev_urls.setLocation}/${this._stage}/api/transport/createRideUserOrder`;

      UserConstants.GET_ALL_VEHICLE_RIDE_HISTORY = `${dev_urls.setLocation}/${this._stage}/api/transport/getAllRideOrdersByUserHashId`;
      UserConstants.GET_SERVICE_ENTITY_BY_SUB_SERVICE_AND_ZONE_HASH_ID = `${dev_urls.setLocation}/${this._stage}/api/service/getServiceEntityBySubServiceAndZoneId`;
      UserConstants.GET_PICK_AND_DROP_DELIVERY_COST_ZONE_SPECIFIC = `${dev_urls.setLocation}/${this._stage}/api/operation/getPickAndDropDeliveryCostZone`;
      UserConstants.GET_SUB_SERVICE_SERVICE_PERSONEL = `${dev_urls.setLocation}/${this._stage}/api/service/getServicePersonnelSubService`;
      UserConstants.GET_SERVICE_PERSONAL_RATE_LIST = `${dev_urls.setLocation}/${this._stage}/api/service/getServicePersonnelRate`;
      UserConstants.GET_SERVICE_PERSONEL_INFO_BY_USER_HASH_ID = `${dev_urls.setLocation}/${this._stage}/api/service/getServicePersonProfileInfoByAccountId`;
      UserConstants.GET_SERVICE_PERSONEL_BUSINESS_DETAIL_BY_USER_HASH_ID = `${dev_urls.setLocation}/${this._stage}/api/service/getServicePersonelBusinessDetailByUserHashId`;

      UserConstants.ADD_EDIT_COURIER_BOOKING = `${dev_urls.setLocation}/${this._stage}/api/service/createServiceUserOrder`;
      UserConstants.GET_COURIER_BOOKING_DETAIL = `${dev_urls.setLocation}/${this._stage}/api/service/getCourierBookingDetails`;
      UserConstants.GET_COURIER_BOOKING_HISTORY = `${dev_urls.setLocation}/${this._stage}/api/app/Service/getCourierBookingUnlistedShopOrdersByUserHashId`;
      UserConstants.UPDATE_COURIER_BOOKING_STATUS = `${dev_urls.setLocation}/${this._stage}/api/app/Service/updateCourierBookingStatus`;
      UserConstants.GET_DISCOUNT_BY_SUB_SERVICE_ID = `${dev_urls.setLocation}/${this._stage}/api/operation/getDiscountBySubServiceId`;

      UserConstants.CREATE_UNLISTED_SHOP_ORDER = `${dev_urls.setLocation}/${this._stage}/api/service/createServiceUserOrder`;
      UserConstants.GET_UNLISTED_SHOP_ORDER_DETAIL_BY_ID = `${dev_urls.setLocation}/${this._stage}/api/Service/getUnlistedShopByOrderCode`;
      UserConstants.UPDATE_UNLISTED_SHOP_ORDER_STATUS = `${dev_urls.setLocation}/${this._stage}/api/app/Service/updateUnlistedShopBookingStatus`;
      UserConstants.GET_COURIER_ORDER_TRACKING_HISTORY = `${dev_urls.setLocation}/${this._stage}/api/operation/getCourierOrderTrackHistory`;
      UserConstants.GET_UNLISTED_SHOP_ORDER_TRACKING_HISTORY = `${dev_urls.setLocation}/${this._stage}/api/operation/getUnlistedShopOrderTrackHistory`;
      UserConstants.GET_BIKE_DATA_BY_ADDRESS_DATE = `${dev_urls.setLocation}/${this._stage}/api/transport/getBike`;
      UserConstants.GET_COURIER_DELIVERY_PERSON_INFO_BY_ORDER_ID = `${dev_urls.setLocation}/${this._stage}/api/transport/getCourierDeliveryPersonInfoByOrderId`;
      UserConstants.GET_INDIVIDUAL_SERVICE_PERSONEL_COST_SETUP = `${dev_urls.setLocation}/${this._stage}/api/service/getServicePersonalCostSetup`;
      UserConstants.CREATE_UTILITY_ORDER = `${dev_urls.setLocation}/${this._stage}/api/service/createServiceUserOrder`;

      UserConstants.UPDATE_SERVICE_PERSONNEL_ORDER_STATUS = `${dev_urls.setLocation}/${this._stage}/api/service/updateServiceOrderStatus`;
      UserConstants.GET_SERVICE_PERSONNEL_ORDER_JOB_CARD = `${dev_urls.setLocation}/${this._stage}/api/service/getServicePersonnelJobCardListing`;
      UserConstants.ADD_EDIT_SERVICE_PERSONNEL_PAYMENT = `${dev_urls.setLocation}/${this._stage}/api/service/addEditServicePersonnelPayment`;
      UserConstants.GET_UTILITY_ORDER_TRACKING_HISTORY = `${dev_urls.setLocation}/${this._stage}/api/service/getServiceOrderTrackHistory`;
      UserConstants.GET_ALL_TYPE_SERVICE_ORDER_BOOKING_HISTORY = `${dev_urls.setLocation}/${this._stage}/api/service/getAllServiceOrdersByUserHashId`;
      UserConstants.GET_UTILITY_ORDER_DETAIL_BY_ID = `${dev_urls.setLocation}/${this._stage}/api/service/getServicePersonnelOrderDetailsByOrderHashId`;
      UserConstants.GET_SERVICE_PERSONNEL_ORDER_BILL_PAYMENT = `${dev_urls.setLocation}/${this._stage}/api/service/getBillDetails`;
      UserConstants.GET_SERVICE_PERSONNEL_ORDER_PAID_DATA_LIST = `${dev_urls.setLocation}/${this._stage}/api/service/getServicePersonnelPaymentInfo`;
      UserConstants.GET_LAUNDRY_ORDER_DETAIL_BY_ORDER_ID = `${dev_urls.setLocation}/${this._stage}/api/service/getLaundryOrderDetailsForUser`;
      UserConstants.UPDATE_LAUNDRY_ORDER_STATUS = `${dev_urls.setLocation}/${this._stage}/api/service/updateLaundryOrderStatus`;
      UserConstants.ADD_SERVICE_PERSONNEL_RATING = `${dev_urls.setLocation}/${this._stage}/api/service/addServicePersonRating`;
      UserConstants.GET_SERVICE_PERSONNEL_RATING_REVIEW = `${dev_urls.setLocation}/${this._stage}/api/service/getServicePersonnelRating`;
      UserConstants.GET_RIDE_TRACK_HISTORY = `${dev_urls.setLocation}/${this._stage}/api/transport/getBikeOrderTrackHistory`;
      UserConstants.GET_SURPRISE_ME_ITEMS = `${dev_urls.setLocation}/${this._stage}/api/app/merchent/getMerchantItemForSearch`;
      UserConstants.SUBMIT_SURPRISE_ME_ORDER = `${dev_urls.setLocation}/${this._stage}/api/merchent/submitSurpriseMeOrder`;
      UserConstants.GET_SURPRISE_ME_BY_USER = `${dev_urls.setLocation}/${this._stage}/api/app/merchent/getSurpriseMeRequestsByUser`;
      UserConstants.GET_SURPRISE_ME_BIDS = `${dev_urls.setLocation}/${this._stage}/api/app/merchent/getSurpriseMeBids`;
      UserConstants.GET_REMOVE_SUPRISE_ME_ORDER = `${dev_urls.setLocation}/${this._stage}/api/merchent/removeSurpriseMeReq`;
      UserConstants.GET_PAYTM_PAYMENT = `${dev_urls.setLocation}/${this._stage}/api/merchent/initiateTransaction`;
      UserConstants.GET_PAYTM_PAYMENT_RECORD = `${dev_urls.setLocation}/${this._stage}/api/merchent/addEditPaytmPaymentRecord`;
      UserConstants.GET_PAYTM_PAYMENT_RECORD = `${dev_urls.setLocation}/${this._stage}/api/merchent/transactionStatus`;
      UserConstants.GET_USER_BANK_DETAILS = `${dev_urls.setLocation}/${this._stage}/api/userservice/getUserBankDetails`;
      UserConstants.DELETE_USER_BANK_DETAILS = `${dev_urls.setLocation}/${this._stage}/api/userservice/deleteUserBankDetails`;
      UserConstants.GET_USER_WALLET_EARN_CASH_REPORT = `${dev_urls.setLocation}/${this._stage}/api/userservice/getWalletEncashReport`;
      UserConstants.ADD_REEDEM_WALLET_CASH = `${dev_urls.setLocation}/${this._stage}/api/userservice/redeemWalletCash`;
      UserConstants.ADD_USER_BANK_DETAILS = `${dev_urls.setLocation}/${this._stage}/api/userservice/addEditUserBankDetails`;
      UserConstants.GET_RIDE_ORDER_HISTORY = `${dev_urls.setLocation}/${this._stage}/api/transport/getRideOrderHistoryDetails`;
      UserConstants.GET_RIDE_ORDER_DETAILS = `${dev_urls.setLocation}/${this._stage}/api/transport/getRideOrderDetails`;
      UserConstants.GET_ITEM_CUSTOMIZATION = `${dev_urls.setLocation}/${this._stage}/api/merchant/getItemCustomizationLists`;
      UserConstants.EDIT_CUSTOMIZABLE_FOR_MERCHANT = `${dev_urls.setLocation}/${this._stage}/api/userservice/addEditRemoveCustomizableForMerchantOrders`;
      UserConstants.GET_DRIVER_DELIVERY_LOCATION = `${dev_urls.setLocation}/${this._stage}/api/userservice/getDriverDeliveryCurrentLocation`;
      UserConstants.ADD_PHONE_CALL_BY_MASKING = `${dev_urls.setLocation}/${this._stage}/api/userservice/initiatePhoneCallBymasking`;

    }else{
      //ZAPConstants.AWS_S3_OBJECT_URL = `${prod_urls.S3ObjectURL}/${this._accessLevel}/`

    }
    // if(initGoogleMap)
    //   this.initGoogleMap();
  }
  private initGoogleMap() {
    const win = window as any;
    const google = win.google;

    if (!google) {
      const script = document.createElement("script");
      script.src = UserConstants.GOOGLE_PLACES_API_URL;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    } else {
      console.log("Google autocomplete API already added");
    }
  }
  get isLocalEnv():boolean{
    return this._isLocalEnv;
  }

  set isLocalEnv(value:boolean){
    this._isLocalEnv = value;
  }
}
