import { Component, OnInit, ViewChild } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { UserConstants } from 'src/app/utils/userConstant';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends Basecomponent implements OnInit {
  
  loginForm:FormGroup
  otp: string='';
  showOtpComponent = true;
  @ViewChild('ngOtpInput', { static: false}) ngOtpInput: any;
  config={
    length : 4,
    disableAutoFocus : false
  }
  isCheckboxSelected:Boolean=false;
  stepValue:number=1;
  constructor(baseService : BaseService,private authService : AuthService,private router : Router,
    private userStorage : UserStorageService) {
    super(baseService);
    this.loginForm = new FormGroup({
      'phone' : new FormControl(null, [Validators.required,Validators.pattern('^[0-9]*$'),Validators.minLength(10),Validators.maxLength(10)]),     
  })
   }

  ngOnInit(): void {
    super.ngOnInit();

    
  }

  apiResultSubscribe(data : any){
    console.log(data);
    if(data.resulttype == UserConstants.RESULT_GET_LOG_IN_DATA){
      if(data.result.statusCode == 200){
        this.closePopupWithData(true);
        localStorage.setItem('isLoginModelOpen', 'false')
        if(data.result.data[0].hash_id && data.result.data[0].hash_id != 'null'){
          this.userStorage.setValueInStorage(UserConstants.KEY_ACCOUNT_HASH_ID,data.result.data[0].hash_id);
          localStorage.setItem(UserConstants.KEY_ACCOUNT_HASH_ID,data.result.data[0].hash_id);
          let userData = data.result.data[0].profileData;
          let location = data.result.data[0].location;
          console.log(userData,location);
          this.userStorage.setValueInStorage(UserConstants.KEY_NAME,JSON.parse(userData).name);
          localStorage.setItem(UserConstants.KEY_NAME,JSON.parse(userData).name);
          this.userStorage.setValueInStorage(UserConstants.KEY_MOBILE_NEW,JSON.parse(userData).phone.substring(3));
          localStorage.setItem(UserConstants.KEY_MOBILE_NEW,JSON.parse(userData).phone.substring(3));
          let zoneHashId = (this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_ZONE_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_ZONE_HASH_ID) : localStorage.getItem(UserConstants.KEY_SELECTED_ZONE_HASH_ID);
          if(zoneHashId && zoneHashId != 'null'){
            this.apiService.setNormalUserZoneMap(zoneHashId);
            this.apiService.getSubServiceByZoneId(zoneHashId);
          }
        }
      }else{
        this.setError(true,data.result);
      }
    }
  }
  changeStep(value){
    this.second=60;
    this.loginForm.reset();
    this.isOtpCame=false;
    this.stepValue=value;
  }
  isOtpCame:Boolean=false;
  onLoginChange(){
    this.loginForm.get('phone').enable();
    this.isOtpCame=false;
  }
  onGetOtp(){
    console.log(this.loginForm);
    let phone_number = '+91'+this.loginForm.value.phone;
      this.userStorage.setValueInStorage(UserConstants.KEY_MOBILE,phone_number);
      localStorage.setItem(UserConstants.KEY_MOBILE,phone_number);
    this.authService.userLoginIn(phone_number).then(result=>{
      console.log(result);
      this.cognitoUser=result;
      this.isOtpCame=true;
      this.loginForm.get('phone').disable();
      // this.setTimer();
    }).catch(err=>{
      console.log(err);
      if(err.code == 'UserLambdaValidationException'){
        // localStorage.clear();
        // this.loginForm.reset();
        // this.changeStep(2);
        this.setError(false,'');
        this.onSignUp();
        // alert("User doesn't exist.");
      }
    })
  }
  resendCode(){
    this.authService.resendVerification(this.userStorage.getValueFromStorage(UserConstants.KEY_MOBILE)).then(res=>{
      console.log(res);
      this.cognitoUser=res;
      alert("Verification code sent successfully.");
      this.config.disableAutoFocus = false;
    }).catch(err=>{
      console.log(err);
      if(err.code == 'UserLambdaValidationException'){
     
        // alert("User doesn't exist.");
      }
    })
  }
  cognitoUser:any;
  onSignUp(){
    console.log(this.loginForm);
    // document.getElementById( "signup-btn" ).innerHTML = '<i class="fa fa-spinner fa-spin"></i>';
    // document.getElementById( "signup-btn" ).setAttribute( "disabled", "diasbled" );
    this.authService.userSignUp(this.loginForm.value.phone).then(res=>{

      console.log(res);
      if(res.userConfirmed){
        this.authService.userLoginIn1(res.user.username).then(result=>{
          console.log(result);
        // document.getElementById( "signup-btn" ).innerHTML = 'SignUp & Get OTP';
        // document.getElementById( "signup-btn" ).removeAttribute( "disabled" );
          this.cognitoUser=result;
          this.isOtpCame=true;
          this.loginForm.get('phone').disable();
        }).catch(err=>{
          // document.getElementById( "signup-btn" ).innerHTML = 'SignUp & Get OTP';
          // document.getElementById( "signup-btn" ).removeAttribute( "disabled" );
          console.log(err);
          // if(err.code == 'UserLambdaValidationException'){
          //   this.loginForm.reset();
          //   this.changeStep(1);
          //   alert("User doesn't exist.");
          // }
        })
      }
    })
  }
  second:number=60;
  setTimer(){
    {
      setInterval(function() {
        var a = new Date();
        document.getElementById("timer").innerHTML = this.second;
        this.second--;
        if (this.second == 0) {
         alert("1 Minute is complete");
         return;
        }
      }, 1000);
    }
  }
  onSubmit(){
    console.log("Enter Otp: ",this.otp,this.otp.length);
    console.log(this.cognitoUser);

    // this.userStorage.setValueInStorage(UserConstants.KEY_AUTH_ID,this.cognitoUser.challengeParam.USERNAME);
    //     localStorage.setItem(UserConstants.KEY_AUTH_ID,this.cognitoUser.challengeParam.USERNAME);
        
        // this.apiService.userLoginIn();
        // console.log("User Sign in successfully.");


    this.authService.userConfirmSignUp(this.cognitoUser,this.otp).then(res=>{
      console.log(res);
      if(res.signInUserSession){
        this.userStorage.setValueInStorage(UserConstants.KEY_AUTH_ID,res.attributes.sub);
        localStorage.setItem(UserConstants.KEY_AUTH_ID,res.attributes.sub);
        // this.apiService.userApiSignIn();
        console.log("User Sign in successfully.");
        this.apiService.userLoginIn();
      }else{
        alert("Verification code not matched. Try again.");
        // this.otp='';
        this.ngOtpInput.setValue('');
        this.config.disableAutoFocus = false;
      }
    }).catch(err=>{
      console.log(err);
    })
  }
  onOtpChange(otp) {
    this.otp = otp;
  }
  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  openPrivacy(){
    this.router.navigate([`${UserConstants.RL_PRIVACY_POLICY}`]);
  }
  openTerms(){
    this.router.navigate([`${UserConstants.RL_TERMS_OF_SERVICE}`]);
  }
  
}
