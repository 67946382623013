import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { UserConstants } from 'src/app/utils/userConstant';

@Component({
  selector: 'app-surprise-me',
  templateUrl: './surprise-me.component.html',
  styleUrls: ['./surprise-me.component.scss']
})
export class SurpriseMeComponent extends Basecomponent implements OnInit {
  cartItem: any;
  form: any;
  confirmItem: any;
  aws3: string = UserConstants.AWS_S3_OBJECT_URL;
  userHashId: any = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ?? localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID);
  surpriseMeOrderHash: any = null;

  constructor(baseService: BaseService,
    private router: Router,
    private userStorage: UserStorageService) {
    super(baseService);
  }

  ngOnInit(): void {
    this.confirmItem = this.getPassingData().stateData;
    // console.log('WAAA:::',this.confirmItem.cart);
    this.cartItem = this.confirmItem.cart;
    console.log("cartitem", this.cartItem);

    this.form = this.confirmItem.form;
    console.log("form", this.form);

  }
  apiResultSubscribe(data: any) {
    console.log("submitdata", data);
    if (data.resulttype == UserConstants.RESULT_SUBMIT_SURPRISE_ME_ORDER) {
      this.surpriseMeOrderHash = data.result.data[0].hash_id
      this.cartItem.notes = this.form.Notes;
      this.cartItem.price = this.form.Enteryourprice;
      this.cartItem.quantity=this.cartItem.selectedQuantity;
      this.closeModel();
      this.router.navigate(['surprisemebids'], {
        queryParams: {
          "surpriseMeOrderHash": this.surpriseMeOrderHash,
          "cartItem": JSON.stringify(this.cartItem)
        }
      });
    }
  }

  submiSuprisemeorder() {
    let data = {
      "item_name": this.cartItem.item_name,
      "item_id": this.cartItem.template_id,
      "item_image": this.cartItem.item_image,
      "item_description": this.cartItem.item_description,
      "price": this.form.Enteryourprice,
      "quantity": this.cartItem.selectedQuantity,
      "notes": this.form.Notes,
      "zone_hash_id": this.cartItem.zone_hash_id,
      "sub_service_type": this.cartItem.sub_service_type,
      "userHashId": this.userHashId,
      "unit": this.cartItem.unit
    }
    this.apiService.getsubmitSurpriseMeOrder(data);
  }
  closeModel() {
    this.compManager.closePopup();
  }
}

