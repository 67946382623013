<div class="admin_us_img">
    <error-exception-comp></error-exception-comp>
    <div class="hmi_sed pltfdr" *ngIf="!isCropImageSelected">
        <img height="300px" width="300px" src="/assets/images/default.jpeg" alt="profile Picture" style="vertical-align: middle;">
    </div>
    <div class="row">
        <div class="srp_sec">
            <image-cropper [imageChangedEvent]="imageChangedEvent" style="max-height:400px" [maintainAspectRatio]="true" [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="2 / 2" [resizeToWidth]="256" [cropperMinWidth]="128" [onlyScaleDown]="true"
                [roundCropper]="true" [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'center'" [style.display]="showCropper ? null : 'none'" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                (loadImageFailed)="loadImageFailed()"></image-cropper>
        </div>

        <div *ngIf="isCropImageSelected" class="prfl_img">
            <div class="dor_sec">
                <div (click)="zoomOut()" class="inline_dis"> <i class="fa fa-minus"></i></div>
                <div (click)="zoomIn()" class="inline_dis"> <i class="fa fa-plus"></i></div>
                <div (click)="rotateLeft()" class="inline_dis"><i class="fa fa-repeat"></i> </div>
                <div (click)="rotateRight()" class="inline_dis"><i class="fa fa-undo" aria-hidden="true"></i></div>
                <!-- <button (click)="rotateLeft()"><i class="fas fa-sync-alt"></i> Rotate left</button>
                <button (click)="rotateRight()"><i class="fas fa-sync-alt"></i> Rotate right</button> -->
            </div>
        </div>
    </div>
    <div class="add_footer">
        <div class="md-footer">
            <div class="my_upadl">
                <div class="file_dratoo" *ngIf="!isCropImageSelected">
                    <input type="file" accept="image/*" name="img" id="file" class="upload" (change)="fileChangeEvent($event)">
                    <span>Upload Picture</span>
                </div>
                <div class="row">
                    <div class="col-sm-6"></div>
                    <div class="col-sm-6">
                        <div *ngIf="isCropImageSelected" class="prfg_right">
                            <button (click)="removeCropPicture()" id="crop-cancel" class="jkl_buttn">Cancel</button>
                            <button (click)="saveCropProfilePicture()" id="crop-save">Save</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
