import { Injectable } from "@angular/core";
import { ComponentInfo } from '../models/compinfo';
import { LoginComponent } from '../component/authenticatiion/login/login.component';
import { UserConstants } from '../utils/userConstant';
import { ComponentRef } from '../models/compref';
import { ComponentModule } from '../enums/component-module.enum';
import { SelectLocationComponent } from '../component/select-location/select-location.component';
import { EditProfileComponent } from '../component/edit-profile/edit-profile.component';
import { ApplyCouponComponent } from '../component/apply-coupon/apply-coupon.component';
import { ProfilePictureComponent } from '../component/profile-picture/profile-picture.component';
import { BackgroundPictureComponent } from '../component/background-picture/background-picture.component';
import { AddDeliveryAddressComponent } from '../component/add-delivery-address/add-delivery-address.component';
import { BookTableComponent } from '../component/book-table/book-table.component';
import { SelectDeliveryLocationComponent } from '../component/select-delivery-location/select-delivery-location.component';
import { TrackOrderComponent } from '../component/track-order/track-order.component';
import { AddPharmacyDocumentComponent } from '../component/add-pharmacy-document/add-pharmacy-document.component';
import { ScratchCardComponent } from '../component/scratch-card/scratch-card.component';
import { ViewWalletHistoryComponent } from '../component/view-wallet-history/view-wallet-history.component';
import { TrackOrderMapComponent } from '../component/track-order-map/track-order-map.component';
import { TrackRideMapComponent } from '../component/track-ride-map/track-ride-map.component';
import { CourierTrackOrderComponent } from '../component/courier-track-order/courier-track-order.component';
import { ViewJobCardComponent } from '../component/view-job-card/view-job-card.component';
import { ViewRateReviewComponent } from '../component/view-rate-review/view-rate-review.component';
import { ServiceOrderMapComponent } from "../component/service-order-map/service-order-map.component";
import { SurpriseMeComponent } from "../component/surprise-me/surprise-me.component";
import { ConfirmBidsComponent } from "../component/confirm-bids/confirm-bids.component";
import { PaytmPaymentComponent } from "../component/paytm-payment/paytm-payment.component";
import { BankDetailsComponent } from "../component/bank-details/bank-details.component";
import { TransactionComponent } from "../component/transaction/transaction.component";
import { RedeemToAccountComponent } from "../component/redeem-to-account/redeem-to-account.component";
import { NotEligibleToRedeemCashComponent } from "../component/not-eligible-to-redeem-cash/not-eligible-to-redeem-cash.component";
import { RequestToRedeemCashComponent } from "../component/request-to-redeem-cash/request-to-redeem-cash.component";
import { UserBankAccountDetailsComponent } from "../component/user-bank-account-details/user-bank-account-details.component";
import { CustomizeComponent } from "../component/customize/customize.component";
// import { SurpriseComponent } from "../pages/surprise/surprise.component";
@Injectable({
  providedIn: "root",
})
export class CommonComponents {


  public getCompList(): Array<ComponentInfo> {
    return [
      {
        headerTitle: "Login",
        compName: ComponentModule[ComponentModule.LOGIN],
        compId: ComponentModule.LOGIN,
        comp: new ComponentRef(LoginComponent),
        routeLink: UserConstants.RL_LOGIN,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Select location",
        compName: ComponentModule[ComponentModule.SELECT_LOCATION],
        compId: ComponentModule.SELECT_LOCATION,
        comp: new ComponentRef(SelectLocationComponent),
        routeLink: UserConstants.RL_SELECT_LOCATION,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Update profile",
        compName: ComponentModule[ComponentModule.EDIT_PROFILE],
        compId: ComponentModule.EDIT_PROFILE,
        comp: new ComponentRef(EditProfileComponent),
        routeLink: UserConstants.RL_EDIT_PROFILE,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Apply coupon",
        compName: ComponentModule[ComponentModule.APPLY_COUPON],
        compId: ComponentModule.APPLY_COUPON,
        comp: new ComponentRef(ApplyCouponComponent),
        routeLink: UserConstants.RL_APPLY_COUPON,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Add profile picture",
        compName: ComponentModule[ComponentModule.Add_Profile_Picture],
        compId: ComponentModule.Add_Profile_Picture,
        comp: new ComponentRef(ProfilePictureComponent),
        routeLink: UserConstants.RL_ADD_PROFILE_PICTURE,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Add business picture",
        compName: ComponentModule[ComponentModule.Add_Business_Picture],
        compId: ComponentModule.Add_Business_Picture,
        comp: new ComponentRef(BackgroundPictureComponent),
        routeLink: UserConstants.RL_ADD_BACKGROUND_PICTURE,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Add delivery address",
        compName: ComponentModule[ComponentModule.Add_delivery_address],
        compId: ComponentModule.Add_delivery_address,
        comp: new ComponentRef(AddDeliveryAddressComponent),
        routeLink: UserConstants.RL_ADD_DELIVERY_ADDRESS,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Paytm Payment",
        compName: ComponentModule[ComponentModule.Paytm_payment],
        compId: ComponentModule.Paytm_payment,
        comp: new ComponentRef(PaytmPaymentComponent),
        routeLink: UserConstants.RL_PAYTM_PAYMENT,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Book table",
        compName: ComponentModule[ComponentModule.Book_table],
        compId: ComponentModule.Book_table,
        comp: new ComponentRef(BookTableComponent),
        routeLink: UserConstants.RL_BOOK_TABLE,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Select delivery location",
        compName: ComponentModule[ComponentModule.Select_delivery_location],
        compId: ComponentModule.Select_delivery_location,
        comp: new ComponentRef(SelectDeliveryLocationComponent),
        routeLink: UserConstants.RL_SELECT_DELIVERY_LOCATION,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Select delivery location",
        compName: ComponentModule[ComponentModule.Select_delivery_location],
        compId: ComponentModule.Select_delivery_location,
        comp: new ComponentRef(SelectDeliveryLocationComponent),
        routeLink: UserConstants.RL_SELECT_DELIVERY_LOCATION,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Track order",
        compName: ComponentModule[ComponentModule.Track_order],
        compId: ComponentModule.Track_order,
        comp: new ComponentRef(TrackOrderComponent),
        routeLink: UserConstants.RL_TRACK_ORDER,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Customize food in your way",
        compName: ComponentModule[ComponentModule.Customize],
        compId: ComponentModule.Customize,
        comp: new ComponentRef(CustomizeComponent),
        routeLink: UserConstants.RL_CUSTOMIZE,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Add Prescription",
        compName: ComponentModule[ComponentModule.Add_pharmacy_document],
        compId: ComponentModule.Add_pharmacy_document,
        comp: new ComponentRef(AddPharmacyDocumentComponent),
        routeLink: UserConstants.RL_ADD_PHARMACY_DOCUMENT,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Scratch Card",
        compName: ComponentModule[ComponentModule.scratch_card],
        compId: ComponentModule.scratch_card,
        comp: new ComponentRef(ScratchCardComponent),
        routeLink: UserConstants.RL_SCRATCH_CARD,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "View Wallet History",
        compName: ComponentModule[ComponentModule.View_Wallet_History],
        compId: ComponentModule.View_Wallet_History,
        comp: new ComponentRef(ViewWalletHistoryComponent),
        routeLink: UserConstants.RL_VIEW_WALLET_HISTORY,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Edit Bank Details",
        compName: ComponentModule[ComponentModule.Bank_Details],
        compId: ComponentModule.Bank_Details,
        comp: new ComponentRef(BankDetailsComponent),
        routeLink: UserConstants.RL_BANK_DETAILS,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Transactions",
        compName: ComponentModule[ComponentModule.Transaction],
        compId: ComponentModule.Bank_Details,
        comp: new ComponentRef(TransactionComponent),
        routeLink: UserConstants.RL_TRANSACTIONS,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Redeem To Account",
        compName: ComponentModule[ComponentModule.Redeem_To_Account],
        compId: ComponentModule.Redeem_To_Account,
        comp: new ComponentRef(RedeemToAccountComponent),
        routeLink: UserConstants.RL_REDEEM_TO_ACCOUNT,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Alert!",
        compName: ComponentModule[ComponentModule.Not_Eligible_To_Cash],
        compId: ComponentModule.Not_Eligible_To_Cash,
        comp: new ComponentRef(NotEligibleToRedeemCashComponent),
        routeLink: UserConstants.RL_NOT_ELIGIBLE_TO_CASH,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Alert!",
        compName: ComponentModule[ComponentModule.Request_To_Redeem_Cash],
        compId: ComponentModule.Request_To_Redeem_Cash,
        comp: new ComponentRef(RequestToRedeemCashComponent),
        routeLink: UserConstants.RL_REQUEST_TO_REDEEM_CASH,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Bank Details",
        compName: ComponentModule[ComponentModule.User_Bank_Account_Details],
        compId: ComponentModule.User_Bank_Account_Details,
        comp: new ComponentRef(UserBankAccountDetailsComponent),
        routeLink: UserConstants.RL_USER_BANK_ACCOUNT_DETAILS,
        compOpenType: UserConstants.POPUP,
      },

      {
        headerTitle: "Track Order Map",
        compName: ComponentModule[ComponentModule.Track_order_map],
        compId: ComponentModule.Track_order_map,
        comp: new ComponentRef(TrackOrderMapComponent),
        routeLink: UserConstants.RL_TRACK_ORDER_MAP,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Track Rider on Map",
        compName: ComponentModule[ComponentModule.Track_ride_map],
        compId: ComponentModule.Track_ride_map,
        comp: new ComponentRef(TrackRideMapComponent),
        routeLink: UserConstants.RL_RIDE_TRACK_MAP,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Track courier order",
        compName: ComponentModule[ComponentModule.track_order_courier],
        compId: ComponentModule.track_order_courier,
        comp: new ComponentRef(CourierTrackOrderComponent),
        routeLink: UserConstants.RL_COURIER_TRACK_ORDER,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "View Job Card",
        compName: ComponentModule[ComponentModule.view_job_card],
        compId: ComponentModule.view_job_card,
        comp: new ComponentRef(ViewJobCardComponent),
        routeLink: UserConstants.RL_VIEW_JOB_CARD,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "View Rate Review",
        compName: ComponentModule[ComponentModule.view_rate_review],
        compId: ComponentModule.view_rate_review,
        comp: new ComponentRef(ViewRateReviewComponent),
        routeLink: UserConstants.RL_VIEW_RATE_REVIEW,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "Service Order Map",
        compName: ComponentModule[ComponentModule.service_order_map],
        compId: ComponentModule.service_order_map,
        comp: new ComponentRef(ServiceOrderMapComponent),
        routeLink: UserConstants.RL_SERVICE_ORDER_MAP,
        compOpenType: UserConstants.POPUP,
      },

      {
        headerTitle: "Your Request",
        compName: ComponentModule[ComponentModule.surprise_me],
        compId: ComponentModule.surprise_me,
        comp: new ComponentRef(SurpriseMeComponent),
        routeLink: UserConstants.RL_SURPRISE_ME,
        compOpenType: UserConstants.POPUP,
      },
      {
        headerTitle: "You have selected this bids",
        compName: ComponentModule[ComponentModule.confrim_bids],
        compId: ComponentModule.confrim_bids,
        comp: new ComponentRef(ConfirmBidsComponent),
        routeLink: UserConstants.RL_CONFRIM_BIDS,
        compOpenType: UserConstants.POPUP,
      },
      // {
      //   headerTitle: "Surprise",
      //   compName: ComponentModule[ComponentModule.surprise],
      //   compId: ComponentModule.surprise,
      //   comp: new ComponentRef(SurpriseComponent),
      //   routeLink: UserConstants.RL_SURPRISE,
      //   compOpenType: UserConstants.POPUP,
      // },

    ];
  }

  constructor() {}
}
