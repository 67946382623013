import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { UserConstants } from 'src/app/utils/userConstant';
import { BaseService } from 'src/app/services/base.service';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-wallet-history',
  templateUrl: './view-wallet-history.component.html',
  styleUrls: ['./view-wallet-history.component.scss']
})
export class ViewWalletHistoryComponent extends Basecomponent implements OnInit {

  constructor(baseService: BaseService,
    private userStorage: UserStorageService,
    private router: Router) {
    super(baseService);
  }
  userHashId: string = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID) : localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID);
  ngOnInit() {
    super.ngOnInit();
    this.apiService.getWalltedInfoByAccountId(this.userHashId);
  }

  walletData: any = [];
  totalWalletAmount: number = 0;
  apiResultSubscribe(data: any) {
    console.log(data);
    if (data.resulttype == UserConstants.RESULT_GET_WALLET_INFO_BY_ACCOUNT_ID) {
      if (data.result.statusCode == 200) {
        this.walletData = [];
        this.totalWalletAmount = 0;
        data.result.data.forEach(element => {
          this.totalWalletAmount = element.totalBalance;
          this.walletData.push(element);
        });
      } else {
        this.setError(true, data.result);
      }
    }
    if (data.resulttype == UserConstants.RESULT_ADD_REEDEM_WALLET_CASH) {
      if (data.result.statusCode == 200) {
        this.apiService.getWalltedInfoByAccountId(this.userHashId);

      }
    }
  }
}
