import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UserConstants } from 'src/app/utils/userConstant';
import { AlertModalService } from 'src/app/pages/modal/alert-modal/alert-modal.service';

@Component({
  selector: 'app-apply-coupon',
  templateUrl: './apply-coupon.component.html',
  styleUrls: ['./apply-coupon.component.scss']
})
export class ApplyCouponComponent extends Basecomponent implements OnInit {

  constructor(baseService : BaseService,
    private alertModal : AlertModalService) {
    super(baseService);
   }

  ngOnInit(): void {
    super.ngOnInit();
    if(this.type == 'courier'){
      this.apiService.getAvailableCoupon1(this.zoneHashId,this.subServiceId);
    }else if(this.type == 'unlisted'){
      this.apiService.getAvailableCoupon1(this.zoneHashId,this.subServiceId);
    }else{
      this.apiService.getAvailableCoupon(this.zoneHashId,this.subServiceId,this.merchantId);
    }
  }
  updateDataInAddMode(){
    let data = this.getPassingData().stateData;
    console.log(data);
    this.cartValue = data.cartValue;
    this.zoneHashId = data.zoneHashId;
    this.subServiceId = data.subServiceId;
    this.merchantId = data.merchantId;
    this.userHashId = data.userHashId;
    this.type = data.type;
  }
  type:string='';
  cartValue : number=0;
  zoneHashId : string = '';
  subServiceId : string = '';
  merchantId : string = '';
  couponCode : string='';
  couponListData : any=[];
  userHashId:string;
  apiResultSubscribe(data : any){
    console.log(data);
    if(data.resulttype == UserConstants.RESULT_VALIDATE_COUPON){
      if(data.result.statusCode == 200){
        let validateCoupon = data.result.data[0];
        console.log(validateCoupon);
        if(validateCoupon.validate == 'not_applicable'){
          this.alertModal.confirm("Alert!",`${this.couponCode} is not valid coupon. Plese try different one.`);
          this.couponCode=null;
        }else if(validateCoupon.validate == 'applicable'){
          validateCoupon.couponCode = this.couponCode;
          validateCoupon.applyType = 'coupon';
          this.closePopupWithData(validateCoupon);
        }
      }else{
        this.setError(true,data.result);
      }
    }else if(data.resulttype == UserConstants.RESULT_GET_AVAILABLE_COUPON){
      if(data.result.statusCode == 200){
        this.couponListData=[];
        data.result.data.forEach(element => {
          this.couponListData.push(element);
        });
      }else{
        this.setError(true,data.result);
      }
    }
  }
  validateCouponCode(item){
    console.log(item);
    this.couponCode = item.coupon_code;
    let couponData ={
      cartValue : this.cartValue,
      zoneHashId : this.zoneHashId,
      subserviceHashId : this.subServiceId,
      merchentHashId : this.merchantId,
      couponCode : item.coupon_code,
      userHashId : this.userHashId
    }
    console.log(couponData);
    this.apiService.validateCoupon(couponData);
  }
  validateCoupon(){
    if(this.couponCode && this.couponCode.trim()){
      let couponData ={
        cartValue : this.cartValue,
        zoneHashId : this.zoneHashId,
        subserviceHashId : this.subServiceId,
        merchentHashId : this.merchantId,
        couponCode : this.couponCode,
        userHashId : this.userHashId
      }
      console.log(couponData);
      this.apiService.validateCoupon(couponData);
    }
  }


}
