import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RegisterState, State } from '../models/state';
import { UserConstants } from '../utils/userConstant';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private _count:number = 0;
  private _stateList:Array<State> = [];
  private _allRegisteredState:Array<RegisterState> = [];

  private _observer:BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _nonBehaviorObserver:Subject<any> = new Subject<any>();

  constructor() { }

  public invokeUpdatePropFunc(sd:any = undefined):void{
    // let stateobj:State = this.createState(DRTOConstants.UPDATE_PROP_VALUE_PRIORITY_1);
    // // 1st priority
    // // obj = {
    // //   type: TVUIConstants.UPDATE_PROP_VALUE_PRIORITY_1
    // // }
    // stateobj.stateData = sd;
    // this.updateState(stateobj);
    // this.updateNBState(stateobj);

    // // 2st priority
    // stateobj = this.createState(DRTOConstants.UPDATE_PROP_VALUE_PRIORITY_2)
    // // obj = {
    // //   type: TVUIConstants.UPDATE_PROP_VALUE_PRIORITY_2
    // // }
    // stateobj.stateData = sd;
    // this.updateState(stateobj);
    // this.updateNBState(stateobj);

    // // 3rd priority
    // stateobj = this.createState(DRTOConstants.UPDATE_PROP_VALUE_PRIORITY_3)
    // // obj = {
    // //   type: TVUIConstants.UPDATE_PROP_VALUE_PRIORITY_3
    // // }
    // stateobj.stateData = sd;
    // this.updateState(stateobj);
    // this.updateNBState(stateobj);

    // // last priority
    // stateobj = this.createState(DRTOConstants.UPDATE_PROP_VALUE)
    // // obj = {
    // //   type: TVUIConstants.UPDATE_PROP_VALUE
    // // }
    // stateobj.stateData = sd;
    // this.updateState(stateobj);
    // this.updateNBState(stateobj);
  }


  public createState(stateid:string|number=undefined):State{
    if(!stateid){
      stateid = this._count++;
    }else{
     let{isExists, stateobj} = this.checkDuplicateState(stateid);
     if(isExists) return stateobj;
    }
    let tempState:State = new State(stateid);
    this._stateList.push(tempState);
    return tempState;
  }

  public updateState(stateobj:State):void{
    this._observer.next(stateobj);
  }

  public updateNBState(stateobj:State):void{
    this._nonBehaviorObserver.next(stateobj);
  }

  public createNewState(stateid:string|number=undefined):State{
    return new State(stateid);
  }

  public registerState(stateObj:State):void{
    let{isExists, registerstateobj} = this.checkDuplicateRegisterState(stateObj.stateID);
    if(isExists){
      registerstateobj.states.push(stateObj);
    }else{
      let regState:RegisterState = new RegisterState();
      regState.stateID = stateObj.stateID;
      regState.states = [];
      regState.states.push(stateObj);
      this._allRegisteredState.push(regState);
    }
  }

  public clearRegisterStateAsPerId(stateid:string|number):void{
    let{isExists, indexno} = this.checkDuplicateRegisterState(stateid);
    if(isExists){
      this._allRegisteredState.splice(indexno, 1);
    }
  }

  public getRegisteredStatesAsPerId(stateid:string|number):RegisterState{
    let{registerstateobj} = this.checkDuplicateRegisterState(stateid);
    return registerstateobj;
  }

  private checkDuplicateState(checkid:string|number):any{
    for (let v of this._stateList) {
      if(v.stateID === checkid){
        return {isExists: true, stateobj:v}
      }
    }
    return {isExists: false, stateobj:undefined};
  }

  private checkDuplicateRegisterState(checkid:string|number):any{
    let indexno:number = 0;
    for (let v of this._allRegisteredState) {
      if(v.stateID === checkid){
        return {isExists: true, registerstateobj:v, indexno:indexno}
      }
      indexno++;
    }
    return {isExists: false, registerstateobj:undefined,indexno:-1};
  }

  get stateObserver():Observable<State>{
    return this._observer.asObservable();
  }

  get stateNBObserver():Observable<State>{
    return this._nonBehaviorObserver.asObservable()
  }

}
