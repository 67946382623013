import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { UserConstants } from 'src/app/utils/userConstant';

@Component({
  selector: 'app-customize',
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.scss']
})
export class CustomizeComponent extends Basecomponent implements OnInit {
  userHashId: any = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID) : localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID);
  userCoustemize: any;
  Step: number = 1;
  customizeOption = [];
  currPortionEmpty: boolean = true;
  currAddOnsEmpty: boolean = true;

  constructor(baseService: BaseService,
    private userStorage: UserStorageService,) {
    super(baseService);
  }

  ngOnInit(): void {
    this.userCoustemize = this.getPassingData().stateData;
    // if (this.userCoustemize.sub_sevice_type != undefined) {
    //   this.userCoustemize.subServiceType = this.userCoustemize.sub_sevice_type;
    // }
    // if (this.userCoustemize.service_hash_id != undefined) {
    //   this.userCoustemize.sub_service_hash = this.userCoustemize.service_hash_id;
    // }
    if (this.userCoustemize.originalItemHash != undefined) {
      this.apiService.getItemCustomizationLists(this.userCoustemize.originalItemHash);
    }
    
    console.log("data", this.userCoustemize);
    if (this.userCoustemize.itemPortionList == null && this.userCoustemize.itemAddOnsList != null) {
      this.Step = 2;
    }

  }
  apiResultSubscribe(data: any) {
    if (data.resulttype == UserConstants.RESULT_CREATE_USER_CART) {
      if (data.result.statusCode == 200) {
        this.compManager.closePopup(10);
      }
    } else if (data.resulttype == UserConstants.RESULT_GET_ITEM_CUSTOMIZATION) {
      if (data.result.statusCode == 200) {
        this.userCoustemize.itemPortionList = data.result.data[0].itemPortionList;
        this.userCoustemize.itemAddOnsList = data.result.data[0].itemAddOnsList;
        console.log("this.customize", this.userCoustemize, this.userCoustemize)
        this.userCoustemize.itemAddOnsList.map(al => {
          al.addonsOptions.map(ao => {
            if(this.userCoustemize.customizeOptions!=null){
              let copInx = this.userCoustemize.customizeOptions.findIndex((cop:any)=> cop.customization_hash_id==ao.hash_id);
              if(copInx!=-1){
                if (this.userCoustemize.editCustomization) {
                  ao.isChecked=true;
                  let y = {
                    "hash_id": ao.hash_id,
                    "item_hash_id": this.userCoustemize.invItemHashId,
                    "add_ons_hash_id": ao.add_ons_hash_id,
                    "customization_name": ao.add_on_name,
                    "customize_type": 2,
                    "extra_cost": ao.extra_cost
                  };
                  this.customizeOption.push(y);
                }else{
                  ao.isChecked=false;              
                }
              }else{
                ao.isChecked=false;              
              }
            }
          });
        });
        this.userCoustemize.itemPortionList.map(pl => {
          if(this.userCoustemize.customizeOptions!=null){
            this.userCoustemize.customizeOptions.findIndex(po => {
              if (pl.hash_id == po.customization_hash_id) {
                if (this.userCoustemize.editCustomization) {
                  pl.isChecked = true;
                  this.currPortionEmpty = false;
                  this.onSelectPortion(pl);
                } else {
                  pl.isChecked = false;
                }
              } else {
                po.isChecked = false
              }
            });
           
          }
        });

      }
    } else if (data.resulttype == UserConstants.RESULT_EDIT_CUSTOMIZABLE_FOR_MERCHANT) {
      if (data.result.statusCode == 200) {
        this.compManager.closePopup();
      }
    }
  }

  coustimezedata() {
    if (this.userCoustemize.itemAddOnsList == null) {
      // this.userCoustemize.isSelected = 1;
      if (this.userCoustemize.editCustomization == true) {
        this.onEditCustomization()
      } else {
        delete this.userCoustemize.itemPortionList;
        delete this.userCoustemize.itemAddOnsList;
        this.userCoustemize.selectedQuantity = 1;
        this.userCoustemize.customizeOptions = this.customizeOption;
        this.apiService.createUserCart(this.userCoustemize, this.userCoustemize.userHashId);
      }

    } else {
      this.Step = 2;
      // console.log('WQQQ::',this.userCoustemize.customizeOptions);
      
    }
  }
  createCart() {
    console.log(this.userCoustemize);
    this.userCoustemize.customizeOptions = this.customizeOption;
    if (this.userCoustemize.editCustomization == true) {
      this.onEditCustomization()
    } else {
      delete this.userCoustemize.itemPortionList;
      delete this.userCoustemize.itemAddOnsList;
      this.userCoustemize.selectedQuantity = 1;
      this.apiService.createUserCart(this.userCoustemize, this.userCoustemize.userHashId);
    }
  }
  onSelectPortion(item: any) {
    console.log('item12345:::::', item);
    let data = {
      "hash_id": item.hash_id,
      "item_hash_id": this.userCoustemize.invItemHashId,
      "add_ons_hash_id": null,
      "customization_name": item.portion_name,
      "customize_type": 1,
      "extra_cost": item.extra_cost
    }
    let portionIndex = this.customizeOption.findIndex(x => x.customize_type == 1);
    if (portionIndex >= 0) {
      this.customizeOption.splice(portionIndex, 1);
    }
    this.customizeOption.push(data);
    this.currPortionEmpty = false;
    console.log('COP::', this.customizeOption);
  }
  onSelectAddOns(ev: any, item: any) {
    console.log('item:::::', item);
    let data = {
      "hash_id": item.hash_id,
      "item_hash_id": this.userCoustemize.invItemHashId,
      "add_ons_hash_id": item.add_ons_hash_id,
      "customization_name": item.add_on_name,
      "customize_type": 2,
      "extra_cost": item.extra_cost
    }

    if (ev.target.checked == true) {
      this.customizeOption.push(data);
      this.currAddOnsEmpty = false;
    }
    else {
      let addOnsIndex = this.customizeOption.findIndex(x => (x.customize_type == 2 && x.hash_id == item.hash_id));
      if (addOnsIndex >= 0) {
        this.customizeOption.splice(addOnsIndex, 1);
      }
    }
    console.log('COP::', this.customizeOption);
  }
  onEditCustomization() {
    var customizeOptions = []
    this.customizeOption.forEach(ele => {
      console.log("data", ele)
      let data = {
        "hash_id": ele.hash_id,
        "customisation_type": ele.customize_type,
        "customization_add_hash_id": ele.add_ons_hash_id,
        "price": ele.extra_cost,
        "item_hash_id": ele.item_hash_id,
        "user_hash_id": this.userHashId,
        "customization_name": ele.customization_name,
      }
      customizeOptions.push(data)
    });
    if (customizeOptions.length > 0) {
      this.apiService.addEditRemoveCustomizableForMerchantOrders({
        customizeOptions: customizeOptions
      }, this.userHashId, this.userCoustemize.cartHashId, this.userCoustemize.hash_id);
    }

    console.log(this.customizeOption);
  }
}


