<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
</div>
<div class="modal-body" style="text-align: left;
font-size: 16px;
color: #373737;
padding-left: 12px;
padding-right: 12px;">
    <!-- <div class="art_img">
        <img src="{{image}}">
    </div> -->
    {{ message }}
</div>
<div class="modal-footer">
     <!-- <button type="button" class="btn btn-danger" *ngIf="btnCancelText=='Cancel'" (click)="decline()">{{ btnCancelText }}</button> --> 
    <button type="button" class="btn btn-primary" (click)="accept()" style="background: #fff;
    padding: 8px 18px 8px 18px;
    border: 1px solid #8f8f8f;
    color: #252525;">{{ btnOkText }}</button>
</div>
