import { Injectable } from '@angular/core';
import { UserAuthService } from './user-auth.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserStorageService } from './user-storage.service';
import { Observable } from 'rxjs';
import { UserConstants } from '../utils/userConstant';
import { map } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class UserAuthGuard {

  constructor(
    private userAuth: UserAuthService,
    private router: Router,
    private storage: UserStorageService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userAuth.isLoggedIn.pipe(
      map((isLogged: boolean) => {
        if (localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID) && localStorage.getItem(UserConstants.KEY_AUTH_ID)) {
          isLogged = true;
        }
        if (!isLogged) {
          this.router.navigate([`${UserConstants.RL_HOME}`]);
          return false;
        }
        return isLogged;
      })
    );
  }
}
