import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserConstants } from './utils/userConstant';
import { UserAuthGuard } from './services/user-auth-guard.service';


const routes: Routes = [
  { path: '',redirectTo: `${UserConstants.RL_HOME}` , pathMatch: 'full'},
  { path: `${UserConstants.RL_HOME}`, loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: `${UserConstants.RL_SUB_SERVICE_PAGE}`, loadChildren: () => import('./pages/sub-service-page/sub-service-page.module').then(m => m.SubServicePageModule) },
  { path: `${UserConstants.RL_SUB_SERVICE_DETAIL}`, loadChildren: () => import('./pages/service-detail/service-detail.module').then(m => m.ServiceDetailModule) },
  { path: `${UserConstants.RL_GLOBAL_SEARCH}`, loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule) },
  { path: `${UserConstants.RL_CART_CHECKOUT}`, loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_MY_ACCOUNT}`, loadChildren: () => import('./pages/my-account/my-account.module').then(m => m.MyAccountModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_ORDER_DETAILS}`, loadChildren: () => import('./pages/order-detail/order-detail.module').then(m => m.OrderDetailModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_CONTACT_US}`, loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: `${UserConstants.RL_ABOUT_US}`, loadChildren: () => import('./pages/about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: `${UserConstants.RL_PRIVACY_POLICY}`, loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: `${UserConstants.RL_NOTIFICATION}`, loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_SETTING}`, loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule) ,canActivate:[UserAuthGuard]},
  { path: `${UserConstants.RL_ORDERS_HISTORY}`, loadChildren: () => import('./pages/orders-history/orders-history.module').then(m => m.OrdersHistoryModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_TABLE_BOOKINGS}`, loadChildren: () => import('./pages/table-bookings/table-bookings.module').then(m => m.TableBookingsModule) ,canActivate:[UserAuthGuard]},
  { path: `${UserConstants.RL_LAUNDRY_DETAILS}`, loadChildren: () => import('./pages/laundry-detail/laundry-detail.module').then(m => m.LaundryDetailModule) },
  { path: `${UserConstants.RL_CAB_BOOKING}`, loadChildren: () => import('./pages/cab-booking/cab-booking.module').then(m => m.CabBookingModule) },
  { path: `${UserConstants.RL_BIKE_BOOKING}`, loadChildren: () => import('./pages/bike-booking/bike-booking.module').then(m => m.BikeBookingModule) },
  { path: `${UserConstants.RL_AUTO_BOOKING}`, loadChildren: () => import('./pages/auto-booking/auto-booking.module').then(m => m.AutoBookingModule) },
  { path: `${UserConstants.RL_TOTO_BOOKING}`, loadChildren: () => import('./pages/toto-booking/toto-booking.module').then(m => m.TotoBookingModule) },
  { path: `${UserConstants.RL_CAB_CHECKOUT}`, loadChildren: () => import('./pages/cab-checkout/cab-checkout.module').then(m => m.CabCheckoutModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_BIKE_CHECKOUT}`, loadChildren: () => import('./pages/bike-checkout/bike-checkout.module').then(m => m.BikeCheckoutModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_AUTO_CHECKOUT}`, loadChildren: () => import('./pages/auto-checkout/auto-checkout.module').then(m => m.AutoCheckoutModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_TOTO_CHECKOUT}`, loadChildren: () => import('./pages/toto-checkout/toto-checkout.module').then(m => m.TotoCheckoutModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_CAB_BOOKING_DETAIL}`, loadChildren: () => import('./pages/cab-booking-detail/cab-booking-detail.module').then(m => m.CabBookingDetailModule) ,canActivate:[UserAuthGuard]},
  { path: `${UserConstants.RL_TOTO_BOOKING_DETAIL}`, loadChildren: () => import('./pages/toto-booking-detail/toto-booking-detail.module').then(m => m.TotoBookingDetailModule) ,canActivate:[UserAuthGuard]},
  { path: `${UserConstants.RL_BIKE_BOOKING_DETAIL}`, loadChildren: () => import('./pages/bike-booking-detail/bike-booking-detail.module').then(m => m.BikeBookingDetailModule) ,canActivate:[UserAuthGuard]},
  { path: `${UserConstants.RL_AUTO_BOOKING_DETAIL}`, loadChildren: () => import('./pages/auto-booking-detail/auto-booking-detail.module').then(m => m.AutoBookingDetailModule) ,canActivate:[UserAuthGuard]},
  { path: `${UserConstants.RL_RIDE_HISTORY}`, loadChildren: () => import('./pages/ride-history/ride-history.module').then(m => m.RideHistoryModule) ,canActivate:[UserAuthGuard]},
  { path: `${UserConstants.RL_COURIER_BOOKING_CHECKOUT}`, loadChildren: () => import('./pages/courier-booking-checkout/courier-booking-checkout.module').then(m => m.CourierBookingCheckoutModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_COURIER_BOOKING}`, loadChildren: () => import('./pages/courier-booking/courier-booking.module').then(m => m.CourierBookingModule) },
  { path: `${UserConstants.RL_COURIER_BOOKING_DETAIL}`, loadChildren: () => import('./pages/courier-booking-details/courier-booking-details.module').then(m => m.CourierBookingDetailsModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_UTILITY}`, loadChildren: () => import('./pages/utility/utility.module').then(m => m.UtilityModule) },
  { path: `${UserConstants.RL_UTILITY_CHECKOUT}`, loadChildren: () => import('./pages/utility-checkout/utility-checkout.module').then(m => m.UtilityCheckoutModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_UTILITY_DETAIL}`, loadChildren: () => import('./pages/utility-detail/utility-detail.module').then(m => m.UtilityDetailModule) },
  { path: `${UserConstants.RL_UTILITY_BOOKING_DETAIL}`, loadChildren: () => import('./pages/utility-booking-detail/utility-booking-detail.module').then(m => m.UtilityBookingDetailModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_UNLISTED_SHOP}`, loadChildren: () => import('./pages/unlisted-shop/unlisted-shop.module').then(m => m.UnlistedShopModule) },
  { path: `${UserConstants.RL_UNLISTED_SHOP_BOOKING_DETAIL}`, loadChildren: () => import('./pages/unlisted-shop-booking-detail/unlisted-shop-booking-detail.module').then(m => m.UnlistedShopBookingDetailModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_UNLISTED_SHOP_CHECKOUT}`, loadChildren: () => import('./pages/unlisted-shop-checkout/unlisted-shop-checkout.module').then(m => m.UnlistedShopCheckoutModule) ,canActivate:[UserAuthGuard]},
  { path: `${UserConstants.RL_BOOKING_HISTORY}`, loadChildren: () => import('./pages/booking-history/booking-history.module').then(m => m.BookingHistoryModule),canActivate:[UserAuthGuard] },
  { path: `${UserConstants.RL_UTILITY_JOB_CARD_LIST}`, loadChildren: () => import('./pages/utility-job-card-list/utility-job-card-list.module').then(m => m.UtilityJobCardListModule) },
  { path: `${UserConstants.RL_LAUNDRY_BOOKING_DETAIL}`, loadChildren: () => import('./pages/laundry-booking-detail/laundry-booking-detail.module').then(m => m.LaundryBookingDetailModule) },
  { path: `${UserConstants.RL_SURPRISE}`, loadChildren: () => import('./pages/surprise/surprise.module').then(m => m.SurpriseModule) },
  { path: `${UserConstants.RL_MERCHANT_ORDER}`, loadChildren: () => import('./pages/merchant-order/merchant-order.module').then(m => m.MerchantOrderModule) },
  { path: `${UserConstants.RL_CAB_BIDS}`, loadChildren: () => import('./pages/cab-bids/cab-bids.module').then(m => m.CabBidsModule) },
  { path: `${UserConstants.RL_RIDE_BID}`, loadChildren: () => import('./pages/ride-bid/ride-bid.module').then(m => m.RideBidModule) },
  { path: `${UserConstants.RL_BIKE_BIDS}`, loadChildren: () => import('./pages/bike-bids/bike-bids.module').then(m => m.BikeBidsModule) },
  { path: 'bike-ride-bids', loadChildren: () => import('./pages/bike-ride-bids/bike-ride-bids.module').then(m => m.BikeRideBidsModule) },
  { path: `${UserConstants.RL_BIKE_RIDE_BIDS}`, loadChildren: () => import('./pages/bike-ride-bids/bike-ride-bids.module').then(m => m.BikeRideBidsModule) },
  { path: 'auto-bids', loadChildren: () => import('./pages/auto-bids/auto-bids.module').then(m => m.AutoBidsModule) },
  // { path: 'download-app', loadChildren: () => import('./pages/download-app/download-app.module').then(m => m.DownloadAppModule) },
  { path: `${UserConstants.RL_DOWNLOAD_APP}`, loadChildren: () => import('./pages/download-app/download-app.module').then(m => m.DownloadAppModule) },
  { path: `${UserConstants.RL_BIKE_DOWNLOAD_APP}`, loadChildren: () => import('./pages/bike-download-app/bike-download-app.module').then(m => m.BikeDownloadAppModule) },
  { path: `${UserConstants.RL_AUTO_DOWNLOAD_APP}`, loadChildren: () => import('./pages/auto-download-app/auto-download-app.module').then(m => m.AutoDownloadAppModule) },
  { path: `${UserConstants.RL_TOTO_DOWNLOAD_APP}`, loadChildren: () => import('./pages/toto-download-app/toto-download-app.module').then(m => m.TotoDownloadAppModule) },
  { path: 'toto-download-app', loadChildren: () => import('./pages/toto-download-app/toto-download-app.module').then(m => m.TotoDownloadAppModule) },
  { path: `${UserConstants.RL_SURPRISE_ME_BIDS}`, loadChildren: () => import('./pages/surprise-me-bids/surprise-me-bids.module').then(m => m.SurpriseMeBidsModule) },
  { path: `${UserConstants.RL_INVITE_OTHERS}`, loadChildren: () => import('./pages/invite-others/invite-others.module').then(m => m.InviteOthersModule) },
  { path: `${UserConstants.RL_SURPRISE_ME_REQUEST}`, loadChildren: () => import('./pages/surprise-me-request/surprise-me-request.module').then(m => m.SurpriseMeRequestModule) },
  { path: 'merchant-order-details', loadChildren: () => import('./pages/merchant-order-details/merchant-order-details.module').then(m => m.MerchantOrderDetailsModule) },
  { path: `${UserConstants.RL_MERCHANT_ORDER_DETAILS}`, loadChildren: () => import('./pages/merchant-order-details/merchant-order-details.module').then(m => m.MerchantOrderDetailsModule) },
  { path: 'ride-booking-history', loadChildren: () => import('./pages/ride-booking-history/ride-booking-history.module').then(m => m.RideBookingHistoryModule) },
  { path: `${UserConstants.RL_RIDE_BOOKING_DETAILS}`, loadChildren: () => import('./pages/ride-booking-history/ride-booking-history.module').then(m => m.RideBookingHistoryModule) },
  { path: 'terms-of-service', loadChildren: () => import('./pages/terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule) },
  { path: `${UserConstants.RL_TERMS_OF_SERVICE}`, loadChildren: () => import('./pages/terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule) },
  { path: `${UserConstants.RL_ADD_YOUR_LAUNDRY_SERVICE}`, loadChildren: () => import('./pages/add-your-laundry-service/add-your-laundry-service.module').then(m => m.AddYourLaundryServiceModule) },
  { path: `${UserConstants.RL_DRIVE_AND_EARN_LIMITLESS}`, loadChildren: () => import('./pages/drive-and-earn-limitless/drive-and-earn-limitless.module').then(m => m.DriveAndEarnLimitlessModule) },
  { path: `${UserConstants.RL_JOIN_DELIVERY_PERSONNAL}`, loadChildren: () => import('./pages/join-delivery-personnel/join-delivery-personnel.module').then(m => m.JoinDeliveryPersonnelModule) },
  { path: `${UserConstants.RL_RETURN_POLICY}`, loadChildren: () => import('./pages/return-policy/return-policy.module').then(m => m.ReturnPolicyModule) },
  { path: `${UserConstants.RL_REFUND_POLICY}`, loadChildren: () => import('./pages/refund-policy/refund-policy.module').then(m => m.RefundPolicyModule) },
  { path: `${UserConstants.RL_HELP_GUIDES}`, loadChildren: () => import('./pages/help-guides/help-guides.module').then(m => m.HelpGuidesModule) },
  // { path: 'invite-others', loadChildren: () => import('./pages/invite-others/invite-others.module').then(m => m.InviteOthersModule) },
  // { path: 'bike-download-app', loadChildren: () => import('./pages/bike-download-app/bike-download-app.module').then(m => m.BikeDownloadAppModule) },
  { path: '**', loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
