<div class="p-1">
    <div class="add_profile_drat">
        <div class="frm_fl">
            <div class="row">
                <div class="col-md-12">
                    You are not eligible to redeem, because your wallet balance is less than 500.
                </div>
            </div>
        </div>
    </div>


    <div class="add_footer">
        <div class="md-footer">
            <div class="row">
                <div class="col-sm-3 offset-9">
                    <button type="submit" (click)="close()">Ok</button>
                </div>
            </div>
        </div>
    </div>
</div>
