import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HelperFunc } from '../utils/helper-func';

@Injectable({
  providedIn: 'root'
})
export class ExceptionManagerService {

  private _exceptions:Subject<any> = new Subject<any>();
  private _showError:BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _showLoader:BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  private _excepEvent:Subject<any> = new Subject<any>();
  constructor() { }

  public validateNodeError(data:any):void{
    console.log("dataxxdk",data)
    this.setError({show:true, msg: this.checkErrorMsg(data)});
  }

  public setLoader(value:Boolean){
    this._showLoader.next(value);
  }

  public setError(value:any){
    this._showError.next(value);
  }

  public setWeeExceptionEvnt(value:any):void{
    this._excepEvent.next(value);
  }

  public removeError():void{
    this.setError({show:false, msg: ""});
  }

  private checkErrorMsg(err:any):string{
    if(typeof err === 'object'){
      if(err.message){
        return err.message;
      }
    }else if(HelperFunc.isValueDefinedByProp(err, "errorMessage")){
      return err.errorMessage;
    }

    return "Network Error";
  }

  get authException():Observable<any>{
    return this._exceptions.asObservable();
  }

  get showError():Observable<any>{
    return this._showError.asObservable();
  }

  get showLoader():Observable<Boolean>{
    return this._showLoader.asObservable();
  }

  get exceptionEvent():Observable<any>{
    return this._excepEvent.asObservable();
  }
}
