<div class="p-1">
<form [formGroup]="bankDetail" (ngSubmit)="submitBankDetail()">
    <div class="add_profile_drat">
        <div class="frm_fl">
            <div class="row">
                <div class="col-md-12">
                    <label>Bank Name
                    </label>
                    <input type="text" class="input-field border" class="form-control" name="bankName" placeholder="e.g. HDFC" formControlName="bankName" [value]="userbankdetail?.bank_name"  required autocomplete="off">
                    <!-- <strong style="color: 'red'; font-size: small">Enter Bank valid name</strong> -->
                </div>

                <div class="col-md-12">
                    <label>Account Holder Namer
                    </label>
                    <input type="text" class="input-field border" class="form-control"  placeholder="e.g. Abcd Kumar" formControlName="accountHolderName"  [value]="userbankdetail?.holder_name" required autocomplete="off">
                    <!-- <strong style="color: 'red'; font-size: small">Enter valid Acount Holder name</strong> -->
                </div>

                <div class="col-md-12">
                    <label>Branch Name
                    </label>
                    <input type="text" class="input-field border" class="form-control"  placeholder="e.g. Raniganj" formControlName="branchName" [value]="userbankdetail?.branch_name" required autocomplete="off">
                    <!-- <strong style="color: 'red'; font-size: small">Enter valid Branch name</strong> -->
                </div>
                <div class="col-md-12">
                    <label for="cars">Account Type</label>
                    <select  id="cars" class="form-select my-1" formControlName="accType">
                        <option value="0" [selected]="userbankdetail?.accType==0">Savings</option>
                        <option value="1" [selected]="userbankdetail?.accType==1">Current</option>
                    </select>
                    <!-- <strong style="color: 'red'; font-size: small">Enter valid name</strong>-->
                </div>
                <div class="col-md-12">
                    <label>Account Number
                    </label>
                    <input type="text" class="input-field border" class="form-control" placeholder="e.g. 123456789" formControlName="accountNumber"  [value]="userbankdetail?.acc_number" required autocomplete="off">
                    <!-- <strong style="color: 'red'; font-size: small">Enter valid Account number</strong> -->
                </div>
                <div class="col-md-12">
                    <label>Ifsc Code
                    </label>
                    <input type="text" class="input-field border" class="form-control" placeholder="e.g. 123456789" formControlName="ifscCode" [value]="userbankdetail?.ifsc_code" required autocomplete="off">
                    <!-- <strong style="color: 'red'; font-size: small">Enter valid Account number</strong> -->
                </div>
                <div class="col-md-12">
                    <label>Pan Card Number
                    </label>
                    <input type="text" class="input-field border" class="form-control"  placeholder="AAAAAA0000" formControlName="pancardNumber"  [value]="userbankdetail?.pan_number" required autocomplete="off">
                    <!-- <strong style="color: 'red'; font-size: small">Enter valid name</strong> -->
                </div>
            </div>
        </div>
    </div>


    <div class="add_footer">
        <div class="md-footer">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-3">
                    <button type="submit" class="closedf">Close</button>
                </div>
                <div class="col-sm-3">
                    <button type="submit">Save</button>
                </div>
            </div>
        </div>
    </div>
    </form>
</div>
