<div>
    <error-exception-comp></error-exception-comp>
    <div class="add_profile_drat">
        <div class="dratoo_lg" *ngIf="stepValue == 1">
            <div class="login_trv_dratoo">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" height="512pt" viewBox="0 0 512 512" width="512pt"><path d="m481.578125 9.238281c-3.988281-5.78125-10.558594-9.238281-17.578125-9.238281h-229.332031c-35.285157 0-64 28.714844-64 64v16.617188c.832031.75 1.789062 1.324218 2.578125 2.136718l85.335937 85.332032c12.09375 12.074218 18.75 28.160156 18.75 45.246093 0 17.089844-6.65625 33.175781-18.75 45.25l-85.335937 85.332031c-.789063.789063-1.746094 1.367188-2.578125 2.132813v37.953125c0 35.285156 28.714843 64 64 64h74.664062c8.855469 0 16.789063-5.460938 19.925781-13.738281l154.667969-405.332031c2.515625-6.550782 1.621094-13.910157-2.347656-19.691407zm0 0" fill="#5d8b57"/><path d="m119.828125 318.378906c-7.957031-3.308594-13.160156-11.09375-13.160156-19.710937v-64h-85.335938c-11.773437 0-21.332031-9.558594-21.332031-21.335938 0-11.773437 9.558594-21.332031 21.332031-21.332031h85.335938v-64c0-8.617188 5.203125-16.40625 13.160156-19.710938 7.980469-3.308593 17.152344-1.472656 23.253906 4.628907l85.332031 85.332031c8.34375 8.34375 8.34375 21.824219 0 30.164062l-85.332031 85.335938c-6.101562 6.101562-15.273437 7.933594-23.253906 4.628906zm0 0" fill="#607d8b"/><path d="m455.742188 2.113281-128.167969 42.730469c-17.300781 5.972656-28.90625 22.25-28.90625 40.488281v384c0 23.53125 19.132812 42.667969 42.664062 42.667969 4.566407 0 8.898438-.660156 13.589844-2.113281l128.171875-42.730469c17.300781-5.972656 28.90625-22.25 28.90625-40.488281v-384c0-28.097657-27.328125-49.453125-56.257812-40.554688zm0 0" fill="#1abd03"/></svg> -->
                <!-- <img src="assets/images/Screenshot.png" class="Logo" alt=""> -->
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path fill="#5d8b57" d="M481.578 9.238A21.36 21.36 0 0 0 464 0H234.668c-35.285 0-64 28.715-64 64v16.617c.832.75 1.789 1.324 2.578 2.137l85.336 85.332c12.094 12.074 18.75 28.16 18.75 45.246 0 17.09-6.656 33.176-18.75 45.25l-85.336 85.332c-.789.79-1.746 1.367-2.578 2.133V384c0 35.285 28.715 64 64 64h74.664c8.856 0 16.79-5.46 19.926-13.738L483.926 28.93c2.515-6.551 1.62-13.91-2.348-19.692zm0 0" data-original="#2196f3" class=""></path><path fill="#5d8b57" d="M119.828 318.379a21.368 21.368 0 0 1-13.16-19.711v-64H21.332C9.559 234.668 0 225.109 0 213.332 0 201.559 9.559 192 21.332 192h85.336v-64a21.364 21.364 0 0 1 13.16-19.71 21.327 21.327 0 0 1 23.254 4.628l85.332 85.332c8.344 8.344 8.344 21.824 0 30.164l-85.332 85.336a21.334 21.334 0 0 1-23.254 4.629zm0 0" data-original="#607d8b" class=""></path><path fill="#1abd03" d="m455.742 2.113-128.168 42.73c-17.3 5.973-28.906 22.25-28.906 40.489v384c0 23.531 19.133 42.668 42.664 42.668 4.566 0 8.898-.66 13.59-2.113l128.172-42.73c17.3-5.973 28.906-22.25 28.906-40.489v-384c0-28.098-27.328-49.453-56.258-40.555zm0 0" data-original="#64b5f6" class=""></path></g></svg>
            </div>
            <div class="dripple_klad">
            <h3>Welcome to Dripple</h3>
            <h5>Start with your mobile number</h5>
           </div>
            <!-- <p class="smk_tex">Lorem Ipsum is simply dummy text printing.</p> -->
            <form [formGroup]="loginForm" (ngSubmit)="onGetOtp()">
                <div class="row">
                    <div class="col-md-12">
                        <!-- <label>Enter Phone Number</label> -->
                        <div class="dfx_sec">
                            <div class="number_added_sec">
                                <i class="fa fa-phone" aria-hidden="true"></i>
                                <p>+91</p>
                            </div>
                            <input type="text" id="business_name" class="form-control" placeholder="Mobile number" autocomplete="off" formControlName="phone">
                        </div>
                        <strong *ngIf="loginForm.get('phone').invalid && loginForm.get('phone').touched" style="color: 'red'; font-size: small;padding-left: 2px;">Enter valid phone number</strong>
                        <div *ngIf="isOtpCame">
                            <div class="mat_ta_ot" (click)="onLoginChange()">
                                <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                            </div>
                        </div>
                    </div>

                    <div class="flk_opr" *ngIf="!isOtpCame">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="dra_login_ot  d-none d-lg-block">
                                    <!-- <button type="submit" [disabled]="loginForm.invalid" id="get-otp-btn"> Get OTP</button> -->
                                    <button type="submit" disabled id="get-otp-btn"> Get OTP</button>
                                </div>
                                <div class="d-block d-lg-none"><button disabled class="btn green-btn dcvgrt">Get OTP</button></div>
                                <div class="d-block d-lg-none">
                                    <p class="lgfnect_uyt">Web access from mobile and tablets now restricted. Apps are coming soon in stores. Login from your laptop or desktop.</p>
                                </div>
                                <div class="d-block d-lg-none">
                                    <div class="dwn_lad_icon">
                                        <div class="gle_icm"><img src="assets/images/tpr48fa.png" style="width: 112px;"></div>
                                        <div class="gle_icm"><img src="assets/images/icon-android-app.png"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12" *ngIf="!isOtpCame">
                        <div class="trm_cnm">
                            <p>By clicking Next, you agree to our
                                <a href="javascript:void(0)" target="_blank" [routerLink]="['/termsofservice']">Terms & conditions,</a> and
                                <a href="javascript:void(0)" target="_blank" [routerLink]="['/privacyPolicy']">Privacy policies</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="frm_fl " *ngIf="stepValue==2 ">
            <label> Sign Up</label>
            <form [formGroup]="loginForm " (ngSubmit)="onSignUp() ">
                <div class="row ">
                    <div class="col-md-12 ">
                        <label>Enter Phone Number</label>
                        <input type="text " id="business_name " class="form-control " placeholder="Mobile number " autocomplete="off " formControlName="phone ">
                        <strong *ngIf="loginForm.get( 'phone').invalid && loginForm.get( 'phone').touched " style="color: 'red'; font-size: small ">Enter valid phone number</strong>
                    </div>
                    <div *ngIf="isOtpCame ">
                        <div class="row ">
                            <div class="col-sm-12 ">
                                <div class="dra_login_ot pcmkl ">
                                    <button (click)="onLoginChange() "> <i class="fa fa-pencil " aria-hidden="true "></i>
                                      Change Number</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row " *ngIf="!isOtpCame ">
                        <div class="col-sm-12 ">
                            <div class="dra_login_ot ">
                                <button type="submit " [disabled]="loginForm.invalid " id="signup-btn ">SignUp & Get OTP</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="row" *ngIf="isOtpCame">
            <div class="verification_code mlmd ">
                <h3>Verification Code</h3>
                <ng-otp-input (onInputChange)="onOtpChange($event) " [config]="config " autocomplete="off " #ngOtpInput></ng-otp-input>
            </div>
            <p class="slpm_rft wvbnd">We have sent a verification code as an SMS in your mobile number specified above.</p>
            <div (click)="resendCode() " style="text-align: center;    padding-top: 12px; ">
              <span class="no_sklad">  No SMS?</span>  <a href="javascript:void(0) " style=" color: #5d8b57; text-decoration: none;"> Resend verification code</a>
            </div>
            <!-- <span id="timer ">60</span> -->
            <!-- <div (click)="resendCode() " style="text-align: center; ">
                <a href="javascript:void(0) " style=" color: #5d8b57; text-decoration: none;">Resend verification code</a>
            </div> -->
            <div class="dra_login_ot">
                <button type="submit" (click)="onSubmit()" [disabled]="otp.length !=4" id="confirm-otp-btn ">Confirm and go</button>
            </div>
           
        </div>
    </div>


    <!-- <div class="add_footer ">
    <div class="md-footer ">
        <div class="row " *ngIf="stepValue==1 ">
            <p>Don't have an account?</p>
            <a href="javascript:void(0) " (click)="changeStep(2) ">Sign Up</a>
        </div>
        <div class="row " *ngIf="stepValue==2 ">
            <p>Already have Account</p>
            <a href="javascript:void(0) " (click)="changeStep(1) ">Login</a>
        </div>
    </div>
</div> -->

</div>
