<div class="surprise_sjk">
    <div class="list_detail">
        <div class="row">
            <div class="col-sm-2">
                <div class="sur_ps_image">
                    <!-- <img src="https://profiledoc.s3.ap-south-1.amazonaws.com/public/dev/image/inventory_picture_1623058831644.png"> -->
                    <img [src]="aws3+confirmOrder.item_image">
                </div>
            </div>
            <div class="col-sm-10">
                <div class="sur_me_odr">
                    <h5>{{ confirmOrder.businessName}}
                    </h5>
                    <p class="confm_text">{{confirmOrder.item_name}}
                    </p>
                    <div class="item_count">
                        <P>Offered bids: Rs.{{ confirmOrder.price }}</P>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="add_footer">
    <div class="md-footer">
        <div class="row">
            <div class="col-sm-6">
                <button type="submit" class="cenl_btn" data-dismiss="modal" (click)="closeModel()"> Cancel </button>
            </div>
            <div class="col-sm-6">
                <button type="button" (click)="goToCheckout()" data-dismiss="modal">Place order</button>
            </div>
        </div>
    </div>
</div>
