import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataEventService {
  private dEvent:EventEmitter<any> = new EventEmitter();
  private dObs = new Subject<any>();
  private dBObs:BehaviorSubject<any> = new BehaviorSubject<any>(null);

  publishBEvnt(data:any):void{
    this.dBObs.next(data);
  }

  publishEvt(data:any):void{
    this.dObs.next(data);
  }

  get dataBObserver():Observable<any>{
    return this.dBObs.asObservable();
  }


  get dataObserver():Observable<any>{
    return this.dObs.asObservable();
  }


  get dataEvent():EventEmitter<any>{
   return this.dEvent;
  }

  emit(value?:any){
    this.dEvent.emit(value);
  }

}
