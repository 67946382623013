import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserStorageService {

  private _valueList:Map<string, string> = new Map();
  constructor() { }

  public setValueInStorage(key:string, val:string):void{
    this._valueList.set(key, val);
  }

  public getValueFromStorage(key:string):string{
    return this._valueList.get(key);
  }
  public removeKey(key : string):void{
    this._valueList.delete(key);
  }
  public clearStorage():void{
    this._valueList.clear();
  }
}
