<div class="wallet_history">
    <div class="ad_ox">
        <div class="cot_head">
            <div class="wel_des_modal">
                <p style="margin-bottom: 0px;
    font-size: 22px;
    color: #393939;
    text-align: left;
    font-weight: bold;">Total balance: <span>₹{{totalWalletAmount}}</span></p>
            </div>
            <table *ngIf="walletData.length != 0">
                <tr>
                    <th>Date</th>
                    <th>Amount </th>
                    <th>Status</th>
                    <th>Order Id</th>
                </tr>
                <tr *ngFor="let item of walletData;let i=index;">
                    <td>{{item.creation_time | date: "MMM d, y, h:mm a"}}</td>
                    <td *ngIf="item.type == 'credit'">₹{{item.credit_amount}}</td>
                    <td *ngIf="item.type == 'debit'">₹{{item.debit_amount}}</td>
                    <td *ngIf="item.type == 'credit'">Deposit</td>
                    <td *ngIf="item.type == 'debit'">Used</td>
                    <td *ngIf="item.order_code && item.order_code != 'null' && item.note == 'order_place'">{{'For Order Id: ' + item.order_code}}</td>
                    <td *ngIf="item.order_code && item.order_code != 'null' && item.note == 'scratch_card'">{{'For Order Id: ' + item.order_code}}</td>
                    <td *ngIf="item.note != 'order_place' && item.note != 'scratch_card'">{{item.note && item.note != 'null' ? item.note : 'NA'}}</td>
                </tr>

            </table>
            <div *ngIf="walletData.length == 0">
                <div>
                    <div class="pce_hml_text">
                        <div class="gtr_plc">
                            <div class="plt_hlr">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve"
                                    class=""><g><g xmlns="http://www.w3.org/2000/svg"><path d="m64.797 0v63.734l31.867 31.868-31.867 31.867v63.734l31.867 31.868-31.867 31.867v257.062h191.203v-512z" fill="#fff3cc" data-original="#fff3cc" style="" class=""></path><path d="m447.203 0h-191.203v512h191.203v-95.602l-31.867-31.867 31.867-31.867z" fill="#ffe487" data-original="#ffe487" style=""></path><path d="m256 72.168-24.567 48.863 24.567 48.863c26.986 0 48.863-21.877 48.863-48.863s-21.877-48.863-48.863-48.863z" fill="#cb2e81" data-original="#cb2e81" style=""></path><path d="m207.137 121.031c0 26.986 21.877 48.863 48.863 48.863v-97.726c-26.986 0-48.863 21.877-48.863 48.863z" fill="#ff3187" data-original="#ff3187" style=""></path><path d="m367.535 376.098v-30h-111.535l-30 15 30 15z" fill="#2d3e53" data-original="#2d3e53" style=""></path><path d="m144.465 346.098h111.535v30h-111.535z" fill="#435d7a" data-original="#435d7a" style=""></path><path d="m367.535 439.832v-30h-111.535l-30 15 30 15z" fill="#2d3e53" data-original="#2d3e53" style=""></path><path d="m144.465 409.832h111.535v30h-111.535z" fill="#435d7a" data-original="#435d7a" style=""></path><path d="m367.535 218.629h-111.535l-30 15 30 15h111.535z" fill="#2d3e53" data-original="#2d3e53" style=""></path><path d="m367.535 312.363v-30h-111.535l-30 15 30 15z" fill="#2d3e53" data-original="#2d3e53" style=""></path><g fill="#435d7a"><path d="m144.465 218.629h111.535v30h-111.535z" fill="#435d7a" data-original="#435d7a" style=""></path><path d="m144.465 282.363h111.535v30h-111.535z" fill="#435d7a" data-original="#435d7a" style=""></path></g></g></g></svg>
                                <div class="rgt_ted">
                                    <h3>No transaction yet
                                    </h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
