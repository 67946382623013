import { Injectable } from '@angular/core';
import { Auth, Storage } from 'aws-amplify';
import { environment } from 'src/environments/environment';
const dev_urls:any = require("../../config-data/dev-url.json");
@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor() { 
     Auth.configure( {
    identityPoolId: `${dev_urls.identityPoolId}`,
    region : 'ap-south-1'
} );
}


date:any=new Date();

  uploadToS3Bucket(bucketName : string,keyPath : string,imageFor: string,data):Promise<any>{
    return new Promise((resolve,reject)=>{
      let date :any=new Date();
      let imageToUpload = this.getBlob( data );
      let pathKey;
      if(environment.production){
        pathKey = keyPath;
      }else{
        pathKey = 'dev/'+keyPath;
      }
      console.log(bucketName,keyPath,imageFor,data);
      Storage.configure( {
        AWSS3: {
            bucket: bucketName,
            region : 'ap-south-1'
        }
    } );
    let image_name = imageFor + '_' + date.getTime() + '.png';
    console.log(image_name);
    Storage.put(pathKey+'/'+image_name,imageToUpload,{
      progressCallback( progress ) {
        console.log("Progress: ",progress);
      },
      contentType: 'image/png',
  } ).then(result=>{
    console.log("Storage Response: ",result);
    resolve(result);
  }).catch(error=>{
    console.log("Storage Error: ",error);
    reject(error);
  })
    })
  }

  uploadToS3BucketTypeWise(bucketName : string,keyPath : string,imageFor : string,data,name,type):Promise<any>{
    return new Promise((resolve,reject)=>{
      let date :any=new Date();
      let pathKey;
      if(environment.production){
        pathKey = keyPath;
      }else{
        pathKey = 'dev/'+keyPath;
      }
      console.log(bucketName,keyPath,imageFor,data,name,type);
      Storage.configure( {
        AWSS3: {
            bucket: bucketName,//Your bucket name;
            region : 'ap-south-1'
        }
    } );

    let image_name = imageFor + '_' + date.getTime() + name;
    console.log(image_name);
    Storage.put(pathKey+'/'+image_name,data,{
      progressCallback( progress ) {
        console.log("Progress: ",progress);
      },
      contentType: type,
  } ).then(result=>{
    console.log("Storage Response: ",result);
    resolve(result);
  }).catch(error=>{
    console.log("Storage Error: ",error);
    reject(error);
  })
    })
  }

  getBlob( b64Data ) {
    let contentType = 'image/png';
    let sliceSize = 512;

    b64Data = b64Data.replace( /data\:image\/(jpeg|jpg|png)\;base64\,/gi, '' );

    let byteCharacters = atob( b64Data ); //decode base64
    let byteArrays = [];

    for ( let offset = 0; offset < byteCharacters.length; offset += sliceSize )
    {
        let slice = byteCharacters.slice( offset, offset + sliceSize );

        let byteNumbers = new Array( slice.length );
        for ( let i = 0; i < slice.length; i++ )
        {
            byteNumbers[ i ] = slice.charCodeAt( i );
        }

        let byteArray = new Uint8Array( byteNumbers );
        byteArrays.push( byteArray );
    }

    let blob = new Blob( byteArrays, { type: contentType } );
    return blob;
}
}
