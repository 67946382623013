import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-not-eligible-to-redeem-cash',
  templateUrl: './not-eligible-to-redeem-cash.component.html',
  styleUrls: ['./not-eligible-to-redeem-cash.component.scss']
})
export class NotEligibleToRedeemCashComponent  extends Basecomponent implements OnInit {

  constructor(baseService : BaseService,) {
    super(baseService)
  }

  ngOnInit(): void {
  }
  close(){
    this.compManager.closePopup()
  }
}
