import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
// import  firebase  from 'firebase/app';
// import 'firebase/messaging';
import { BehaviorSubject, Notification, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserConstants } from '../utils/userConstant';
@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging) {

  }

  requestPermission() {
    this.angularFireMessaging.requestPermission.subscribe((res) => {
      console.log(res);
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          console.log(token);
          localStorage.setItem(UserConstants.KEY_FCM_TOKEN,token);
        },
        (err) => {
          console.error('get token error', err);
        }
      );
    },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }

    );

  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload:any) => {
        console.log('new message received. ', payload);
          this.currentMessage.next(payload);
      });

    }
    // createNotification(title, text) {
    //   const noti = new Notification(title, {
    //     body: text,
    //   });
    // }

}
