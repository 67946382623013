import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalRef,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { ComponentInfo } from "../models/compinfo";
import { BaseService } from "../services/base.service";
import { UserConstants } from "./userConstant";
import { GenericPopupOption } from "../models/common.model";

@Component({
  selector: "generic-context",
  template: `
    <style>
      .inputWidth {
        width: 100%;
      }
      .close {
        outline: none !important;
      }
    </style>
    <div class="modal-header custom_dra" *ngIf="loginModelOpenChecker != 'true'">
      <h4 class="modal-title">
        {{
          supportinParam?.headerTitle ||
            compinfo?.headerTitle ||
            compinfo?.compName
        }}
      </h4>
      <button
        type="button"
        class="close "
        aria-label="Close"
        (click)="checkStateAndClose()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body custom_dra1">
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="checkStateAndClose()"
      *ngIf="loginModelOpenChecker == 'true'">
        <span aria-hidden="true" style="
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 33px;
">&times;</span>
      </button>
      <ng-template [zapCompMap]="compinfo"></ng-template>
    </div>

    <!--div class="modal-footer">
        <button type="button" class="btn btn-outline-dark"
        (click)="checkStateAndClose()">Close</button>
      </div-->
  `,
})
export class GenericModalContent implements OnDestroy, OnInit {
  @Input() compinfo: ComponentInfo;
  @Input() set closed(value: boolean) {
    if (value) {
      this.activeModal.close();
    }
  }

  @Input() supportinParam: any;

  private _subscription: Subscription;
  private _stateSubscription: Subscription;
  private mode: string;
  public loginModelOpenChecker:any
  constructor(
    private activeModal: NgbActiveModal,
    private baseServices: BaseService
  ) {
    this._subscription = this.baseServices.compManager.dataObserver.subscribe(
      (data) => {
        if (
          data &&
          (data.type === UserConstants.CLOSE_POPUP ||
            data.type === UserConstants.POPUP_CLOSED)
        ) {
          this.checkStateAndClose();
        }
      }
    );
  }

  ngOnInit(): void {
      console.log("hey");
      console.log(localStorage.getItem('isLoginModelOpen'));
      this.loginModelOpenChecker = localStorage.getItem('isLoginModelOpen')
  }

  checkStateAndClose(): void {
    this.activeModal.close();
    localStorage.setItem('isLoginModelOpen', 'false')
    // this.baseServices.compManager.notifyPopupClosed(this.supportinParam);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}

@Component({
  selector: "generic-popup",
  template: ``,
})
export class GenericPopup implements OnInit, OnDestroy {
  @Input() modalOption: NgbModalOptions;
  @Input() level: number;

  private _subscription: Subscription;
  private modalRef: NgbModalRef;
  // public loginModelOpenChecker:any
  constructor(
    private modalService: NgbModal,
    private baseServices: BaseService
  ) {
    this._subscription = this.baseServices.compManager.dataObserver.subscribe(
      (data) => {
        if (data.type === UserConstants.POPUP && data.level === this.level) {
          this.baseServices.weeManager.removeError();
          this.open(data.value, data.supportingParams);
        }
      }
    );
  }

  ngOnInit() {
    
    
   
  }

  private open(compinfo: ComponentInfo, supportinParam?: any): void {
    console.log(supportinParam, "Generic model");
    // this.loginModelOpenChecker = localStorage.getItem('isLoginModelOpen')
    if (!this.modalOption) {
      this.modalOption = new GenericPopupOption();
      this.modalOption.size = "lg";
    }
    this.modalRef = this.modalService.open(GenericModalContent, {
      size: (supportinParam.modalSize && supportinParam.modalSize != 'null') ? supportinParam.modalSize : "lg",
      backdrop: "static",
      centered: true,
    });
    this.modalRef.componentInstance.compinfo = compinfo;
    this.modalRef.componentInstance.supportinParam = supportinParam;
  }

  public openPopup(
    compinfo: ComponentInfo,
    supportinParam?: any,
    level: number = 1
  ): void {
    if (level === this.level) this.open(compinfo, supportinParam);
  }

  public closePopup(level: number = 1): void {
    if (level === this.level) this.modalRef.componentInstance.closed = true;
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
