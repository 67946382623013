import { Directive, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Mode } from "../enums/mode.enum";
import { WarningMessage } from "../models/common.model";
import { State } from "../models/state";
import { ApiService } from "../services/api.service";
import { BaseService } from "../services/base.service";
import { CompLoadManagerService } from "../services/comp-manager.service";
import { DataEventService } from "../services/data-event.service";
import { ExceptionManagerService } from "../services/exception-manager.service";
import { StateService } from "../services/state.service";
import { HelperFunc } from "../utils/helper-func";
import { UserConstants } from "../utils/userConstant";

@Directive()
export abstract class Basecomponent implements OnInit, OnDestroy {
  protected compManager: CompLoadManagerService;
  protected stateManager: StateService;
  protected exceptionManager: ExceptionManagerService;
  protected dataEvent: DataEventService;
  protected apiService: ApiService;
  protected modeState: State;
  protected currentResultType: String;
  protected compDataObj: any;
  protected passingDataState: State;
  protected windowState: State;
  protected warningMsg: WarningMessage;

  private compManagerSubscription: Subscription;
  private stateSubscription: Subscription;
  private stateNBSubscription: Subscription;
  private exceptEventSubscription: Subscription;
  private apiSubscription: Subscription;

  private _backLink: string;
  private _docLink ;

  constructor(baseServices: BaseService) {
    this.compManager = baseServices.compManager;
    this.stateManager = baseServices.stateService;
    this.exceptionManager = baseServices.weeManager;
    this.dataEvent = baseServices.dataEvent;
    this.apiService = baseServices.apiService;

    this.warningMsg = new WarningMessage();

    this.modeState = this.stateManager.createState(UserConstants.STATE_MODE);
    this.passingDataState = this.stateManager.createState(
      UserConstants.STATE_PASSING_DATA
    );
    this.windowState = this.stateManager.createState(
      UserConstants.STATE_FROM_WINDOW_TRACK
    );

    this.compManagerSubscription = this.compManager.dataObserver.subscribe(
      (data) => {
        if (data.type === UserConstants.CLOSE_POPUP) {
          this.popupCloseHandler();
        }
        if (data.type === UserConstants.POPUP_CLOSED) {
          this.popupWithDataCloseHandler(data);
        }
        this.loadManagerSubscribe(data);
      }
    );

    this.stateSubscription = this.stateManager.stateObserver.subscribe(
      (data) => {
        if (data) this.stateSubscribe(data);
      }
    );

    this.stateNBSubscription = this.stateManager.stateNBObserver.subscribe(
      (data) => {
        if (data) this.stateNBSubscribe(data);
      }
    );

    this.exceptEventSubscription = this.exceptionManager.exceptionEvent.subscribe(
      (data) => {
        if (data) {
          this.exceptEventSubscribe(data);
        }
      }
    );

    this.apiSubscription = this.apiService.apiResults.subscribe((data) => {
      if (data) {
        this.apiResultSubscribe(data);
      }
    });
  }

  ngOnInit() {
    this.checkModeNUpdate();
    this.setError(false);
  }

  protected popupCloseHandler(): void {}

  protected popupWithDataCloseHandler(data: any): void {}

  protected loadManagerSubscribe(data: any): void {}

  protected stateSubscribe(data: State): void {}

  protected stateNBSubscribe(data: State): void {}

  protected eventSubscribe(data: any): void {}

  protected exceptEventSubscribe(data: any): void {}

  protected apiResultSubscribe(data: any): void {
    if (data.resulttype === this.currentResultType) {
      this.dataConvertAfterApiHandler(data);
    }
  }

  protected updateDataInEditMode(): void {}

  protected updateDataInAddMode(): void {}

  protected updateDataInUpdateMode(): void {}

  protected redirect(
    routestring: string,
    supportingParams?: any,
    complevel: number = 1
  ): void {
    if(routestring == UserConstants.RL_LOGIN){
      localStorage.setItem('isLoginModelOpen', 'true')
    }
    this.compManager.redirect(routestring, supportingParams, complevel);
  }

  public closePopup(): void {
    this.compManager.closePopup();
  }

  protected closePopupWithData(param: any): void {
    this.compManager.notifyPopupClosed(param);
  }

  protected setError(
    showError: boolean,
    message: string = "",
    entireErrorObj: any = undefined
  ): void {
    if (!HelperFunc.isUndefined(entireErrorObj)) {
      this.exceptionManager.setError(entireErrorObj);
    } else {
      this.exceptionManager.setError({ show: showError, msg: message });
    }
  }

  protected validateAllFields(): boolean {
    return true;
  }

  public saveHandler(showValidationErr: boolean = false): void {
    let isvalid: boolean = this.validateAllFields();
    if (isvalid) {
      this.dataConvertBeforApiHandler();
      this.invokeApiHandler();
    }

    if (showValidationErr && !isvalid) {
      this.validationErrorHandler();
    }
  }

  protected validationErrorHandler(): void {}

  protected invokeApiHandler(): void {}

  protected dataConvertBeforApiHandler(): void {
    if (!HelperFunc.isUndefined(this.compDataObj))
      HelperFunc.deleteUnusedTag(this.compDataObj);
  }

  protected dataConvertAfterApiHandler(data: any): void {}

  protected setPassingData(srcObj: any): void {
    this.passingDataState.stateData = HelperFunc.deepCopyNObj(srcObj);
  }

  protected getPassingData(): State {
    return this.passingDataState;
  }

  protected changeMode(modeid: Mode): void {
    this.modeState.currentstate = Mode[modeid];
  }

  private checkModeNUpdate(): void {
    if (
      this.modeState.currentstate === undefined ||
      this.modeState.currentstate === Mode[Mode.Add]
    ) {
      this.modeState.currentstate = Mode[Mode.Add];
      this.updateDataInAddMode();
    } else if (this.modeState.currentstate === Mode[Mode.Edit]) {
      this.updateDataInEditMode();
    } else if (this.modeState.currentstate === Mode[Mode.Update]) {
      this.updateDataInUpdateMode();
    }
  }

  set backLink(routeLink: string) {
    this._backLink = routeLink;
  }

  get backLink() {
    return this._backLink;
  }

  get doclink() {
    return this._docLink;
  }

  ngOnDestroy() {
    this.compManagerSubscription.unsubscribe();
    this.stateSubscription.unsubscribe();
    this.stateNBSubscription.unsubscribe();
    this.exceptEventSubscription.unsubscribe();
    this.apiSubscription.unsubscribe();
  }
}
