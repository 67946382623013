import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { UserConstants } from 'src/app/utils/userConstant';

@Component({
  selector: 'app-scratch-card',
  templateUrl: './scratch-card.component.html',
  styleUrls: ['./scratch-card.component.scss']
})
export class ScratchCardComponent extends Basecomponent implements OnInit {

  constructor(baseService : BaseService,private userStorage : UserStorageService) {
    super(baseService);
   
   }
   userHashId : string = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID) : localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID);
   orderHashId:string;
  ngOnInit(): void {
    super.ngOnInit();
    let data = this.getPassingData().stateData;
    this.orderHashId = data.orderHashId; 
    this.apiService.getScratchCardProvidedToUser(this.userHashId,this.orderHashId);
  }
  isScratchRedeem:boolean=false;
  scratchCardData:any={};
  apiResultSubscribe(data : any){
    console.log(data);
    if(data.resulttype == UserConstants.RESULT_GET_SCRATCH_CARD_PROVIDED_TO_USER){
      this.scratchCardData={};
      this.isScratchRedeem=false;
      if(data.result.statusCode == 200){
        let scratch :any = data.result.data[0];
        if(scratch.is_redeemed == 1){
          this.isScratchRedeem = true;
        }else if(scratch.is_redeemed == 0){
          this.isScratchRedeem = false;
        }
        this.scratchCardData=scratch;
      }else{
        this.setError(true,data.result);
      }
    }else if(data.resulttype == UserConstants.RESULT_REDEEMED_SCRATCH_CARD){
      if(data.result.statusCode == 200){
        this.apiService.getScratchCardProvidedToUser(this.userHashId,this.orderHashId);
      }else{
        this.setError(true,data.result);
      }
    }
  }

  revealScratchCard(){
    let data = {
      hashId : this.scratchCardData.hash_id,
      userHashId : this.userHashId
    }
    this.apiService.redeemedScratchCard(this.scratchCardData.hash_id,this.userHashId);
  }

}
