import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

export class AlertCode {
  acode: number;
  constructor() {}
}

export class WEEMessage {
  wMessage: string;
  showBox: boolean;
  weeType: WEEType;
  supportParams?: any;

  constructor() {}
}

export class WarningMessage extends WEEMessage {
  constructor() {
    super();
    this.weeType = WEEType.Warning;
  }
}

export class ErrorMessage extends WEEMessage {
  constructor() {
    super();
    this.weeType = WEEType.Error;
  }
}

export enum WEEType {
  Warning,
  Error,
  Exception,
  WarnConfirmation,
  Success,
}

export class UserData {
  private _name: string;
  private _password: string;
  private _id: string;

  set name(value: string) {
    this._name = value;
  }

  get name() {
    return this._name;
  }

  set password(value: string) {
    this._password = value;
  }

  get password() {
    return this._password;
  }
  set id(value: string) {
    this._id = value;
  }

  get id() {
    return this._id;
  }
}


export class userDataModel{
  name : string;
  address : AddressModel;
  mobile : string;
  userId:string;
  password : string;
  description:string;
  email : string;
}

export class adminUserModel{
  name : string;
  address : AddressModel;
  mobile : string;
  email? :string;
}

export class AddressModel {
  addressLine: string;
  city?:string;
  state?:string;
  country?:string;
  zip?:string;
  lat?: string;
  lng?: string;

  constructor() {}
}



export class OptionType {
  command: any;
  title: string;
}


export class GenericPopupOption implements NgbModalOptions {
  constructor() {}
}
