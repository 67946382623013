export enum ComponentModule {
  LOGIN,
  SELECT_LOCATION,
  EDIT_PROFILE,
  APPLY_COUPON,
  Add_Profile_Picture,
  Add_Business_Picture,
  Add_delivery_address,
  Paytm_payment,
  Book_table,
  Select_delivery_location,
  Track_order,
  Add_pharmacy_document,
  scratch_card,
  View_Wallet_History,
  Bank_Details,
  Transaction,
  Redeem_To_Account,
  Not_Eligible_To_Cash,
  Request_To_Redeem_Cash,
  User_Bank_Account_Details,
  Track_order_map,
  Track_ride_map,
  track_order_courier,
  view_job_card,
  view_rate_review,
  service_order_map,
  surprise_me,
  surprise,
  confrim_bids,
  Customize

}
