import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UserConstants } from 'src/app/utils/userConstant';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { AlertModalService } from 'src/app/pages/modal/alert-modal/alert-modal.service';

@Component({
  selector: 'app-book-table',
  templateUrl: './book-table.component.html',
  styleUrls: ['./book-table.component.scss']
})
export class BookTableComponent extends Basecomponent implements OnInit {
  constructor(baseService : BaseService,
    private userStorage : UserStorageService,
    private alertModal : AlertModalService) {
    super(baseService);
    
   }

  ngOnInit(): void {
    super.ngOnInit();
  }
  serviceId : string;
  updateDataInAddMode(){
    let data = this.getPassingData().stateData;
    this.serviceId = data.serviceId;
  }
  time :any;
  meridian = true;
  model: NgbDateStruct;
  date: {year: number, month: number};
  noOfPerson:number = 1;
  note : string;
  apiResultSubscribe(data : any){
    console.log(data);
    if(data.resulttype == UserConstants.RESULT_BOOK_RESTAURANT_TABLE){
      if(data.result.statusCode == 200){
        this.closePopupWithData(true);
        this.alertModal.confirm("Alert!","Table Booking done. Wait for the confirmation.");
      }else{
        this.setError(true,data.result);
      }
    }
  }
  onKeyPress(evt) {
    return this.isNumberKey(evt);
}
private isNumberKey(evt) {
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  return true;
}
plus(){
this.noOfPerson += 1;
}
minus(){
  if(this.noOfPerson > 1){
    this.noOfPerson -= 1;
  }
}
accountHashId = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID) : localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID);
  saveData(){
    console.log(this.time,this.model,this.noOfPerson);
    if(!this.time || this.time == 'null'){
      alert("Please set valid time.");
      return;
    }
    if(!this.model){
      alert("Please set valid date.");
      return;
    }
    if(!this.model.day){
      alert("Please set valid date.");
      return;
    }
    if(this.noOfPerson == 0){
      alert("Please set valid no of person.");
      return;
    }
    this.saveHandler(true);
  }

  invokeApiHandler(){
    console.log(this.time,this.model);
    let time :any=Object.assign({},this.time);
    let date:any=Object.assign({},this.model);
    if(time.hour < 10){
      time.hour = '0'+ time.hour;
    }
    if(time.minute < 10){
      time.minute = '0'+ time.minute;
    }
    if(date.month < 10){
      date.month = '0' + date.month;
    }
    if(date.day < 10){
      date.day = '0' + date.day;
    }
    let booktableData = {
      book_date:date.year+'-'+date.month+'-'+date.day,
      book_time:time.hour+':'+time.minute,
      number_of_person  :this.noOfPerson,      
      user_hash_id:this.accountHashId,
      business_hash_id:this.serviceId,
      note : (this.note && this.note != 'null') ? this.note : null
    }
    console.log(booktableData);
    this.apiService.bookRestaurantTable(booktableData);
  }

  
}
