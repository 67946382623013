import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-request-to-redeem-cash',
  templateUrl: './request-to-redeem-cash.component.html',
  styleUrls: ['./request-to-redeem-cash.component.scss']
})
export class RequestToRedeemCashComponent extends Basecomponent implements OnInit {

  constructor(baseService : BaseService) {
    super(baseService)
  }

  ngOnInit(): void {
  }
  close(){
    this.compManager.closePopup();
  }
}
