import { Component } from '@angular/core';
import { CommonComponents } from './data/common-components';
import { BaseService } from './services/base.service';
import { UrlConverterService } from './services/urlconverter.service';
import { NavigationEnd, Router } from '@angular/router';
import { MessagingService } from './services/messaging.service';
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dratoo-user-web';
  isConnected = true;
  noInternetConnection: boolean;
  status : any = "ONLINE";
  private _componentsdata:CommonComponents = new CommonComponents();
  constructor(private _urlConverter:UrlConverterService,private baseService:BaseService,
    public router : Router,
    public messagingService : MessagingService){
      this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd){
            gtag('config', 'G-VENN22NZL9',
                  {
                    'page_path': event.urlAfterRedirects
                  }
                 );
         }

        });
        this.messagingService.requestPermission();
      this.messagingService.receiveMessage();
    // this.connectionService.monitor().subscribe(res=>{
    //   console.log(res);
    //   this.isConnected = res;
    //   if (this.isConnected) {
    //       this.noInternetConnection=false;
    //       this.status = "ONLINE";
    //     }else {
    //     this.noInternetConnection=true;
    //     this.status = "OFFLINE"
    //     }
    //     // alert(this.status);
    // },err=>{
    //   console.log(err);
    // });
    document.addEventListener('DOMContentLoaded', function() {
      if (!Notification) {
       alert('Desktop notifications not available in your browser. Try Chromium.');
       return;
      }

      if (Notification.permission !== 'granted')
       Notification.requestPermission();
     });
  }
  ngOnInit(){
    this.baseService.compManager.compList = this._componentsdata.getCompList();
    if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
      this._urlConverter.initialize(false);
    }
    this._urlConverter.initialize(true);
  }
  createNotification(title, text) {
    const noti = new Notification(title, {
      body: text,
    });
  }
}
