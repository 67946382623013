import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserConstants } from 'src/app/utils/userConstant';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UserStorageService } from 'src/app/services/user-storage.service';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss']
})
export class BankDetailsComponent extends Basecomponent implements OnInit {
  bankDetail:FormGroup;
  userHashId:string = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID) : localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID);
  zoneHashId = (this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_ZONE_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_ZONE_HASH_ID) : localStorage.getItem(UserConstants.KEY_SELECTED_ZONE_HASH_ID);
  userbankdetail:any={}

  constructor(public formBuilder:FormBuilder,
    baseService : BaseService,
    private userStorage : UserStorageService,) {
      super(baseService);
    this.bankDetail=this.formBuilder.group({
      "bankName":[null,[Validators.required]],
      "accountHolderName":[ null,[Validators.required]],
      "branchName":[ null,[Validators.required]],
      "accountNumber":[ null,[Validators.required]],
      "pancardNumber":[ null,[Validators.required]],
      "accType":["0",[Validators.required]],
      "ifscCode":[null,[Validators.required]]
    })
  }

  ngOnInit(): void {
    this.apiService.getUserBankDetails(this.userHashId,this.zoneHashId)
  }
   apiResultSubscribe(data: any) {
    if (data.resulttype == UserConstants.RESULT_GET_USER_BANK_DETAILS) {
      if (data.result.statusCode == 200) {
        this.userbankdetail=data.result.data[0]
      }
      if(data.resulttype == UserConstants.RESULT_ADD_USER_BANK_DETAILS){
        this.apiService.getUserBankDetails(this.userHashId,this.zoneHashId)
      }
    }
  }
  get fc(){
    return this.bankDetail.controls
  }
  submitBankDetail(){
    console.log("form",this.bankDetail.value);
    let data ={
      "userHashId":this.userHashId,
      "bank_name":this.bankDetail.value.bankName??this.userbankdetail.holder_name,
      "holder_name":this.bankDetail.value.accountHolderName??this.userbankdetail.holder_name,
      "branch_name":this.bankDetail.value.branchName ??this.userbankdetail.branch_name,
      "acc_type":this.bankDetail.value.accType,
      "acc_number":this.bankDetail.value.accountNumber ??this.userbankdetail.acc_number,
      "ifsc_code":this.bankDetail.value.ifscCode ??this.userbankdetail.ifsc_code,
      "pan_number":this.bankDetail.value.pancardNumber ?? this.userbankdetail.pan_number,
      "zoneHashId":this.zoneHashId,
    }
    // console.log("datahash",this.userbankdetail.hash_id);
    // this.apiService.addEditUserBankDetails(data)

    if(this.userbankdetail !=undefined){
      data['hash_id'] = this.userbankdetail.hash_id;
      console.log("data:122",data);
    }
    this.apiService.addEditUserBankDetails(data);
    this.compManager.closePopup();

  }

}
