import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { Mode } from 'src/app/enums/mode.enum';
import { AlertModalService } from 'src/app/pages/modal/alert-modal/alert-modal.service';
import { BaseService } from 'src/app/services/base.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { UserConstants } from 'src/app/utils/userConstant';

@Component({
  selector: 'app-paytm-payment',
  templateUrl: './paytm-payment.component.html',
  styleUrls: ['./paytm-payment.component.scss']
})
export class PaytmPaymentComponent extends Basecomponent implements OnInit {
  paymentStatus: any;
  userHashId: any = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID) : localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID);
  businessHashId: any;
  subServiceId: string;
  subServiceType: string;
  loaderVisible:boolean=true;
  constructor(baseService: BaseService,
    private router: Router,
    private userStorage: UserStorageService,
    private alertModal: AlertModalService) {
    super(baseService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    var PayInfo = this;
    let data = this.getPassingData().stateData;
    this.subServiceType = data.cartType;
    this.subServiceId = data.subServiceId;
    this.businessHashId = data.businessHashId
    var config = {
      flow: "DEFAULT",
      hidePaymodeLabel: true,
      root: "#card",
      data: {
        orderId: data.orderId,
        amount: data.amount,
        token: data.token,
        tokenType: data.tokenType,
      },
      style: {
        bodyColor: "green"
      },
      payMode: {
        "order": ['UPI', 'CARD', 'PAY_WITH_PAYTM'],
        "labels": {
          "PAY_WITH_PAYTM": "Pay with paytm"
        }
      },
      merchant: {
        mid: "uAKazF74735905421040",
        redirect: false
      },
      handler: {
        notifyMerchant: function (eventType, data) {
          console.log("notify merchant called", eventType, data);
        },
        transactionStatus: function transactionStatus(paymentStatus): any {
          console.log('paymentStatus::::', paymentStatus);
          // console.log('PayInfo::::', PayInfo);
          if (paymentStatus.STATUS == "TXN_SUCCESS") {
            PayInfo.alertModal.confirm("PAYMENT SUCCESS", `ORDERID: ${paymentStatus.ORDERID}, STATUS: ${paymentStatus.STATUS}, TXNAMOUNT: ${paymentStatus.TXNAMOUNT}, TXNID: ${paymentStatus.TXNID}, TXNDATE: ${paymentStatus.TXNDATE},BANKNAME: ${paymentStatus.BANKNAME}, CURRENCY: ${paymentStatus.CURRENCY},GATEWAYNAME: ${paymentStatus.GATEWAYNAME},PAYMENTMODE: ${paymentStatus.PAYMENTMODE},`);
            PayInfo.paymentStatus = paymentStatus
            if (data.cartType == UserConstants.SUB_SERVICE_TYPE_LAUNDARY) {
              PayInfo.apiService.createLaundryUserOrder(data.orderDataInfo);
            } else {
              PayInfo.apiService.createUserOrder(data.orderDataInfo)
            }
          } else if (paymentStatus.STATUS =="TXN_FAILURE") {
            PayInfo.alertModal.confirm("Payment Failed",paymentStatus.RESPMSG);
          }
          PayInfo.closePopup();
        },
      }
    };

    global.Paytm.CheckoutJS.init(config).then(()=>{
      this.loaderVisible=false
    }).then(function onSuccess(data) {
      console.log("res::", data);
      global.Paytm.CheckoutJS.invoke()
      // this.loaderVisible=false;
    }).catch(function onError(error) {
      console.log("error => ", error);
    });

  }
  apiResultSubscribe(data: any) {
    console.log("data", data);
    if (data.resulttype == UserConstants.RESULT_CREATE_USER_ORDER) {
      if (data.result.statusCode == 200) {
        this.apiService.deleteUserCart(this.userHashId)
      let paytmPaymetRecord = {
        "txnAmount": this.paymentStatus.TXNAMOUNT,
        "respMsg": this.paymentStatus.RESPMSG,
        "checkSumHash": this.paymentStatus.CHECKSUMHASH,
        "paymentMode": this.paymentStatus.PAYMENTMODE,
        "status": this.paymentStatus.STATUS,
        "gatewayName": this.paymentStatus.GATEWAYNAME,
        "bankName": this.paymentStatus.BANKNAME,
        "respCode": this.paymentStatus.RESPCODE,
        "bankTxnId": this.paymentStatus.BANKTXNID,
        "txnId": this.paymentStatus.TXNID,
        "txnDate": this.paymentStatus.TXNDATE,
        "orderId": this.paymentStatus.ORDERID,
        "businessHashId": this.businessHashId,
        "userHashId": this.userHashId
      };
      console.log("paytmPaymetRecord", paytmPaymetRecord);
      this.apiService.paytmPaymentRecord(paytmPaymetRecord)
      if (this.subServiceType == UserConstants.SUB_SERVICE_TYPE_LAUNDARY) {
       this.router.navigate([`${UserConstants.RL_LAUNDRY_BOOKING_DETAIL}`], { queryParams: { 'orderId': data.result.data[0].hash_id, 'subServiceId': this.subServiceId, 'orderStatus': 'new' } });
      } else {
         this.router.navigate([`${UserConstants.RL_ORDER_DETAILS}`], { queryParams: { 'orderId': data.result.data[0].hash_id, 'subServiceId': this.subServiceId, 'orderStatus': 'new' } });
      }
      }

    }
  }
}
