import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UploadService } from 'src/app/services/uploadService/upload.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Dimensions } from 'src/app/interfaces/imageCropper/dimensions';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { base64ToFile } from 'src/app/interfaces/imageCropper/blob.utils';
import { ImageTransform } from 'src/app/interfaces/imageCropper/image-transform';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { UserConstants } from 'src/app/utils/userConstant';
@Component({
  selector: 'app-background-picture',
  templateUrl: './background-picture.component.html',
  styleUrls: ['./background-picture.component.scss']
})
export class BackgroundPictureComponent extends Basecomponent implements OnInit {

  constructor(baseService : BaseService,
    private _uploadService : UploadService,
    private userStorage : UserStorageService,
    private loader : LoaderService) {
    super(baseService);
   }
   imageChangedEvent: any = '';
   croppedImage: any = '';
   canvasRotation = 0;
   rotation = 0;
   scale = 1;
   showCropper = false;
   containWithinAspectRatio = false;
   transform: ImageTransform = {};
   isCropImageSelected:Boolean=false;
   imageType : any;
   imageUrl:string='/assets/images/186236.svg';
  ngOnInit(): void {
    super.ngOnInit();
    let data = this.getPassingData().stateData;
    this.imageType = data.imageType;
    this.imageUrl = (data.imageUrl && data.imageUrl != 'null') ? data.imageUrl : '/assets/images/186236.svg'; 
    console.log(this.imageType);
  }
  removeCropPicture(){
this.imageChangedEvent=null;
this.croppedImage=null;
this.isCropImageSelected=false;
this.canvasRotation=0;
this.rotation=0;
this.scale=1;
this.showCropper=false;
this.containWithinAspectRatio=false;
  }
  private storageKey:any;
  saveCropProfilePicture(){
    try {
      document.getElementById( 'crop-save' ).innerHTML = 'Uploading <i class="fa fa-spinner fa-spin"></i>';
      document.getElementById( 'crop-save' ).setAttribute( "disabled", "diasbled" );
      // this.loader.showLoader=true;
      this._uploadService.uploadToS3Bucket(UserConstants.BUCKET_TYPE_PROFILE_DOC,'image',this.imageType,this.croppedImage).then(res=>{
        // this.loader.showLoader=false;
        document.getElementById( 'crop-save' ).innerHTML = 'Save';
      document.getElementById( 'crop-save' ).removeAttribute( "disabled" );
        console.log(res);
        this.storageKey=res['key'];
        let imageData = {
          type : this.imageType,
          key : this.storageKey
        }
        this.closePopupWithData(imageData);
      }).catch(err=>{
        document.getElementById( 'crop-save' ).innerHTML = 'Save';
      document.getElementById( 'crop-save' ).removeAttribute( "disabled" );
        // this.loader.showLoader=false;
        console.log(err);
      })
    } catch (error) {
      console.log(error);
    }
  }
  fileChangeEvent(event: any): void {
    console.log("File change Event: ",event);
    let file = event.target.files;
    console.log(file)
  if(file[0].type === 'image/jpeg' || file[0].type === 'image/png' || file[0].type === 'image/gif'){
    this.isCropImageSelected=true;
    this.imageChangedEvent = event;
  }else{
    this.setError(true,"Alert!","Please select valid picture type. Picture should be saved as JPG, PNG, or GIF.");
  }

}

imageCropped(event: ImageCroppedEvent) {
    console.log("Image cropped: ",event);
    this.croppedImage = event.base64;
    console.log(event, base64ToFile(event.base64));
}

imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
}

cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
}

loadImageFailed() {
    console.log('Load failed');
}
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
}

rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
}
zoomOut() {
  this.scale -= .1;
  this.transform = {
      ...this.transform,
      scale: this.scale
  };
}

zoomIn() {
  this.scale += .1;
  this.transform = {
      ...this.transform,
      scale: this.scale
  };
}
private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
    };
}

}
