import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { ConfirmModalService } from 'src/app/pages/modal/confirm-modal/confirm-modal.service';
import { BaseService } from 'src/app/services/base.service';
import { UserStorageService } from 'src/app/services/user-storage.service';
import { UserConstants } from 'src/app/utils/userConstant';

@Component({
  selector: 'app-user-bank-account-details',
  templateUrl: './user-bank-account-details.component.html',
  styleUrls: ['./user-bank-account-details.component.scss']
})
export class UserBankAccountDetailsComponent extends Basecomponent implements OnInit {
  userHashId: any = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID) : localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID);
  zoneHashId = (this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_ZONE_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_SELECTED_ZONE_HASH_ID) : localStorage.getItem(UserConstants.KEY_SELECTED_ZONE_HASH_ID);
  bankDetail: any = {}
  constructor(baseService: BaseService,
    private userStorage: UserStorageService,
    private confirmModal: ConfirmModalService,
    private router: Router) {
    super(baseService);
  }

  ngOnInit(): void {
    this.apiService.getUserBankDetails(this.userHashId, this.zoneHashId)

  }
  apiResultSubscribe(data: any) {
    if (data.resulttype == UserConstants.RESULT_GET_USER_BANK_DETAILS) {
      if (data.result.statusCode == 200) {
        this.bankDetail = data.result.data[0]
      }
    }
    if (data.resulttype == UserConstants.RESULT_DELETE_USER_BANK_DETAILS) {
      if (data.result.statusCode == 200) {
        this.apiService.getUserBankDetails(this.userHashId, this.zoneHashId)

      }

    }
  }

  openBankAccount() {
    this.redirect(UserConstants.RL_BANK_DETAILS, { modalSize: 'md' });
  }
  deletUserBankDetail(data) {
    console.log("ndn", data);
    this.confirmModal.confirm("Alert!","Are you sure you want to delete  your Account ?").then(res=>{
      console.log(res);
      if(res){
          this.apiService.deleteUserBankDetails(data.hash_id, this.zoneHashId)
      }
    }).catch(err=>{
      console.log(err);
    })

  }

}
