import { Injectable } from '@angular/core';
// import { ApiService } from './api.service';
import { CompLoadManagerService } from './comp-manager.service';
import { DataEventService } from './data-event.service';
import { ExceptionManagerService } from './exception-manager.service';
import { StateService } from './state.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(public compManager:CompLoadManagerService,
    public stateService:StateService,
    public dataEvent:DataEventService,
    public weeManager:ExceptionManagerService,
    public apiService:ApiService
    ) { }
}
