<div class="track_order_map">
    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2645.9577697739874!2d-89.21424329999999!3d48.457339800000014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4d5923eaca84fa67%3A0xfd2f9f807114acd4!2s292+Shuniah+St%2C+Thunder+Bay%2C+ON+P7A+3A2!5e0!3m2!1sen!2sca!4v1424272931061"
        width=100% height="350" frameborder="0" style="border:0"></iframe> -->

        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false" (mapClick)="mapClicked($event)">

            <!-- <agm-marker [latitude]="businessAddress.lat" [longitude]="businessAddress.lng">
            </agm-marker> -->
            <!-- <agm-marker [latitude]="deliveryAddress.lat" [longitude]="deliveryAddress.lng">
            </agm-marker>  -->
            <agm-marker [latitude]="driverDeliveryCurrentLocation?.lat_locations" [longitude]="driverDeliveryCurrentLocation?.lng_locations" iconUrl="assets/images/bike25x25.png">
            </agm-marker> 
            <agm-marker *ngFor="let coordinate of start_end_mark; let i=index"
              [latitude]="coordinate[0]"
              [longitude]="coordinate[1]" [agmFitBounds]="true"></agm-marker>
              <agm-polyline [visible]="true" [strokeWeight]="3" [strokeColor]="'#07b57a'">
                    <agm-polyline-point
                    *ngFor="let coordinate of latlng; let i=index"
                    [latitude]="coordinate[0]"
                    [longitude]="coordinate[1]">

</agm-polyline-point>
</agm-polyline>
        </agm-map>
</div>
<div class="track_opc_map">
    <div class="row">
        <div class="col-sm-6">
            <div class="rgt_tex">
                <div class="tgr_sed">
                    <div class="track_tel">
                        <button> <i class="fa fa-phone" aria-hidden="true" type="submit" (click)="phoneCallbymasking()"></i> Call Merchant</button>
                    </div>
                </div>
                <h3>Merchant
                </h3>
            </div>
            <div class="delivery_rate">
                <div class="plm_krf">
                    <div class="row">
                        <div class="col-sm-2">
                            <div class="ord_pic" *ngIf="businessImage[0]">
                                <img [src]="businessImage[0].image_url">
                            </div>
                            <div class="cbk_dratoo" *ngIf="!businessImage[0]">
                                <h4>{{createAvaterName(businessName) ? createAvaterName(businessName) : 'NA'}}</h4>
                            </div>
                        </div>
                        <div class="col-sm-10">
                            <div class="deli_head_ord">
                                <h4>{{businessName && businessName != 'null' ? businessName : 'NA'}}</h4>
                                <p>{{businessAddress.address_line && businessAddress.address_line != 'null' ? businessAddress.address_line : 'NA'}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="rgt_tex">
                <div class="tgr_sed">
                    <div class="track_tel">
                        <button (click)="callMe(deliveryBoyInfo.phone)"> <i class="fa fa-phone" aria-hidden="true"></i> Call Delivery</button>
                    </div>
                </div>
                <h3>Delivery
                </h3>
            </div>
            <div class="delivery_rate">
                <div class="plm_krf">
                    <div class="row">
                        <div class="col-sm-2">
                            <div class="ord_pic">
                                <img [src]="deliveryBoyInfo.image_url">
                            </div>
                        </div>
                        <div class="col-sm-10">
                            <div class="deli_head_ord">
                                <h4>{{deliveryBoyInfo.name}}</h4>
                                <p>Total {{deliveryBoyInfo.orderCount}}+ order complete</p>
                                <p style="padding-left: 2px;">{{deliveryBoyInfo.phone}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
