import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnDestroy {
  loaderVisible: boolean = false;
  private _apiSubscription: Subscription;

  constructor(private loader: LoaderService) {
    this._apiSubscription = this.loader.showLoader.subscribe((data: boolean) =>{
      setTimeout(() => {
        this.loaderVisible = data;
      }, 100);
    });
   }


  ngOnDestroy(): void {
    this._apiSubscription.unsubscribe();
  }

}
