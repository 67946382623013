<div class="track_order_sec">
    <error-exception-comp></error-exception-comp>
    <div class="add_scroll">
        <div class="ad_ox">
            <div class="track_heading">
                <h3>
                     Status track
                </h3>
            </div>
            <div class="tht_track" *ngFor="let item of orderTrackStatus;let i=index;">
                <div class="track_list">
                    <span class="time_date_dratoo">{{item.creation_time | date: "MMM d, y, h:mm a"}}</span>
                    <span class="ver_trc"></span>
                </div>
                <div class="Delivery_sed_trk">
                    <span>{{item.value}}</span>
                </div>
            </div>
            <div class="row" *ngIf="orderTrackStatus.length == 0">
                <div class="col-sm-12">
                    <div class="pce_hml_text">
                        <div class="gtr_plc">
                            <div class="plt_hlr">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve"><g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                  <g>
                                    <path d="M401,401c-20.671,0-38.255,13.42-44.531,32H79c-27.019,0-49-21.981-49-49c0-27.019,21.981-49,49-49h96    c43.561,0,79-35.439,79-79c0-43.561-35.439-79-79-79H91.531C86.848,163.134,75.866,152.152,62,147.469V111h81    c8.284,0,15-6.716,15-15V32c0-8.284-6.716-15-15-15H47c-8.284,0-15,6.716-15,15v64v51.469C13.42,153.745,0,171.329,0,192    c0,25.916,21.084,47,47,47c20.671,0,38.255-13.42,44.531-32H175c27.019,0,49,21.981,49,49s-21.981,49-49,49H79    c-43.561,0-79,35.439-79,79c0,43.561,35.439,79,79,79h277.469c6.276,18.58,23.86,32,44.531,32c25.916,0,47-21.084,47-47    S426.916,401,401,401z M62,47h66v34H62V47z M47,209c-9.374,0-17-7.626-17-17c0-9.374,7.626-17,17-17s17,7.626,17,17    C64,201.374,56.374,209,47,209z M401,465c-9.374,0-17-7.626-17-17c0-9.374,7.626-17,17-17c9.374,0,17,7.626,17,17    C418,457.374,410.374,465,401,465z" fill="#000000" data-original="#000000"  ></path>
                                  </g>
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                  <g>
                                    <path d="M401,113c-25.916,0-47,21.084-47,47s21.084,47,47,47s47-21.084,47-47S426.916,113,401,113z M401,177    c-9.374,0-17-7.626-17-17c0-9.374,7.626-17,17-17c9.374,0,17,7.626,17,17C418,169.374,410.374,177,401,177z" fill="#000000" data-original="#000000"  ></path>
                                  </g>
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                  <g>
                                    <path d="M401,49c-61.206,0-111,49.794-111,111c0,33.051,14.399,63.844,39.626,85.015l58.01,113.797    C390.197,363.837,395.36,367,401,367s10.803-3.163,13.364-8.188l58.01-113.797C497.601,223.844,512,193.051,512,160    C512,98.794,462.206,49,401,49z M451.15,223.613c-1.698,1.341-3.085,3.032-4.068,4.96L401,318.972l-46.082-90.399    c-0.982-1.927-2.37-3.619-4.068-4.96C331.244,208.132,320,184.946,320,160c0-44.664,36.336-81,81-81s81,36.336,81,81    C482,184.946,470.756,208.132,451.15,223.613z" fill="#000000" data-original="#000000"  ></path>
                                  </g>
                                </g>

                                </g></svg>
                                <div class="rgt_ted">
                                    <h3>No track history
                                    </h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
