import { Component, OnInit } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { UserConstants } from 'src/app/utils/userConstant';

@Component({
  selector: 'app-courier-track-order',
  templateUrl: './courier-track-order.component.html',
  styleUrls: ['./courier-track-order.component.scss']
})
export class CourierTrackOrderComponent extends Basecomponent implements OnInit {

  constructor(baseService : BaseService) {
    super(baseService);
   }
   passingData:any={};
  ngOnInit(): void {
    super.ngOnInit();
    this.passingData= this.getPassingData().stateData;
    if(this.passingData.serviceType == 'courier'){
      this.apiService.getCourierOrderTrackingOrder(this.passingData.orderHashId);
    }else if(this.passingData.serviceType == 'buyonrequest'){
      this.apiService.getUnlistedShopOrderTrackingOrder(this.passingData.orderHashId);
    }else if(this.passingData.serviceType == 'utilityService'){
      this.apiService.getServicePersonnelOrderTrackHistory(this.passingData.orderHashId);
    }else if(this.passingData.serviceType == 'ride'){
      this.apiService.getRideTrackHistory(this.passingData.orderHashId)
    }
  }
  orderTrackStatus:any[]=[];
  apiResultSubscribe(data: any) {
    console.log(data);
    if(data.resulttype == UserConstants.RESULT_GET_COURIER_ORDER_TRACKING_HISTORY){
      if(data.result.statusCode == 200){
        this.orderTrackStatus=[];
        data.result.data.forEach(element => {
          if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_ASSIGNED_TO_DELIVERY_BOY){
            element.value = "Delivery Assigned";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_BOY_REACHED_AT_DROP_LOCATION){
            element.value = "Reach at drop location";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_WILL_BE_DELAYED_BY_DELAYED_BY_DELIVERY_BOY){
            element.value = "Delivery may be delayed";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_DELIVERY_BOY){
            element.value = "Delivery Accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PICKED_UP_BY_DELIVERY_BOY){
            element.value = "Order Picked";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PLACED_BY_USER){
            element.value = "Order Place";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_READY_BY_MERCHANT){
            element.value = "Order ready";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_PAYMENT_DONE_FOR_THE_ORDER){
            element.value = "Payment done";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PLACED_BY_CALL_CENTER_OR_ADMIN){
            element.value = "Order place by call center/admin";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_USER){
            element.value = "Order cancel by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_MERCHANT_AFTER_ACCEPTING){
            element.value = "Order cancel by merchant after accepting";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_MERCHANT){
            element.value = "Order cancel by merchant";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_MERCHANT){
            element.value = "Order accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_COMPLETE){
            element.value = "Order Delivered";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_CANCEL_BY_USER){
            element.value = "Delivery cancel by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_CALL_CENTER_OR_ADMIN){
            element.value = "Order cancel by call center/admin";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_USER_RATING_DONE_FOR_THE_DELIVERY){
            element.value = "Rating done for delivery by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_USER_RATING_DONE_FOR_THE_ORDER){
            element.value = "Rating done for order by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_WILL_BE_DELAYED_BY_MERCHANT){
            element.value = "Order may be delayed from the merchant";
          }
          console.log(element);
          this.orderTrackStatus.push(element);
        });
      }else{
        this.setError(true,data.result);
      }
    }else if(data.resulttype == UserConstants.RESULT_GET_UNLISTED_SHOP_ORDER_TRACKING_HISTORY){
      if(data.result.statusCode == 200){
        this.orderTrackStatus=[];
        data.result.data.forEach(element => {
          if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_ASSIGNED_TO_DELIVERY_BOY){
            element.value = "Delivery Assigned";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_BOY_REACHED_AT_DROP_LOCATION){
            element.value = "Reach at drop location";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_WILL_BE_DELAYED_BY_DELAYED_BY_DELIVERY_BOY){
            element.value = "Delivery may be delayed";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_DELIVERY_BOY){
            element.value = "Delivery Accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PICKED_UP_BY_DELIVERY_BOY){
            element.value = "Order Picked";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PLACED_BY_USER){
            element.value = "Order Place";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_READY_BY_MERCHANT){
            element.value = "Order ready";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_PAYMENT_DONE_FOR_THE_ORDER){
            element.value = "Payment done";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PLACED_BY_CALL_CENTER_OR_ADMIN){
            element.value = "Order place by call center/admin";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_USER){
            element.value = "Order cancel by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_MERCHANT_AFTER_ACCEPTING){
            element.value = "Order cancel by merchant after accepting";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_MERCHANT){
            element.value = "Order cancel by merchant";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_MERCHANT){
            element.value = "Order accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_COMPLETE){
            element.value = "Order Delivered";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_CANCEL_BY_USER){
            element.value = "Delivery cancel by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_CALL_CENTER_OR_ADMIN){
            element.value = "Order cancel by call center/admin";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_USER_RATING_DONE_FOR_THE_DELIVERY){
            element.value = "Rating done for delivery by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_USER_RATING_DONE_FOR_THE_ORDER){
            element.value = "Rating done for order by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_WILL_BE_DELAYED_BY_MERCHANT){
            element.value = "Order may be delayed from the merchant";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_BOY_REACHED_AT_PICK_UP_LOCATION){
            element.value = 'At pickup location';
          }
          console.log(element);
          this.orderTrackStatus.push(element);
        });
      }else{
        this.setError(true,data.result);
      }
    }else if(data.resulttype == UserConstants.RESULT_GET_UTILITY_ORDER_TRACKING_HISTORY){
      if(data.result.statusCode == 200){
        this.orderTrackStatus=[];
        data.result.data.forEach(element => {
          if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_ASSIGNED_TO_DELIVERY_BOY){
            element.value = "Delivery Assigned";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_BOY_REACHED_AT_DROP_LOCATION){
            element.value = "Reach at drop location";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_WILL_BE_DELAYED_BY_DELAYED_BY_DELIVERY_BOY){
            element.value = "Delivery may be delayed";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_DELIVERY_BOY){
            element.value = "Delivery Accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PICKED_UP_BY_DELIVERY_BOY){
            element.value = "Order Picked";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PLACED_BY_USER){
            element.value = "Order Place";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_READY_BY_MERCHANT){
            element.value = "Order ready";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_PAYMENT_DONE_FOR_THE_ORDER){
            element.value = "Payment done";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PLACED_BY_CALL_CENTER_OR_ADMIN){
            element.value = "Order place by call center/admin";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_USER){
            element.value = "Order cancel by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_MERCHANT_AFTER_ACCEPTING){
            element.value = "Order cancel by merchant after accepting";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_MERCHANT){
            element.value = "Order cancel by merchant";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_MERCHANT){
            element.value = "Order accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_COMPLETE){
            element.value = "Order Delivered";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_CANCEL_BY_USER){
            element.value = "Delivery cancel by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_CALL_CENTER_OR_ADMIN){
            element.value = "Order cancel by call center/admin";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_USER_RATING_DONE_FOR_THE_DELIVERY){
            element.value = "Rating done for delivery by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_USER_RATING_DONE_FOR_THE_ORDER){
            element.value = "Rating done for order by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_WILL_BE_DELAYED_BY_MERCHANT){
            element.value = "Order may be delayed from the merchant";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_SERVICE_PERSONNEL ){
            element.value = "Accepted by service provider";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_SERVICE_PERSONNEL){
            element.value = "Cancelled by service provider";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_SERVICE_PERSONNEL_AFTER_ACCEPTING){
            element.value = "Order cancel after accepting the order by service provider";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_JOB_CARD_PREPARED_BY_SERVICE_PERSONNEL){
            element.value = "Job Card prepared";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_JOB_CARD_ACCEPTED_BY_USER){
            element.value = "Job card accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_JOB_CARD_DECLINED_BY_USER){
            element.value = "Job card declined";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_SERVICE_ORDER_STARTED_BY_SERVICE_PERSONNEL){
            element.value = "Service Started";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_SERVICE_ORDER_CANCEL_BY_SERVICE_PERSONNEL_AFTER_ACCEPTING_JOB_CARD_BY_USER){
            element.value = "Service cancel by service provider after accepting job card by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_SERVICE_ORDER_COMPLETE){
            element.value = "Service Order Complete";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_SERVICE_ORDER_BILL_GENERATED_BY_SERVICE_PERSONNEL){
            element.value = "Bill Generated";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_SERVICE_ORDER_BILL_AMOUNT_PAID_BY_CUSTOMER){
            element.value = "Bill Paid by customer";
          }
          console.log(element);
          this.orderTrackStatus.push(element);
        });
      }else{
        this.setError(true,data.result);
      }
    }else if(data.resulttype == UserConstants.RESULT_GET_RIDE_TRACK_HISTORY){
      if(data.result.statusCode == 200){
        this.orderTrackStatus=[];
        data.result.data.forEach(element => {
          if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_ASSIGNED_TO_DELIVERY_BOY){
            element.value = "Delivery Assigned";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_BOY_REACHED_AT_DROP_LOCATION){
            element.value = "Reach at drop location";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_WILL_BE_DELAYED_BY_DELAYED_BY_DELIVERY_BOY){
            element.value = "Delivery may be delayed";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_DELIVERY_BOY){
            element.value = "Delivery Accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PICKED_UP_BY_DELIVERY_BOY){
            element.value = "Order Picked";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PLACED_BY_USER){
            element.value = "Order Place";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_READY_BY_MERCHANT){
            element.value = "Order ready";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_PAYMENT_DONE_FOR_THE_ORDER){
            element.value = "Payment done";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_PLACED_BY_CALL_CENTER_OR_ADMIN){
            element.value = "Order place by call center/admin";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_USER){
            element.value = "Order cancel by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_MERCHANT_AFTER_ACCEPTING){
            element.value = "Order cancel by merchant after accepting";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_MERCHANT){
            element.value = "Order cancel by merchant";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_MERCHANT){
            element.value = "Order accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_COMPLETE){
            element.value = "Order Delivered";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_DELIVERY_CANCEL_BY_USER){
            element.value = "Delivery cancel by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_CALL_CENTER_OR_ADMIN){
            element.value = "Order cancel by call center/admin";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_USER_RATING_DONE_FOR_THE_DELIVERY){
            element.value = "Rating done for delivery by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_USER_RATING_DONE_FOR_THE_ORDER){
            element.value = "Rating done for order by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_WILL_BE_DELAYED_BY_MERCHANT){
            element.value = "Order may be delayed from the merchant";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_SERVICE_PERSONNEL ){
            element.value = "Accepted by service provider";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_SERVICE_PERSONNEL){
            element.value = "Cancelled by service provider";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_SERVICE_PERSONNEL_AFTER_ACCEPTING){
            element.value = "Order cancel after accepting the order by service provider";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_JOB_CARD_PREPARED_BY_SERVICE_PERSONNEL){
            element.value = "Job Card prepared";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_JOB_CARD_ACCEPTED_BY_USER){
            element.value = "Job card accepted";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_JOB_CARD_DECLINED_BY_USER){
            element.value = "Job card declined";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_SERVICE_ORDER_STARTED_BY_SERVICE_PERSONNEL){
            element.value = "Service Started";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_SERVICE_ORDER_CANCEL_BY_SERVICE_PERSONNEL_AFTER_ACCEPTING_JOB_CARD_BY_USER){
            element.value = "Service cancel by service provider after accepting job card by user";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_SERVICE_ORDER_COMPLETE){
            element.value = "Service Order Complete";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_SERVICE_ORDER_BILL_GENERATED_BY_SERVICE_PERSONNEL){
            element.value = "Bill Generated";
          }else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_SERVICE_ORDER_BILL_AMOUNT_PAID_BY_CUSTOMER){
            element.value = "Bill Paid by customer";
          }


          else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_CANCELLED_BY_DRIVER){
            element.value = "Order cancel by driver";
          }
          else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_ORDER_ACCEPTED_BY_DRIVER){
            element.value = "Accepted";
          }
          else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_PASSENGER_LOCATED){
            element.value = "Passenger Located";
          }
          else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_PASSENGER_PICKED_UP_BY_DRIVER){
            element.value = "Passenger Picked";
          }
          else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_REACHED_DROP_LOCATION){
            element.value = "At drop location";
          }
          else if(element.user_delivery_status_latest == UserConstants.ORDER_STATUS_PAYMENT_DONE){
            element.value = "Payment done";
          }
          console.log(element);
          this.orderTrackStatus.push(element);
        });
      }else{
        this.setError(true,data.result);
      }
    }
  }

}
