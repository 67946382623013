import { Injectable } from '@angular/core';
import { from, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { ExceptionManagerService } from './exception-manager.service';
import { HelperFunc } from '../utils/helper-func';
import { UserConstants } from '../utils/userConstant';
import { AuthService } from './auth.service';
import { UserStorageService } from './user-storage.service';


const REQUEST_GET: string = "GET";
const REQUEST_POST: string = "POST";
const REQUEST_PUT: string = "PUT";


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _result: Subject<any> = new Subject<any>();

  constructor(
    private drtoHttp: HttpClient,
    private loader: LoaderService,
    private errorManager: ExceptionManagerService,
    private userStorage: UserStorageService,
  ) {}


  userLoginIn(){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.USER_LOGIN_IN_DATA;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_LOG_IN_DATA;
    let fcmToken : string = localStorage.getItem(UserConstants.KEY_FCM_TOKEN) ? localStorage.getItem(UserConstants.KEY_FCM_TOKEN) : 'null';
    let awsHashId = (this.userStorage.getValueFromStorage(UserConstants.KEY_AUTH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_AUTH_ID) : localStorage.getItem(UserConstants.KEY_AUTH_ID);
    console.log("Aws Hash Id: ",awsHashId);
    let hplist : Array<HeaderParam>=[
      new HeaderParam("awsId",awsHashId),
      new HeaderParam("fcmToken",fcmToken),
      new HeaderParam("deviceType",'3'),
      new HeaderParam("deviceId",'web')
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getZoneByLocationInfo(zip : string,line1:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_ZONE_INFO_BY_LOCATION;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_ZONE_INFO_BY_LOCATION;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zip",zip),
      new HeaderParam("line1",line1)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getZoneByLocation(){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = "https://ytytr5ywqc.execute-api.us-east-1.amazonaws.com/dev/api/v1/mthc/associate-countries/list"
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_ZONE_INFO;
    // let hplist : Array<HeaderParam>=[
    //   new HeaderParam("zip",zip),
    //   new HeaderParam("line1",line1)
    // ]
    this.genericApiCall(apiparams);
  }

  setNormalUserZoneMap(zoneHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.SET_NORMAL_USER_ZONE_MAP;

    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_SET_NORMAL_USER_ZONE_MAP;
    let accountId = (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID) : localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID);
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneId",zoneHashId),
      new HeaderParam("userId",accountId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getSubServiceByZoneId(zoneHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_ZONE_SPECIFIC_SUB_SERVICE_LIST;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_ZONE_SPECFIC_SUB_SERVICE_LIST;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId",zoneHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getBusinessListBySubService(subServiceHashId:string,zoneHashId : string,pageNo : string,noOfItem:string ,activeLoadder : boolean = true){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_BUSINESS_LIST_BY_SUB_SERVICE;
    apiparams.requesttype = REQUEST_GET;
    apiparams.activeLoader = activeLoadder;
    apiparams.resulttype = UserConstants.RESULT_GET_BUSINESS_LIST_BY_SUB_SERVICE;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId",zoneHashId),
      new HeaderParam("subServiceHashId",subServiceHashId),
      new HeaderParam("pageNo",pageNo),
      new HeaderParam("noOfItem",noOfItem)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  searchBusinessListBySubService(subServiceHashId:string,zoneHashId : string,pageNo : string,noOfItem:string ,activeLoadder : boolean = true,searchString : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_BUSINESS_LIST_BY_SUB_SERVICE;
    apiparams.requesttype = REQUEST_GET;
    apiparams.activeLoader = activeLoadder;
    apiparams.resulttype = UserConstants.RESULT_SEARCH_BUSINESS_LIST_BY_SUB_SERVICE;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId",zoneHashId),
      new HeaderParam("subServiceHashId",subServiceHashId),
      new HeaderParam("searchString",searchString),
      new HeaderParam("pageNo",pageNo),
      new HeaderParam("noOfItem",noOfItem)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getBusinessDetailById(businessHashId : string,activeLoader : boolean=true){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_BUSINESS_DETAIL_BY_ID;
    apiparams.requesttype = REQUEST_GET;
    apiparams.activeLoader = activeLoader;
    apiparams.resulttype = UserConstants.RESULT_GET_BUSINESS_DETAIL_BY_ID;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("businessHashId",businessHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getBusinessListByZoneId(zoneHashId : string,searchString : string,pageNo : string,noOfItem : string,activeLoadder : boolean=true){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_BUSINESS_LIST_BY_ZONE_ID;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_BUSINESS_LIST_BY_ZONE_ID;
    apiparams.activeLoader = activeLoadder;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId",zoneHashId),
      new HeaderParam("searchString",searchString),
      new HeaderParam("pageNo",pageNo),
      new HeaderParam("noOfItem",noOfItem)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getSurpriseMeByZoneId(zoneHashId : string,searchString : string,pageNo : string,noOfItem : string,subServiceType : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_SURPRISE_ME_ITEMS;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_SURPRISE_ME_ITEMS;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId",zoneHashId),
      new HeaderParam("subServiceType",subServiceType),
      new HeaderParam("skey",searchString),
      new HeaderParam("pageNo",pageNo),
      new HeaderParam("noOfItem",noOfItem)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getsubmitSurpriseMeOrder(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.SUBMIT_SURPRISE_ME_ORDER;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_SUBMIT_SURPRISE_ME_ORDER;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }
  getSurpriseMeRequestsByUser(userHashId:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_SURPRISE_ME_BY_USER;
    apiparams.requesttype = REQUEST_GET;
    // apiparams.resulttype = UserConstants.RESULT_GET_SURPRISE_ME_ITEMS;
    apiparams.resulttype = UserConstants.RESULT_GET_SURPRISE_ME_BY_USER;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId),
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getSurpriseMeBids(surpriseMeOrderHash:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_SURPRISE_ME_BIDS;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_SURPRISE_ME_BIDS;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("surpriseMeOrderHash",surpriseMeOrderHash),
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getremoveSupriseMeOrder(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_REMOVE_SUPRISE_ME_ORDER;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_REMOVE_SUPRISE_ME_ORDER;
    apiparams.bodydata = bodydata,
    this.genericApiCall(apiparams);
  }


  getInventoryItemForNormalUser(businessHashId : string,pageNo : string,noOfItem:string,itemCategoryHashId:string,activeLoadder: boolean=true,inputParams:boolean){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_INVENTORY_ITEM_FOR_NORMAL_USER;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_INVENTORY_ITEM_FOR_NORMAL_USER;
    apiparams.activeLoader = activeLoadder;
    apiparams.inputParams=inputParams;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("businessHashId",businessHashId),
      new HeaderParam("pageNo",pageNo),
      new HeaderParam("noOfItem",noOfItem),
      new HeaderParam("itemCategoryHashId",itemCategoryHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  searchInventoryItemForNormalUser(businessHashId : string,pageNo : string,noOfItem:string,searchString:string,itemCategoryHashId:string,activeLoadder: boolean=true,inputParams:boolean){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.SEARCH_INVENTORY_ITEM_FOR_NORMAL_USER;
    apiparams.requesttype = REQUEST_GET;
    apiparams.activeLoader = activeLoadder;
    apiparams.inputParams=inputParams;
    apiparams.resulttype = UserConstants.RESULT_SEARCH_INVENTORY_ITEM_FOR_NORMAL_USER;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("businessHashId",businessHashId),
      new HeaderParam("pageNo",pageNo),
      new HeaderParam("noOfItem",noOfItem),
      new HeaderParam("searchString",searchString),
      new HeaderParam("itemCategoryHashId",itemCategoryHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getCategoryByMerchantId(businessHashId:string,activeLoader : boolean=true){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_ITEM_CATEGORY_AS_PER_KEY;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_ITEM_CATEGORY_AS_PER_KEY;
    apiparams.activeLoader= activeLoader;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("businessHashId",businessHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  updateUserData(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.UPDATE_USER_DATA;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_UPDATE_USER_DATA;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  getNormalUserDetailByAccountId(userHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_NORMAL_USER_DETAIL_BY_ACCOUNT_ID;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_NORMAL_USER_DETAIL_BY_ACCOUNT_ID;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  addEditDeliveryAddress(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_EDIT_DELIVERY_ADDRESS;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_ADD_EDIT_DELIVERY_ADDRESS;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }
  initiatePhoneCallBymasking(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_PHONE_CALL_BY_MASKING;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_ADD_PHONE_CALL_BY_MASKING;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  getDeliveryAddress(userHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_DELIVERY_ADDRESS;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_DELIVERY_ADDRESS;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getUserCart(userHashId : string,activeLoader:boolean=true){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_USER_CART;
    apiparams.requesttype = REQUEST_GET;
    apiparams.activeLoader = activeLoader;
    apiparams.resulttype = UserConstants.RESULT_GET_USER_CART;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  createUserCart(bodydata,userHashId:string,activeLoader : boolean = true){
    console.log("Body data: ",bodydata);
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.CREATE_USER_CART;
    apiparams.requesttype = REQUEST_POST;
    apiparams.activeLoader = activeLoader;
    apiparams.resulttype = UserConstants.RESULT_CREATE_USER_CART;
    apiparams.bodydata = bodydata;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  updateCartQuantity(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.UPDATE_CART_QUANTITY;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_UPDATE_CART_QUANTITY;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  removeCartItem(itemHashId : string , userHashId : string,cartHashId:string){
    console.log("dataleft",itemHashId,userHashId,cartHashId)
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.REMOVE_CART_ITEM;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_REMOVE_CART_ITEM;
    let hplist:Array<HeaderParam>=[
      new HeaderParam("itemHashId",itemHashId),
      new HeaderParam("userHashId",userHashId),
      new HeaderParam("cartHashId",cartHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getItemCustomizationLists(itemHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_ITEM_CUSTOMIZATION
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_ITEM_CUSTOMIZATION;
    let hplist:Array<HeaderParam>=[
      new HeaderParam("itemHashId",itemHashId),
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  addEditRemoveCustomizableForMerchantOrders(bodydata,userHashId:string,cartHash:string,customizableHashId:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.EDIT_CUSTOMIZABLE_FOR_MERCHANT;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_EDIT_CUSTOMIZABLE_FOR_MERCHANT;
    apiparams.bodydata = bodydata;
    let hplist:Array<HeaderParam>=[
      new HeaderParam("customizableHashId",customizableHashId),
      new HeaderParam("userHashId",userHashId),
      new HeaderParam("cartHash",cartHash),
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  deleteUserCart(userHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.DELETE_USER_CART;
    apiparams.resulttype = UserConstants.RESULT_DELETE_USER_CART;
    apiparams.requesttype = REQUEST_POST;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  deleteDeliveryAddress(hash_id : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.DELETE_DELIVERY_ADDRESS;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_DELETE_DELIVERY_ADDRESS;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("hashId",hash_id)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  setDefaultDeliveryAddress(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.SET_DEFAULT_DELIVERY_ADDRESS;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_SET_DEFAULT_DELIVERY_ADDRESS;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  bookRestaurantTable(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.BOOK_RESTAURANT_TABLE;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_BOOK_RESTAURANT_TABLE;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  validateCoupon(couponData){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.VALIDATE_COUPON;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_VALIDATE_COUPON;
    apiparams.bodydata = couponData;
    this.genericApiCall(apiparams);
  }

  paytmPayment(paymentData){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_PAYTM_PAYMENT;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_PAYTM_PAYMNT;
    apiparams.activeLoader = false;
    apiparams.bodydata = paymentData;
    this.genericApiCall(apiparams);
  }
  paytmPaymentRecord(paymentDataRecord){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_PAYTM_PAYMENT_RECORD;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_PAYTM_PAYMENT_RECORD;
    apiparams.bodydata = paymentDataRecord;
    apiparams.inputParams = paymentDataRecord;
    this.genericApiCall(apiparams);
  }
  paytmtransactionStatus(paymentDataRecord){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_PAYTM_TRANSACTION_STATUS;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_PAYTM_TRANSACTION_STATUS;
    apiparams.bodydata = paymentDataRecord;
    this.genericApiCall(apiparams);
  }

  getAvailableCoupon(zoneHashId : string,subServiceHashId : string,merchentHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_AVAILABLE_COUPON;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_AVAILABLE_COUPON;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId",zoneHashId),
      new HeaderParam("subServiceHashId",subServiceHashId),
      new HeaderParam("merchentHashId",merchentHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getAvailableCoupon1(zoneHashId : string,subServiceHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_AVAILABLE_COUPON;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_AVAILABLE_COUPON;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId",zoneHashId),
      new HeaderParam("subServiceHashId",subServiceHashId),
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getCheckoutValue(userHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_CHECKOUT_VALUE;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_CHECKOUT_VALUE;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId)
    ];
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  createUserOrder(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.CREATE_USER_ORDER;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_CREATE_USER_ORDER;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  createLaundryUserOrder(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.CREATE_LAUNDRY_USER_ORDER;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_CREATE_USER_ORDER;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  getOrderByUserId(userHashId : string,noOfItem:string,startIndex:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_ORDER_BY_USER_ID;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_ORDER_BY_USER_ID;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId),
      new HeaderParam("noOfItem",noOfItem),
      new HeaderParam("startIndex",startIndex)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getDriverDeliveryCurrentLocation(orderHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_DRIVER_DELIVERY_LOCATION;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_DRIVER_DELIVERY_LOCATION;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderHashId),
    
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getOrderDetailForNormalUser(subServiceId : string,orderId:string){
    console.log("CCCC::",subServiceId);
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_ORDER_DETAIL_FOR_NORMAL_USER;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_ORDER_DETAIL_FOR_NORMAL_USER;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("subServiceId",subServiceId),
      new HeaderParam("orderId",orderId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  updateOrderStatus(orderId : string,deliveryStatus : string,createdBy:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.UPDATE_ORDER_STATUS;
    apiparams.resulttype = UserConstants.RESULT_UPDATE_ORDER_STATUS;
    apiparams.requesttype = REQUEST_PUT;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderId",orderId),
      new HeaderParam("deliveryStatus",deliveryStatus),
      new HeaderParam("createdBy",createdBy)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getOrderTrackHistory(orderHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_ORDER_TRACK_HISTORY;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_ORDER_TRACK_HISTORY;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getTableBookingInfo(userHashId  :string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_TABLE_BOOKING_INFO;
    apiparams.resulttype = UserConstants.RESULT_GET_TABLE_BOOKING_INFO;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  addDeliveryRating(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_DELIVERY_RATING;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_ADD_DELIVERY_RATING;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  addMerchantRating(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_MERCHANT_RATING;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_ADD_MERCHANT_RATING;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  getDeliveryPersonInfoByOrderId(orderHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_DELIVERY_PERSON_INFO_BY_ORDER_ID;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_DELIVERY_PERSON_INFO_BY_ORDER_ID;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  addContactUsInfo(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_CONTACT_US_INFO;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_ADD_CONTACT_US_INFO;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }
  getWalltedInfoByAccountId(userHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_WALLET_INFO_BY_ACCOUNT_ID;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_WALLET_INFO_BY_ACCOUNT_ID;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId)
    ];
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getScratchCardProvidedToUser(userHashId : string,orderHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_SCRATCH_CARD_PROVIDED_TO_USER;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_SCRATCH_CARD_PROVIDED_TO_USER;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId),
      new HeaderParam("orderHashId",orderHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  redeemedScratchCard(hashId : string,userHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.REDEEMED_SCRATCH_CARD;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_REDEEMED_SCRATCH_CARD;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("hashId",hashId),
      new HeaderParam("userHashId",userHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getBusinessPromotionByZone(zoneHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_BUSINESS_PROMOTION_BY_ZONE;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_BUSINESS_PROMOTION_BY_ZONE;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId",zoneHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getBusinessPromotionByZone2(zoneHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_BUSINESS_PROMOTION_BY_ZONE;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_BUSINESS_PROMOTION_BY_ZONE2;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId",zoneHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getUserNotification(userHashId : string,pageNo:string,noOfItem:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_USER_NOTIFICATION;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_USER_NOTIFICATION;
    let hplist:Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId),
      new HeaderParam("pageNo",pageNo),
      new HeaderParam("noOfItem",noOfItem)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getLaundryList(zoneHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_LAUNDRY_LIST;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_LAUNDRY_LIST;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId",zoneHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  searchLaundryList(zoneHashId : string,searchString:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_LAUNDRY_LIST;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_SEARCH_LAUNDRY_LIST;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId",zoneHashId),
      new HeaderParam("searchString",searchString)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getLaundryDetailById(businessHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_LAUNDRY_DETAIL_BY_ID;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_LAUNDRY_DETAIL_BY_ID;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("businessHashId",businessHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getLaundryInventoryItem(businessHashId : string,pageNo : string,noOfItem:string,itemCategoryHashId:string,activeLoadder: boolean=true,inputParams:boolean){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_INVENTORY_ITEM_LAUNDRY;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_INVENTORY_ITEM_LAUNDRY;
    apiparams.activeLoader = activeLoadder;
    apiparams.inputParams=inputParams;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("businessHashId",businessHashId),
      new HeaderParam("pageNo",pageNo),
      new HeaderParam("noOfItem",noOfItem),
      new HeaderParam("itemCategoryHashId",itemCategoryHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  searchLaundryInventoryItem(businessHashId : string,pageNo : string,noOfItem:string,searchString:string,itemCategoryHashId:string,activeLoadder: boolean=true,inputParams:boolean){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.SEARCH_INVENTORY_ITEM_LAUNDRY;
    apiparams.requesttype = REQUEST_GET;
    apiparams.activeLoader = activeLoadder;
    apiparams.inputParams=inputParams;
    apiparams.resulttype = UserConstants.RESULT_SEARCH_INVENTORY_ITEM_LAUNDRY;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("businessHashId",businessHashId),
      new HeaderParam("pageNo",pageNo),
      new HeaderParam("noOfItem",noOfItem),
      new HeaderParam("searchString",searchString),
      new HeaderParam("itemCategoryHashId",itemCategoryHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getCategoryByLaundry(businessHashId:string,activeLoader : boolean=true){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_ITEM_CATEGORY_LAUNDRY;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_ITEM_CATEGORY_LAUNDRY;
    apiparams.activeLoader= activeLoader;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("businessHashId",businessHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  searchCabByAddressDateTime(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_CAB_DATA_BY_ADDRESS_DATE;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_GET_CAB_DATA_BY_ADDRESS_DATE;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  searchBikeByAddressDateTime(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_BIKE_DATA_BY_ADDRESS_DATE;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_GET_BIKE_DATA_BY_ADDRESS_DATE;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  getRideCostSetup(zoneHashId : string, vehicleType : string, cabType : string, packageType : string){
    console.log("Ride cost setup",zoneHashId,vehicleType,cabType,packageType);
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_RIDE_COST_SETUP;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_RIDE_COST_SETUP;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId",zoneHashId),
      new HeaderParam("vehicleType",vehicleType),
      new HeaderParam("cabType",cabType),
      new HeaderParam("packageType",packageType)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  addEditCabOrder(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_EDIT_CAB_ORDER;
    apiparams.resulttype = UserConstants.RESULT_ADD_EDIT_CAB_ORDER;
    apiparams.requesttype = REQUEST_POST;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  getCabOrderDetailForUser(orderId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_CAB_ORDER_DETAIL_FOR_USER;
    apiparams.resulttype = UserConstants.RESULT_GET_CAB_ORDER_DETAIL_FOR_USER;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getBikeOrderDetailForUser(orderId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_BIKE_ORDER_DETAIL_FOR_USER;
    apiparams.resulttype = UserConstants.RESULT_GET_BIKE_ORDER_DETAIL_FOR_USER;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getAutoOrderDetailForUser(orderId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_AUTO_ORDER_DETAIL_FOR_USER;
    apiparams.resulttype = UserConstants.RESULT_GET_AUTO_ORDER_DETAIL_FOR_USER;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getTotoOrderDetailForUser(orderId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_TOTO_ORDER_DETAIL_FOR_USER;
    apiparams.resulttype = UserConstants.RESULT_GET_TOTO_ORDER_DETAIL_FOR_USER;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  addEditBikeOrder(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_EDIT_BIKE_ORDER;
    apiparams.resulttype = UserConstants.RESULT_ADD_EDIT_BIKE_ORDER;
    apiparams.requesttype = REQUEST_POST;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }



  addEditAutoOrder(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_EDIT_AUTO_ORDER;
    apiparams.resulttype = UserConstants.RESULT_ADD_EDIT_AUTO_ORDER;
    apiparams.requesttype = REQUEST_POST;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }



  addEditTotoOrder(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_EDIT_TOTO_ORDER;
    apiparams.resulttype = UserConstants.RESULT_ADD_EDIT_TOTO_ORDER;
    apiparams.requesttype = REQUEST_POST;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }
  redeemWalletCash(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_REEDEM_WALLET_CASH;
    apiparams.resulttype = UserConstants.RESULT_ADD_REEDEM_WALLET_CASH;
    apiparams.requesttype = REQUEST_POST;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }
  addEditUserBankDetails(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_USER_BANK_DETAILS;
    apiparams.resulttype = UserConstants.RESULT_ADD_USER_BANK_DETAILS;
    apiparams.requesttype = REQUEST_POST
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  getUserBankDetails(userHashId : string,zoneHashId:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_USER_BANK_DETAILS;
    apiparams.resulttype = UserConstants.RESULT_GET_USER_BANK_DETAILS;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId),
      new HeaderParam("zoneHashId",zoneHashId),
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  deleteUserBankDetails(bankHashId : string,zoneHashId:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.DELETE_USER_BANK_DETAILS;
    apiparams.resulttype = UserConstants.RESULT_DELETE_USER_BANK_DETAILS;
    apiparams.requesttype = REQUEST_PUT;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("bankHashId",bankHashId),
      new HeaderParam("zoneHashId",zoneHashId),
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getWalletEncashReport(userHashId : string,zoneHashId:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_USER_WALLET_EARN_CASH_REPORT;
    apiparams.resulttype = UserConstants.RESULT_GET_USER_WALLET_EARN_CASH_REPORT;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId),
      new HeaderParam("zoneHashId",zoneHashId),
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getRideOrderHistoryDetails(userHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_RIDE_ORDER_HISTORY;
    apiparams.resulttype = UserConstants.RESULT_GET_RIDE_ORDER_HISTORY;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId),
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getRideOrderDetails(orderHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_RIDE_ORDER_DETAILS;
    apiparams.resulttype = UserConstants.RESULT_GET_RIDE_ORDER_DETAILS;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderHashId),
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getAllVehicleRideHistory(userHashId : string,fromDate:string,toDate : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_ALL_VEHICLE_RIDE_HISTORY;
    apiparams.resulttype = UserConstants.RESULT_GET_ALL_VEHICLE_RIDE_HISTORY;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId),
      new HeaderParam("fromDate",fromDate),
      new HeaderParam("toDate",toDate)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getServiceEntityBySubServiceAndZone(zoneHId : string,subServiceType:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_SERVICE_ENTITY_BY_SUB_SERVICE_AND_ZONE_HASH_ID;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_SERVICE_ENTITY_BY_SUB_SERVICE_AND_ZONE_HASH_ID;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHId",zoneHId),
      new HeaderParam("subServiceType",subServiceType)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  searchServiceEntityBySubServiceAndZone(zoneHId : string,subServiceType:string,searchString:string,pageNo:string,noOfItem:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_SERVICE_ENTITY_BY_SUB_SERVICE_AND_ZONE_HASH_ID;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_SERVICE_ENTITY_BY_SUB_SERVICE_AND_ZONE_HASH_ID;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHId",zoneHId),
      new HeaderParam("subServiceType",subServiceType),
      new HeaderParam("searchString",searchString),
      new HeaderParam("pageNo",pageNo),
      new HeaderParam("noOfItem",noOfItem)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getPickAndDropDeliveryCostZone(zoneHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_PICK_AND_DROP_DELIVERY_COST_ZONE_SPECIFIC;
    apiparams.requesttype  = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_PICK_AND_DROP_DELIVERY_COST_ZONE_SPECIFIC;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId",zoneHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getSubServiceServicePersonel(businessHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_SUB_SERVICE_SERVICE_PERSONEL;
    apiparams.resulttype = UserConstants.RESULT_GET_SUB_SERVICE_SERVICE_PERSONEL;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("businessHashId",businessHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getRateListServicePersonel(businessHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_SERVICE_PERSONAL_RATE_LIST;
    apiparams.resulttype = UserConstants.RESULT_GET_SERVICE_PERSONAL_RATE_LIST;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("businessHashId",businessHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getServicePersonelProfileInfoByUserHashId(accountHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_SERVICE_PERSONEL_INFO_BY_USER_HASH_ID;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_SERVICE_PERSONEL_INFO_BY_USER_HASH_ID;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("accountHashId",accountHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getServicePersonelBusinessDetailByUserHashId(userHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_SERVICE_PERSONEL_BUSINESS_DETAIL_BY_USER_HASH_ID;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_SERVICE_PERSONEL_BUSINESS_DETAIL_BY_USER_HASH_ID;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  createCourierOrder(userHashId : string,bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_EDIT_COURIER_BOOKING;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_ADD_EDIT_COURIER_BOOKING;
    apiparams.bodydata = bodydata;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getCourierBookingDetail(courierId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_COURIER_BOOKING_DETAIL;
    apiparams.requesttype=REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_COURIER_BOOKING_DETAIL;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("courierId",courierId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getCourierBookingHistory(UserHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_COURIER_BOOKING_HISTORY;
    apiparams.resulttype = UserConstants.RESULT_GET_COURIER_BOOKING_HISTORY;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",UserHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  updateCourierBookingStatus(userHashId : string,courierHId : string,bookingStatus : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.UPDATE_COURIER_BOOKING_STATUS;
    apiparams.requesttype = REQUEST_PUT;
    apiparams.resulttype = UserConstants.RESULT_UPDATE_COURIER_BOOKING_STATUS;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId),
      new HeaderParam("courierHId",courierHId),
      new HeaderParam("bookingStatus",bookingStatus)
    ];
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getDiscountBySubServiceId(subServiceHashId : string , zoneHashId : string,orderDate : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_DISCOUNT_BY_SUB_SERVICE_ID ;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_DISCOUNT_BY_SUB_SERVICE_ID;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId",zoneHashId),
      new HeaderParam("subServiceHashId",subServiceHashId),
      new HeaderParam("orderDate",orderDate)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  createUnlistedShopOrder(userHashId : string,bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.CREATE_UNLISTED_SHOP_ORDER;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_CREATE_UNLISTED_SHOP_ORDER;
    apiparams.bodydata = bodydata;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getUnlistedShopBookingDetail(orderCode : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_UNLISTED_SHOP_ORDER_DETAIL_BY_ID;
    apiparams.requesttype=REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_UNLISTED_SHOP_ORDER_DETAIL_BY_ID;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderCode",orderCode)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  updateUnlistedShopOrderStatus(orderCode : string,orderStatus : string,userHashId: string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.UPDATE_UNLISTED_SHOP_ORDER_STATUS;
    apiparams.resulttype = UserConstants.RESULT_UPDATE_UNLISTED_SHOP_ORDER_STATUS;
    apiparams.requesttype = REQUEST_PUT;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderCode",orderCode),
      new HeaderParam("orderStatus",orderStatus),
      new HeaderParam("userHashId",userHashId),
    ];
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }
  getCourierOrderTrackingOrder(orderHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_COURIER_ORDER_TRACKING_HISTORY;
    apiparams.resulttype = UserConstants.RESULT_GET_COURIER_ORDER_TRACKING_HISTORY;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getBusinessPromotionBySubServiceId(zoneHashId : string, subServiceId : string) {
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_BUSINESS_PROMOTION_BY_SUB_SERVICE_ID;
    apiparams.resulttype = UserConstants.RESULT_GET_BUSINESS_PROMOTIOM_BY_SUB_SERVICE_ID;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("zoneHashId", zoneHashId),
      new HeaderParam("sub_service_hash", subServiceId)
    ]
    this.genericApiCall(apiparams, this.getHeaderInfo(hplist));
  }

  getUnlistedShopOrderTrackingOrder(orderHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_UNLISTED_SHOP_ORDER_TRACKING_HISTORY
    apiparams.resulttype = UserConstants.RESULT_GET_UNLISTED_SHOP_ORDER_TRACKING_HISTORY;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getCourierDeliveryPersonInfoByOrderId(orderHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_COURIER_DELIVERY_PERSON_INFO_BY_ORDER_ID;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_COURIER_DELIVERY_PERSON_INFO_BY_ORDER_ID;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getIndividualServicePersonelCostSetup(serviceHId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_INDIVIDUAL_SERVICE_PERSONEL_COST_SETUP;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_INDIVIDUAL_SERVICE_PERSONEL_COST_SETUP;
    let hplist:Array<HeaderParam>=[
      new HeaderParam("serviceHId",serviceHId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  createUtilityOrder(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.CREATE_UTILITY_ORDER;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_CREATE_UTILITY_ORDER;
    apiparams.bodydata=bodydata;
    this.genericApiCall(apiparams);
  }
  getUtilityOrderDetailByOrderHashId(orderHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_UTILITY_ORDER_DETAIL_BY_ID;
    apiparams.resulttype = UserConstants.RESULT_GET_UTILITY_ORDER_DETAIL_BY_ID;
    apiparams.requesttype = REQUEST_GET;
    let hplist:Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  updateServicePersonnelOrderStatus(orderHId : string,deliveryStatus : string,userHashId:string,note:string,createdBy:string,inputParams:string ){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.UPDATE_SERVICE_PERSONNEL_ORDER_STATUS;
    apiparams.resulttype = UserConstants.RESULT_UPDATE_SERVICE_PERSONNEL_ORDER_STATUS;
    apiparams.requesttype = REQUEST_PUT;
    apiparams.inputParams=inputParams;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHId",orderHId),
      new HeaderParam("deliveryStatus",deliveryStatus),
      new HeaderParam("userHashId",userHashId),
      new HeaderParam("note",note),
      new HeaderParam("createdBy",createdBy)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }



  getServicePersonnelOrderJobCard(orderHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_SERVICE_PERSONNEL_ORDER_JOB_CARD;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_SERVICE_PERSONNEL_ORDER_JOB_CARD;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  addEditServicePersonnelPayment(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_EDIT_SERVICE_PERSONNEL_PAYMENT;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_ADD_EDIT_SERVICE_PERSONNEL_PAYMENT;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  getServicePersonnelOrderTrackHistory(orderHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_UTILITY_ORDER_TRACKING_HISTORY;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_UTILITY_ORDER_TRACKING_HISTORY;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getAllTypeServiceOrderBookingHistory(userHashId : string,fromDate:string,toDate:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_ALL_TYPE_SERVICE_ORDER_BOOKING_HISTORY;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_ALL_TYPE_SERVICE_ORDER_BOOKING_HISTORY;
    let hplist:Array<HeaderParam>=[
      new HeaderParam("userHashId",userHashId),
      new HeaderParam("fromDate",fromDate),
      new HeaderParam("toDate",toDate)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getServicePersonnelOrderBillPayment(orderHashId  :string){
    let apiparams : ApiParam= new ApiParam();
    apiparams.url = UserConstants.GET_SERVICE_PERSONNEL_ORDER_BILL_PAYMENT;
    apiparams.resulttype = UserConstants.RESULT_GET_SERVICE_PERSONNEL_ORDER_BILL_PAYMENT;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

getServicePersonnelOrderPaidDataList(orderHashId : string){
  let apiparams : ApiParam = new ApiParam();
  apiparams.url = UserConstants.GET_SERVICE_PERSONNEL_ORDER_PAID_DATA_LIST;
  apiparams.requesttype = REQUEST_GET;
  apiparams.resulttype = UserConstants.RESULT_GET_SERVICE_PERSONNEL_ORDER_PAID_DATA_LIST;
  let hplist : Array<HeaderParam>=[
    new HeaderParam("orderHashId",orderHashId)
  ]
  this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
}

getLaundryDetailByOrderHashId(orderHashId : string){
  let apiparams : ApiParam = new ApiParam();
  apiparams.url = UserConstants.GET_LAUNDRY_ORDER_DETAIL_BY_ORDER_ID;
  apiparams.resulttype = UserConstants.RESULT_GET_LAUNDRY_ORDER_DETAIL_BY_ORDER_ID;
  apiparams.requesttype = REQUEST_GET;
  let hplist : Array<HeaderParam>=[
    new HeaderParam("orderHashId",orderHashId)
  ]
  this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
}

updateLaundryOrderStatus(orderId : string, deliveryStatus : string, note : string, createdBy : string){
  let apiparams : ApiParam = new ApiParam();
  apiparams.url = UserConstants.UPDATE_LAUNDRY_ORDER_STATUS;
  apiparams.requesttype = REQUEST_PUT;
  apiparams.resulttype = UserConstants.RESULT_UPDATE_LAUNDRY_ORDER_STATUS;
  let hplist : Array<HeaderParam>=[
    new HeaderParam("orderId",orderId),
    new HeaderParam("deliveryStatus",deliveryStatus),
    new HeaderParam("note",note),
    new HeaderParam("createdBy",createdBy)
  ]
  this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
}

  addServicePersonnelRating(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_SERVICE_PERSONNEL_RATING;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_ADD_SERVICE_PERSONNEL_RATING;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }

  getServicePersonnelRateAndReview(servicePersonHId:string,pageNo : string, noOfItem : string,inputParams:string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_SERVICE_PERSONNEL_RATING_REVIEW;
    apiparams.requesttype = REQUEST_GET;
    apiparams.resulttype = UserConstants.RESULT_GET_SERVICE_PERSONNEL_RATING_REVIEW;
    apiparams.inputParams = inputParams;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("servicePersonHId",servicePersonHId),
      new HeaderParam("pageNo",pageNo),
      new HeaderParam("noOfItem",noOfItem)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  getRideTrackHistory(orderHashId : string){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.GET_RIDE_TRACK_HISTORY;
    apiparams.resulttype = UserConstants.RESULT_GET_RIDE_TRACK_HISTORY;
    apiparams.requesttype = REQUEST_GET;
    let hplist : Array<HeaderParam>=[
      new HeaderParam("orderHashId",orderHashId)
    ]
    this.genericApiCall(apiparams,this.getHeaderInfo(hplist));
  }

  addNewJoiningInterestRequest(bodydata){
    let apiparams : ApiParam = new ApiParam();
    apiparams.url = UserConstants.ADD_NEW_JOINING_INTEREST_REQUEST_DATA;
    apiparams.requesttype = REQUEST_POST;
    apiparams.resulttype = UserConstants.RESULT_ADD_NEW_JOINING_INTEREST_REQUEST_DATA;
    apiparams.bodydata = bodydata;
    this.genericApiCall(apiparams);
  }


  private genericApiCall(apiparam: ApiParam, htpHeader: any = undefined) {
    if (apiparam.activeLoader) this.loader.showLoader = true;
    switch (apiparam.requesttype) {
      case REQUEST_GET:
        this.drtoHttp.get(apiparam.url, htpHeader).subscribe(
          (data) => {
            // console.log("GET REQUEST MADE", data);
            if (apiparam.activeLoader) this.loader.showLoader = false;
            if (this.checkMsgForError(data)) return;
            if (!this.validResultCode(data, apiparam.resulttype)) {
              this._result.next({
                resulttype: apiparam.resulttype,
                result: data,
                inputParams: apiparam.inputParams,
              });
            }
          },
          (error) => {
            if (apiparam.activeLoader) this.loader.showLoader = false;
            // console.log(error);
            this.handleError(error);
          }
        );
        break;

      case REQUEST_POST:
        this.drtoHttp
          .post(apiparam.url, apiparam.bodydata, htpHeader)
          .subscribe(
            (data) => {
              if (apiparam.activeLoader) this.loader.showLoader = false;
              if (this.checkMsgForError(data)) return;
              if (!this.validResultCode(data, apiparam.resulttype)) {
                this._result.next({
                  resulttype: apiparam.resulttype,
                  result: data,
                  inputParams: apiparam.inputParams,
                });
              }
            },
            (error) => {
              this.handleError(error);
            }
          );
        break;

      case REQUEST_PUT:
        this.drtoHttp.put(apiparam.url, apiparam.bodydata, htpHeader).subscribe(
          (data) => {
            if (apiparam.activeLoader) this.loader.showLoader = false;
            if (this.checkMsgForError(data)) return;
            if (!this.validResultCode(data, apiparam.resulttype)) {
              this._result.next({
                resulttype: apiparam.resulttype,
                result: data,
                inputParams: apiparam.inputParams,
              });
            }
          },
          (error) => {
            this.handleError(error);
          }
        );

        break;
    }
  }
  private getHeaderInfo(headerlist: Array<HeaderParam>): any {
    let htpheaders = new HttpHeaders();
    for (let hp of headerlist) {
      htpheaders = htpheaders.set(hp.keyName, hp.keyValue);
    }

    const httpOpt = {
      headers: htpheaders,
    };
    return httpOpt;
  }
  private handleError(error: any) {
    this.loader.showLoader = false;
    this.errorManager.validateNodeError(error);
  }

  private checkMsgForError(value: any): boolean {
    if (HelperFunc.isValueDefinedByProp(value, "errorMessage")) {
      this.errorManager.setError({ show: true, msg: value.errorMessage });
      return true;
    }
    return false;
  }

  private validResultCode(data: any, result_type: string): boolean {
    return false;
  }

  get apiResults() {
    return this._result.asObservable();
  }
}

class ApiParam {
  url: string;
  resulttype: string;
  bodydata?: any;
  requesttype?: string = REQUEST_GET;
  activeLoader?: boolean = true;
  inputParams?: any;
  constructor() {}
}

class HeaderParam {
  keyName: string;
  keyValue: string;
  constructor(keyname: string = undefined, keyvalue: string = undefined) {
    if (keyname) this.keyName = keyname;
    if (keyvalue) this.keyValue = keyvalue;
  }
}
