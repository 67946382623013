import { Injectable } from '@angular/core';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { LoaderService } from './loader.service';
import { ExceptionManagerService } from './exception-manager.service';
import { UserStorageService } from './user-storage.service';
import { UserConstants } from '../utils/userConstant';
Amplify.configure(awsconfig);
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private apiService : ApiService,
    private loader: LoaderService,
    private errorManager: ExceptionManagerService,
    private userStorage: UserStorageService,
    private router : Router) { 
    
  }
  userSignUp(mobileNumber : string):Promise<any>{
    return new Promise((resolve,reject)=>{
      let username = '+91'+mobileNumber;
      let phone_number = '+91'+mobileNumber;
      // this.loader.showLoader=true;
      this.userStorage.setValueInStorage(UserConstants.KEY_MOBILE,username);
      this.userStorage.setValueInStorage(UserConstants.KEY_PASSWORD,Date.now().toString());
      localStorage.setItem(UserConstants.KEY_MOBILE,username);
      localStorage.setItem(UserConstants.KEY_PASSWORD,Date.now().toString());
      Auth.signUp( {
        username,
        password : Date.now().toString(),
        attributes: {
          phone_number,
          // 'custom:zoneHashId' : zoneHashId
        },
        validationData: []  //optional
      } ).then(res=>{
        // this.loader.showLoader=false;
        console.log(res);
        if(res.userConfirmed){
          // this.router.navigate([`${MerchantConstants.RL_CONFIRM_OTP}`],{queryParams:{'type':'signUp','userName':username}});
          resolve(res);
        }
      }).catch(err=>{
        // this.loader.showLoader=false;
        console.log(err);
        this.handleError(err);
        reject(err);
      })
    })
    
  }
  
  userConfirmSignUp(user,code):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.loader.showLoader=true;
      Auth.sendCustomChallengeAnswer(user,code).then(res=>{
        this.loader.showLoader=false;
        console.log(res);
       resolve(res);
      }).catch(err=>{
        this.loader.showLoader=false;
        console.log(err);
        this.handleError(err);
        reject(err);
      })
    })
  }
  userLoginIn1(userName : string):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.loader.showLoader=true;
      Auth.signIn(userName).then(res=>{
        this.loader.showLoader=false;
        console.log(res);
        resolve(res);
      }).catch(err=>{
        this.loader.showLoader=false;
        console.log(err);
        this.handleError(err);
        reject(err);
      })
    })
  }
  userLoginIn(userName : string):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.loader.showLoader=true;
      Auth.signIn(userName).then(res=>{
        this.loader.showLoader=false;
        console.log(res);
        resolve(res);
      }).catch(err=>{
        this.loader.showLoader=false;
        console.log(err);
        this.handleError(err);
        reject(err);
      })
    })
  }
  private handleError(error: any) {
    this.loader.showLoader = false;
    this.errorManager.validateNodeError(error);
  }

  resendVerification(userName : string):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.loader.showLoader=true;
      Auth.signIn(userName).then(res=>{
        this.loader.showLoader=false;
        console.log(res);
        resolve(res);
      }).catch(err=>{
        this.loader.showLoader=false;
        console.log(err);
        this.handleError(err);
        reject(err);
      })
    })
  }

  userLogout():Promise<any>{
    return new Promise((resolve,reject)=>{
      this.loader.showLoader=true;
      Auth.signOut().then(res=>{
        this.loader.showLoader=false;
        console.log(res);
        resolve(res)
      }).catch(err=>{
        this.loader.showLoader=false;
        console.log(err);
        reject(err);
      })
    })
  }

  currentAuthenticatedUser(phone_number:string):Promise<any>{
    return new Promise((resolve,reject)=>{
      Auth.currentAuthenticatedUser().then(res=>{
        console.log("Response :",res);
        Auth.updateUserAttributes(res,{'phone_number':'+91'+phone_number}).then(result=>{
          console.log(result);
          resolve(result);
        }).catch(error=>{
          console.log(error);
          this.handleError(error);
          reject(error);
        })
      }).catch(err=>{
        console.log("Error :",err);
        this.handleError(err);
      })
    })
  }


}
