<div class="surprise_sjk">
    <div class="list_detail">
        <div class="row">
            <div class="col-sm-2">
                <div class="sur_ps_image">
                    <img [src]="aws3+cartItem.item_image">
                </div>
                <div *ngIf="cartItem.item_image == null">
                    <div class="hm_icon">
                        <i class="fa fa-cutlery"></i>
                    </div>
                </div>
            </div>
            <div class="col-sm-10">
                <div class="sur_me_odr">
                    <h5>{{ cartItem.item_name }}
                    </h5>
                    <div class="item_count">
                        <P>{{ cartItem.selectedQuantity }} item</P>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="list_detail">
        <div class="sur_plk_sec">
            <p>Your Quote
            </p>
        </div>
        <p class="surg_ted">Rs.{{ form.Enteryourprice }}</p>
    </div>

    <div class="list_detail">
        <div class="sur_plk_sec">
            <p>Notes </p>
        </div>
        <p class="surg_ted">{{ form.Notes }}</p>
    </div>
</div>

<div class="add_footer">
    <div class="md-footer">
        <div class="row">
            <div class="col-sm-6">
                <button type="submit" class="cenl_btn" (click)="closeModel()"> Cancel </button>
            </div>
            <div class="col-sm-6">
                <button type="button" (click)="submiSuprisemeorder()">Confrim</button>
            </div>
        </div>
    </div>
</div>
