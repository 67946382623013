import { Component, OnInit, NgZone } from '@angular/core';
import { Basecomponent } from 'src/app/directives/basecomponent.directive';
import { BaseService } from 'src/app/services/base.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserConstants } from 'src/app/utils/userConstant';
import { UserStorageService } from 'src/app/services/user-storage.service';
declare var google;
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent extends Basecomponent implements OnInit {
  personalInfoForm: FormGroup;
  constructor(baseService : BaseService,
    private ngZone : NgZone,
    private userStorage : UserStorageService) {
    super(baseService);
    this.personalInfoForm = new FormGroup({
      'name' : new FormControl(null, [Validators.required,Validators.minLength(2),Validators.pattern("([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,30}")]),
      // 'phone' : new FormControl(null,[Validators.required,Validators.minLength(10),Validators.maxLength(10),Validators.pattern('^[0-9]*$')]),
      'address' : new FormControl(null,[Validators.required,Validators.minLength(2)]),
      'email' : new FormControl(null,[Validators.email,Validators.required]),
      'gender' : new FormControl(null,[Validators.required]),
      'dob' : new FormControl(null,[Validators.required]),
      'description' : new FormControl(null,[Validators.required])   
  })
   }

   imageUrl:string;
   updateDataInEditMode(){
     let profileData = this.getPassingData().stateData;
     console.log(profileData);
    //  this.personalInfoForm.get('phone').patchValue(profileData.phone);
     this.personalInfoForm.get('name').patchValue(profileData.name);
     this.personalInfoForm.get('address').patchValue(profileData.location.address_line);
     this.personalInfoForm.get('gender').patchValue(profileData.gender);
     this.personalInfoForm.get('dob').patchValue(profileData.dob.slice(0,10));
     this.personalInfoForm.get('email').patchValue(profileData.email);
     this.personalInfoForm.get('description').patchValue(profileData.short_bio);
     this.imageUrl=profileData.image_url;
     this.formattedAddress=profileData.location.address_line;
     this.lat = profileData.location.lat;
     this.lng = profileData.location.lng;
     this.selectedLocation.city = (profileData.location.city && profileData.location.city != 'null') ? profileData.location.city : null;
     this.selectedLocation.state = (profileData.location.state && profileData.location.state != 'null') ? profileData.location.state : null;
     this.selectedLocation.country = (profileData.location.country && profileData.location.country != 'null') ? profileData.location.country : null;
     this.selectedLocation.zip = (profileData.location.zip && profileData.location.zip != 'null') ? profileData.location.zip : null;
   }
   saveDetail(){
 console.log(this.personalInfoForm);
 this.saveHandler(true);
   }
   invokeApiHandler() {
     let updateData = {
    userHashId : (this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID)) ? this.userStorage.getValueFromStorage(UserConstants.KEY_ACCOUNT_HASH_ID) : localStorage.getItem(UserConstants.KEY_ACCOUNT_HASH_ID),
    name : this.personalInfoForm.value.name,
    //  phone : this.personalInfoForm.value.phone,
     email : (this.personalInfoForm.value.email && this.personalInfoForm.value.email != 'null') ? this.personalInfoForm.value.email : 'null',
     gender : (this.personalInfoForm.value.gender && this.personalInfoForm.value.gender !='null') ? this.personalInfoForm.value.gender : 'null',
     dob : (this.personalInfoForm.value.dob && this.personalInfoForm.value.dob != 'null') ? this.personalInfoForm.value.dob : 'null',
     image_url : (this.imageUrl && this.imageUrl != 'null') ? this.imageUrl : 'null',
     image_thumb_url : (this.imageUrl && this.imageUrl != 'null') ? this.imageUrl : 'null',
     shortBio : this.personalInfoForm.value.description,
     adressInfo : {
       address_line : this.formattedAddress,
       city : (this.selectedLocation.city && this.selectedLocation.city != 'null') ? this.selectedLocation.city : 'null',
       state : (this.selectedLocation.state && this.selectedLocation.state != 'null') ? this.selectedLocation.state : 'null',
       country : (this.selectedLocation.country && this.selectedLocation.country != 'null') ? this.selectedLocation.country : 'null',
       zip : (this.selectedLocation.zip && this.selectedLocation.zip != 'null') ? this.selectedLocation.zip : 'null',
       lat : (this.lat && this.lat != 'null') ? this.lat : 'null',
       lng : (this.lng && this.lng != 'null') ? this.lng : 'null' 
     }
   }
   console.log(updateData);
   this.apiService.updateUserData(updateData);
 }
 apiResultSubscribe(data: any) {
   console.log(data);
   if(data.resulttype == UserConstants.RESULT_UPDATE_USER_DATA){
     if(data.result.statusCode == 200){
       this.closePopupWithData(true);
     }else{
       this.setError(true,data.result);
     }
   }
 }
   validationErrorHandler(): void {
     this.setError(true, UserConstants.DEFAULT_INPUT_VALIDATION_MESSAGE);
   }
 
   private _isAddressMouseDownWarning: boolean = false;
   onAddressMouseDown(){
     if(!this.personalInfoForm.value.address){
       this._isAddressMouseDownWarning = true;
     }
   }
   get isAddressMouseDownWarning(){
     return this._isAddressMouseDownWarning;
   }
   
   autocomplete: any = [];
   geocoder = new google.maps.Geocoder();
   selectedLocation : any = [];
   formattedAddress : any;
   lat:any;
   lng:any;
   GoogleAutocomplete = new google.maps.places.AutocompleteService();
   
   autocompleteItems:any = [];
   updateSearchResults() {
     console.log("Text enter")
     if ( this.personalInfoForm.value.address == '' )
     {
         this.autocompleteItems = [];
         return;
     }
     this.GoogleAutocomplete.getPlacePredictions( {
         input: this.personalInfoForm.value.address
     },
         ( predictions, status ) => {
   
           console.log(predictions,status);
             this.autocompleteItems = [];
             predictions.forEach( ( prediction ) => {
                 this.autocompleteItems.push( prediction );
             } );
         } );
   }
   selectSearchResult( item ) {
     console.log(item);
     this.autocompleteItems = [];
     this.geocoder.geocode( {
         'placeId': item.place_id
     }, ( results, status ) => {
         if ( status === 'OK' && results[ 0 ] )
         {
             this.ngZone.run( () => {
                 // this.personalInfoForm.value.address = results[ 0 ].formatted_address;
                 this.personalInfoForm.get('address').patchValue(results[0].formatted_address);
                 // this.locationSelected = true;
                 let location_set:any = JSON.stringify(results[0].geometry);
                 location_set=JSON.parse(location_set)
                 let position = {
                     lat: results[ 0 ].geometry.location.lat,
                     lng: results[ 0 ].geometry.location.lng
                 };
                 this.lat=location_set.location.lat;
                 this.lng=location_set.location.lng;
                 console.log(this.lat,this.lng);
                 console.log('Latitude & Langitude' , position)
                 this.storeLocation( results[ 0 ].address_components, results[ 0 ].formatted_address );
             } );
         }
     } )
   }
   storeLocation( location, formatted_address ) {
     const selectedLocation: any = {};
     location.forEach( element => {
         console.log( element, element.types.includes( "postal_code" ) )
         if ( element.types.includes( "postal_code" ) )
         {
             selectedLocation.zip = element.long_name
         } else if ( element.types.includes( "country" ) )
         {
             selectedLocation.country = element.long_name
         } else if ( element.types.includes( "administrative_area_level_1" ) )
         {
             selectedLocation.state = element.long_name
         } else if ( element.types.includes( "locality" ) )
         {
             selectedLocation.city = element.long_name
         } else if ( element.types.includes( "route" ) )
         {
             selectedLocation.address = element.long_name
         }
     } );
       this.formattedAddress = formatted_address;
       this.selectedLocation = selectedLocation;
       this._isAddressMouseDownWarning=false;
   }


}
